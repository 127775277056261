import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { importanceComponent } from '@nodeTypeConfig/helpers/editComponents/importanceComponent';

const EXAMPLE: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  information: {
    single: 'Voorbeeld',
    plural: 'Voorbeelden',
  },

  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    {
      type: NodeType.REFERENCE_GROUP,
      showInAncestorTypes: [NodeType.VISION_TEXT],
    },
  ],
  edit: [importanceComponent],

  isCollapsible: false,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default EXAMPLE;
