import { EditAsideCheckbox } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { get, set } from 'lodash';
import { useCurrentEditNode } from '../../../hooks/UseCurrentEditNode';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import SimpleCheckbox from './SimpleCheckbox';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideCheckbox: React.FC<{
  config: EditAsideCheckbox;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const editHref = useEditHref();

  const currentEditNode = useCurrentEditNode();
  const checked = Boolean(get(currentEditNode, config.property, false));

  const readOnly = useReadOnly();

  const change = (value: boolean) => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, value)));
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <SimpleCheckbox
        checked={checked}
        label={config.label}
        readOnly={readOnly}
        onChange={change}
      />
    </AsideValidation>
  );
};

export default AsideCheckbox;

AsideCheckbox.displayName = 'AsideCheckbox';

AsideCheckbox.propTypes = {
  config: any,
};
