/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-loop-func */
import jsonPatch from 'json-patch';
import uuidv4 from 'uuid/v4';
import {
  getResourceType,
  getResourceKey,
  getProposalRelatedResourcesHrefs,
} from './documentHelpers';
import { getProposalType } from '../viewmodels/proposalViewModel';
import { proposalStatuses } from '../constants/proposalStatuses';
import { modes } from '../constants/modes';
import { relationTypes } from '../constants/relationTypes';
import { proposalTypes } from '../constants/proposalTypes';

/**
 * - Include in apiWithPendingChanges.content and apiWithPendingChanges.relations those active
 * proposals that include changes of type CREATE
 * - Override apiWithPendingChanges.content and apiWithPendingChanges.relations with proposal
 * changes with type PATCH
 */
function applyProposals(apiWithPendingChanges, mode) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [, proposal] of apiWithPendingChanges.proposals) {
    // only apply proposals that corresponds to the currently selected mode
    if (
      mode === 'SUGGESTING' ||
      (mode === 'REVIEWING' && proposal.status === 'SUBMITTED_FOR_REVIEW')
    ) {
      proposal.listOfRequestedChanges.forEach((change) => {
        const resourceType = getResourceType(change.appliesTo.href);

        switch (change.type) {
          case 'CREATE': {
            console.log('APPLYING CREATE node from proposal:', change.resource);

            apiWithPendingChanges[resourceType].set(change.appliesTo.href, change.resource);
            break;
          }

          case 'PATCH': {
            let node = apiWithPendingChanges[resourceType].get(change.appliesTo.href);
            if (!node) {
              break;
            }

            node = {
              ...node,
              $$new: false,
            };

            // be sure the value is set in every patch item to avoid Invalid Patch error
            change.patch.forEach((item) => {
              if (!('value' in item)) {
                item.value = undefined;
              }
            });

            console.log('APPLYING PATCH node from proposal:', node, change.patch);

            // ensure all fields with op 'replace' in the propsal patch actually exists in node
            change.patch.forEach((p) => {
              if (p.op === 'replace' && !node[p.path.slice(1)]) {
                node[p.path.slice(1)] = '';
              }
            });

            jsonPatch.apply(node, change.patch);

            apiWithPendingChanges[resourceType].set(change.appliesTo.href, node);
            break;
          }

          case 'DELETE': {
            // we can't remove the resource proposed to be delete from the apiWithPendingChanges because
            // we need it to display in the document tree with a different style
            const resource = apiWithPendingChanges[resourceType].get(change.appliesTo.href);

            if (resource) {
              console.log('APPLYING DELETE mark to resource from proposal:', resource);

              apiWithPendingChanges[resourceType].set(change.appliesTo.href, {
                ...resource,
                deleteProposal: true,
              });
            }
            break;
          }

          case 'UPLOAD': {
            let node = apiWithPendingChanges.content.get(change.relatedTo.href);
            if (!node) {
              break;
            }

            const attachments = node.attachments.filter(
              (a) => a.key !== (change.attachment.key || change.metadata.key)
            );

            node = { ...node, attachments };
            console.log('APPLYING UPLOAD node from proposal:', node, change);

            node.attachments.push({
              ...change.attachment,
              ...change.metadata,
              href:
                !change.attachment.href || change.attachment.href.indexOf('/content') !== -1
                  ? `/proposals/${proposal.key}/attachments/${change.attachment.name}`
                  : change.attachment.href,
            });

            apiWithPendingChanges.content.set(change.relatedTo.href, node);
            break;
          }

          case 'DELETE_UPLOAD': {
            let node = apiWithPendingChanges.content.get(change.relatedTo.href);
            if (!node) {
              break;
            }

            const newAttachments = [...node.attachments].filter(
              (a) => a.key !== getResourceKey(change.appliesTo.href)
            );

            node = { ...node, attachments: newAttachments };
            console.log('APPLYING DELETE_UPLOAD node from proposal:', node, change);

            apiWithPendingChanges.content.set(change.relatedTo.href, node);
            break;
          }

          default: {
            break;
          }
        }
      });
    }
  }
}

// get all related content hrefs to the given pending action resource
// it must be used for content resources, it's not considering proposals
const getAllRelatedContentHrefs = (resource) => {
  const hrefs = [];

  if (resource.relatedTo) {
    hrefs.push(resource.relatedTo.href);
  }
  if (resource.appliesTo) {
    hrefs.push(resource.appliesTo.href);
  }
  if (resource.href) {
    hrefs.push(resource.href);
  }
  // if (resource.parentHref) {
  //   hrefs.push(resource.parentHref);
  // }
  return hrefs;
};

export const getRelatedContentHref = (resource, href, forProposal) => {
  if (href && getResourceType(href) !== 'proposals') {
    return href;
  }

  if (resource.relatedTo) {
    href = resource.relatedTo.href;
  } else if (resource.appliesTo) {
    href = resource.appliesTo.href; // /proposals
  } else if (forProposal && resource.source) {
    href = resource.source.href; // /web/pages
  } else if (resource.$$meta && getResourceType(resource.$$meta.permalink) === 'proposals') {
    href = getRelatedContentHref(resource.listOfRequestedChanges[0]);
  } else if (resource.$$meta && getResourceType(resource.$$meta.permalink) !== 'proposals') {
    href = resource.$$meta.permalink;
  } else if (resource.href) {
    href = resource.href;
  }
  return href;
};

/**
 * Special update of apiPending is needed when when we are applying the pendingActions as proposals.
 * apiPending.proposals is updated with pendingActions
 */
function updateApiPendingProposals(pendingAction, apiPending, apiProposals, rootKey, me, mode) {
  pendingAction.resources.forEach((resource) => {
    const relatedContentHref = getRelatedContentHref(resource, null, true);

    // Check first if it is already added to apiPending.
    // Find at least one change with appliesTo/relatedTo equal to getRelatedContentHref
    // and must include the current to exclude replaced proposals.
    let proposal = apiPending.proposals.find((apiPendingProposal) => {
      return (
        apiPendingProposal.body.listOfRequestedChanges.some(
          (change) =>
            change.appliesTo.href === relatedContentHref ||
            change.appliesTo.href === resource?.href ||
            (change.relatedTo && change.relatedTo.href === relatedContentHref)
        ) && apiPendingProposal.body.creators.includes(me.$$meta.permalink)
      );
    });

    if (!proposal) {
      proposal = apiProposals.get(relatedContentHref);
    } else {
      proposal = proposal.body;
    }

    if (proposal) {
      if (
        proposal.creators.includes(me.$$meta.permalink) ||
        pendingAction.type === 'PATCH_PROPOSAL'
      ) {
        proposal = { ...proposal, listOfRequestedChanges: [...proposal.listOfRequestedChanges] };
      } else {
        proposal = {
          // a different user modifying proposed
          ...proposal,
          key: uuidv4(),
          status: mode === 'REVIEWING' ? 'SUBMITTED_FOR_REVIEW' : proposal.status,
          creators: [...proposal.creators, me.$$meta.permalink],
          expandedCreators: [...proposal.expandedCreators, me],
          listOfRequestedChanges: [...proposal.listOfRequestedChanges],
          olderProposals: [...(proposal.olderProposals || []), proposal],
        };
      }

      if (proposal.olderProposals) {
        // when creating a new proposal we may need to update also the status of older proposals linked to it
        proposal.olderProposals = proposal.olderProposals.map((p) => {
          if (p.status !== proposal.status) {
            p.status = proposal.status;

            apiPending.proposals = apiPending.proposals.filter(
              (pf) => pf.href !== `/proposals/${p.key}`
            );
            // code was not doing things properly with empty body anyway so safest thing to do is to comment this out
            /* apiPending.proposals.push({
              verb: 'PUT',
              href: `/proposals/${p.key}`,
              body: p,// body was missing here !!!!!
            }); */
          }
          return p;
        });
      }
    } else {
      const key = uuidv4();
      proposal = {
        key,
        status: mode === 'REVIEWING' ? 'SUBMITTED_FOR_REVIEW' : 'IN_PROGRESS',
        creators: [me.$$meta.permalink],
        expandedCreators: [me],
        externalReferences: [`/content/${rootKey}`],
        listOfRequestedChanges: [],
        $$meta: { permalink: `/proposals/${key}` },
      };
    }

    const change = {
      type: resource.type || pendingAction.type,
      appliesTo: { href: resource.href },
    };

    if (relatedContentHref && relatedContentHref !== resource.href) {
      // relatedTo only neccesary if the modified resource is not of type content
      change.relatedTo = { href: relatedContentHref };
    }
    switch (resource.type || pendingAction.type) {
      case 'PATCH':
        change.patch = resource.patch.map((p) => {
          // Update href of attachments with new proposal key
          if (p.path === '/attachments') {
            const value = p.value.map((v) => {
              let { href } = v;
              if (href?.startsWith('/proposals')) {
                href = href.replace(
                  /\/proposals\/.*\/attachments/,
                  `/proposals/${proposal.key}/attachments`
                );
              }
              return { ...v, href };
            });
            return { ...p, value };
          }
          return p;
        });

        break;
      case 'UPLOAD':
        change.attachment = resource.body;
        break;
      case 'DELETE_UPLOAD':
        // remove previous UPLOAD change for same attachment and ignore DELETE_UPLOAD
        proposal.listOfRequestedChanges = proposal.listOfRequestedChanges.filter((ch) => {
          if (ch.type === 'UPLOAD' && ch.appliesTo.href === change.appliesTo.href) {
            change.ignoreChange = true;
            return false;
          }
          return true;
        });

        // Also remove item in attachments field
        if (change.ignoreChange) {
          proposal.listOfRequestedChanges.forEach((c) => {
            if (c.type === 'PATCH') {
              c.patch.forEach((p) => {
                if (p.path === '/attachments') {
                  const attKey = change.appliesTo.href.match(/\/content\/.*\/attachments\/(.*)/)[1];
                  p.value = p.value.filter((v) => v.key !== attKey);
                }
              });
            } else if (c.type === 'CREATE' && change.relatedTo.href === c.appliesTo.href) {
              const attKey = change.appliesTo.href.match(/\/content\/.*\/attachments\/(.*)/)[1];
              c.resource.attachments = c.resource.attachments.filter((a) => a.key !== attKey);
            }
          });
        }

        break;
      case 'PATCH_PROPOSAL':
        proposal = jsonPatch.apply({ ...proposal }, resource.patch);
        // also apply the patch to older proposals if needed
        // (eg. when accepting the latest proposal)
        if (resource.patchOlderProposals && proposal.olderProposals) {
          proposal.olderProposals = proposal.olderProposals.map((p) => {
            p = jsonPatch.apply({ ...p }, resource.patch);
            apiPending.proposals = apiPending.proposals.filter(
              (pf) => pf.href !== `/proposals/${p.key}`
            );
            apiPending.proposals.push({
              verb: 'PUT',
              href: `/proposals/${p.key}`,
              body: p,
            });
            return p;
          });
        }
        change.ignoreChange = true;
        break;
      default:
        if (resource.body) {
          change.resource = resource.body;
        }
        break;
    }

    // some cases will not add a change in the proposal list
    if (!change.ignoreChange) {
      change.creator = { href: me.$$meta.permalink };
      proposal.listOfRequestedChanges = [...proposal.listOfRequestedChanges, change];
    }

    apiPending.proposals = apiPending.proposals.filter(
      (p) => p.href !== `/proposals/${proposal.key}`
    );

    if (proposal.listOfRequestedChanges.length) {
      apiPending.proposals.push({
        verb: 'PUT',
        href: `/proposals/${proposal.key}`,
        body: proposal,
      });
    }
  });
}

/**
 * Update an api data structure with user changes in apiPending.
 * (api cache after document is saved or apiWithPendingChanges when updating it for the view model)
 */
const applyApiPending = (api, apiPending, updateProposalAttHrefs = false) => {
  const newApi = { ...api };
  Object.keys(apiPending).forEach((field) => {
    apiPending[field].forEach((pending) => {
      if (field === 'externalContent') {
        // special case of adding external resources that behaves like content resources
        field = 'content';
      }
      switch (pending.verb) {
        case 'PUT': {
          // proposal attachments: update /proposal hrefs to /content hrefs
          if (updateProposalAttHrefs && pending.body.attachments) {
            pending.body.attachments.forEach((att) => {
              if (att.href && att.href.startsWith('/proposals') && att.relatedTo) {
                att.href = `${att.relatedTo.href}/attachments/${att.name}`;
              }
            });
          }
          // pending it's a modification then update the current resource
          newApi[field].set(getRelatedContentHref(pending.body, pending.href), pending.body);
          break;
        }

        case 'DELETE': {
          if (newApi[field]) {
            newApi[field].delete(getRelatedContentHref(pending));
          }
          break;
        }

        default: {
          break;
        }
      }
    });
  });

  return newApi;
};

/**
 * In review mode when editing nodes that are not related to a submitted proposal,
 * the editor can immediately update the content in the content api.
 */
function shouldResourceBeCreatedAsContentInReviewMode(
  mode,
  nodeHrefsThatArePartOfSubmittedProposal,
  resources
) {
  const allRelatedContentHrefs = resources.flatMap((r) => getAllRelatedContentHrefs(r));
  return (
    mode === 'REVIEWING' &&
    !nodeHrefsThatArePartOfSubmittedProposal.some((href) => allRelatedContentHrefs.includes(href))
  );
}

// check if some of the resources in th pending action has a proposal already submitted
// it will seek for proposals also in the children of the resource if present
/* function pendingActionResourceHasSubmittedProposal(actionResources, apiProposals, apiContent) {
  let proposal;
  let contentResourceHrefs = [];
  const considerResourceChilds = actionResources.some(resource => resource.parentHref);

  function getProposal(resourceHref) {
    let p = apiProposals.get(resourceHref);
    if (p) {
      return p;
    }
    const node = apiContent.get(resourceHref);
    if (node && node.$$children && considerResourceChilds) {
      const child = node.$$children.find(c => {
        return apiProposals.get('/content/' + c.key);
      });
      return child ? apiProposals.get('/content/' + child.key) : undefined;
    }
    return undefined;
  }

  actionResources.forEach(resource => {
    contentResourceHrefs = [...contentResourceHrefs, ...getAllRelatedContentHrefs(resource)];
  });

  contentResourceHrefs.forEach(resourceHref => {
    const p = getProposal(resourceHref);
    if (p) {
      proposal = p;
    }
  });

  return proposal && proposal.status === 'SUBMITTED_FOR_REVIEW';
} */

// reject those IN_PROGRESS proposals related to a node being deleted by reviewer
// add them to the apiPending list to be updated in the api
function rejectRelatedProposalsToRemovedNode(resource, api, apiPending) {
  const inProgressProposals = [...api.proposals.values()].filter((p) => p.status === 'IN_PROGRESS'); // REFACTOR: called from updateApiPending which gets newDocumentAPi => ok
  // build a map with <proposal, relatedResources>
  const inProgressProposalsWithRelatedResources = inProgressProposals.reduce((map, proposal) => {
    map.set(proposal.key, Array.from(new Set(getProposalRelatedResourcesHrefs(proposal))));
    return map;
  }, new Map());

  // link proposals sharing some related resource, used to identfy proposals in deleted node children
  inProgressProposals.forEach((proposal) => {
    let relatedResources = inProgressProposalsWithRelatedResources.get(proposal.key);
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of inProgressProposalsWithRelatedResources) {
      if (key !== proposal.key && relatedResources.some((res) => value.includes(res))) {
        relatedResources = relatedResources.concat(value);
        inProgressProposalsWithRelatedResources.set(
          proposal.key,
          Array.from(new Set(relatedResources))
        );
      }
    }
  });

  return inProgressProposals.map((proposal) => {
    const proposalRelatedResourcesHrefs = inProgressProposalsWithRelatedResources.get(proposal.key);

    if (proposalRelatedResourcesHrefs.includes(resource.href)) {
      const updatedProposal = {
        ...proposal,
        status: 'REJECTED',
      };

      apiPending.proposals = apiPending.proposals.filter(
        (p) => p.href !== `/proposals/${proposal.key}`
      );
      apiPending.proposals.push({
        verb: 'PUT',
        href: `/proposals/${proposal.key}`,
        body: updatedProposal,
      });
      console.log('CHECK in_progress proposals for:', resource.href, updatedProposal);
    }
    return proposal;
  });
}

/**
 * Create the arrays of api pending items according to the type of the pendingActions.
 * The apiPending item body for CREATE/PATCH will be the cached api resources with the
 * pendingAction applied.
 */
export const updateApiPending = (
  api,
  nodeHrefsThatArePartOfSubmittedProposal,
  pendingActions,
  mode,
  rootKey,
  me
) => {
  const apiPending = {
    content: [],
    relations: [],
    proposals: [],
    webpages: [],
    fileUploads: [],
    fileDeletes: [],
    externalContent: [],
    newsletterSettings: [],
  };

  pendingActions.forEach((pendingAction) => {
    // consider special case where reviewers can edit nodes without proposals
    // and save directly to content api (#17659)
    const resourceShouldBeCreatedAsContentInReviewMode =
      shouldResourceBeCreatedAsContentInReviewMode(
        mode,
        nodeHrefsThatArePartOfSubmittedProposal,
        pendingAction.resources
      );

    if (
      (mode === 'EDIT' ||
        pendingAction.type === 'ACCEPT_PROPOSAL' ||
        resourceShouldBeCreatedAsContentInReviewMode) &&
      pendingAction.type !== 'PATCH_PROPOSAL'
    ) {
      pendingAction.resources.forEach((resource) => {
        const resourceType = getResourceType(resource.href);

        switch (resource.type || pendingAction.type) {
          case 'CREATE': {
            apiPending[resourceType] = apiPending[resourceType].filter(
              (p) => p.href !== resource.href
            );
            apiPending[resourceType].push({
              verb: 'PUT',
              href: resource.href,
              body: {
                ...resource.body,
                $$new: resource.body.$$new || false,
              },
            });

            break;
          }

          case 'DELETE': {
            apiPending[resourceType] = apiPending[resourceType].filter(
              (p) => p.href !== resource.href
            );
            if (resourceType === 'content') {
              // remove fileUploads from to delete items.
              apiPending.fileUploads = apiPending.fileUploads.filter(
                (p) => p.body.relatedTo.href !== resource.href
              );
            }
            apiPending[resourceType].push({
              verb: 'DELETE',
              href: resource.href,
              // body: api[resourceType] ? api[resourceType].get(resource.href) : undefined, /// ??? WHY does a DELETE have a BODY???
            });
            if (
              resourceShouldBeCreatedAsContentInReviewMode ||
              pendingAction.type === 'ACCEPT_PROPOSAL'
            ) {
              // removing a node should reject any possible IN_PROGRESS suggestion related to it (#18222)
              rejectRelatedProposalsToRemovedNode(resource, api, apiPending);
            }

            break;
          }

          case 'PATCH': {
            // first check if can be found already added/updated in apiPending
            let node = apiPending[resourceType].find(
              (p) => p.href === resource.href && p.verb === 'PUT'
            );
            if (!node) {
              node = api[resourceType].get(resource.href);
            } else {
              node = node.body;
            }

            console.log('PATCHING node:', node, resource.patch);

            if (node) {
              apiPending[resourceType] = apiPending[resourceType].filter(
                (p) => p.href !== resource.href
              );
              const newBody = jsonPatch.apply({ ...node }, resource.patch);
              apiPending[resourceType].push({
                verb: 'PUT',
                href: resource.href,
                body: newBody,
              });

              // Update pending attachment texts
              apiPending.fileUploads = apiPending.fileUploads.map((upload) => {
                const pendingUpload = newBody.attachments?.find((a) => a.key === upload.body.key);
                if (pendingUpload) {
                  return {
                    ...upload,
                    body: {
                      ...upload.body,
                      alt: pendingUpload.alt,
                      description: pendingUpload.description,
                    },
                  };
                }
                return upload;
              });
            }
            break;
          }

          case 'UPLOAD': {
            resource.body.relatedTo = resource.relatedTo;

            // update content with a PATCH for attachments adding a new one
            const existingNode = apiPending.content.find((p) => p.href === resource.relatedTo.href);
            const node =
              existingNode?.body || api.content.get(resource.relatedTo.href) || resource.node;

            if (!node) {
              break;
            }

            if (resource.body.key) {
              node.attachments = node.attachments.filter((a) => a.key !== resource.body.key);
              node.attachments.push(resource.body);
            }

            if (node.html) {
              // always need to keep the attachment type CONTENT text
              node.attachments = node.attachments.map((attachment) => {
                if (attachment.type === 'CONTENT') {
                  return {
                    ...attachment,
                    text: node.html,
                  };
                }
                return attachment;
              });
            }

            apiPending.content = apiPending.content.filter(
              (p) => p.href !== `/content/${node.key}`
            );
            apiPending.content.push({
              verb: 'PUT',
              href: `/content/${node.key}`,
              body: node,
            });

            // update fileUploads
            // if (resource.body.file) { Removed because of accept suggestions would otherwise not work
            apiPending[resourceType] = apiPending[resourceType].filter(
              (p) => p.href !== resource.href
            );
            apiPending[resourceType].push({
              verb: 'PUT',
              href: resource.href,
              body: resource.body,
            });

            break;
          }

          case 'DELETE_UPLOAD': {
            // update content with a PATCH for attachments adding a new one
            const hrefParts = resource.href.split('/');
            const attachmentKey = hrefParts[hrefParts.length - 1];
            const pendingNodes = apiPending.content.filter(
              (pc) => pc.href === resource.relatedTo.href
            );
            const currentNode =
              pendingNodes.length > 0
                ? pendingNodes[pendingNodes.length - 1].body
                : api.content.get(resource.relatedTo.href);

            if (!currentNode) {
              break;
            }

            const attachment = currentNode.attachments.find((a) => a.key === attachmentKey);
            const newNode = {
              ...currentNode,
              attachments: currentNode.attachments
                .filter((a) => a.key !== attachmentKey)
                .map((a) => {
                  // always need to keep the attachment type CONTENT text
                  if (a.type === 'CONTENT') {
                    return { ...a, text: a.text || currentNode.html };
                  }
                  return a;
                }),
            };

            if (!apiPending.fileUploads.find((p) => p.href === resource.href)) {
              // if the deleted file was found in the uploads, then we don't have to send a DELETE to the API because it never existed there.
              apiPending.fileDeletes.push({
                href: attachment && attachment.href ? attachment.href : resource.href,
                verb: 'DELETE',
              });
            }

            // remove the deleted att from the uploads if present.
            apiPending.fileUploads = apiPending.fileUploads.filter((p) => p.href !== resource.href);

            apiPending.content = apiPending.content.filter(
              (p) => p.href !== `/content/${newNode.key}`
            );
            apiPending.content.push({
              verb: 'PUT',
              href: `/content/${newNode.key}`,
              body: newNode,
            });

            break;
          }

          default: {
            break;
          }
        }
      });
    } else if (
      mode === 'SUGGESTING' ||
      mode === 'REVIEWING' ||
      pendingAction.updateProposalsInEditMode
    ) {
      updateApiPendingProposals(pendingAction, apiPending, api.proposals, rootKey, me, mode);
    }
  });

  return apiPending;
};

export const fillRelationsWithExpandedResources = (relations, expandedResources) => {
  return [...relations.values()].map((relation) => {
    if (relation.relationtype !== 'IS_PART_OF') {
      return {
        ...relation,
        to: {
          ...relation.to,
          $$expanded: expandedResources[relation.to.href],
        },
      };
    }
    return relation;
  });
};

export const updateApiWithPendingChanges = (api, apiPending, mode) => {
  const apiWithPendingChanges = {
    content: new Map(api.content),
    relations: new Map(api.relations),
    proposals: new Map(api.proposals),
    webpages: new Map(api.webpages),
    fileUploads: new Map(api.fileUploads),
    newsletterSettings: new Map(api.newsletterSettings),
  };

  // 1. update apiWithPendingChanges with data in apiPending
  applyApiPending(apiWithPendingChanges, apiPending);

  // 2. apply proposals info only if certain mode is active
  if (mode !== 'EDIT') {
    applyProposals(apiWithPendingChanges, mode);
  }

  // 3. relations from and to grouped by content resource (needed to create the document tree)
  const relationsTo = {};
  const relationsFrom = {};

  [...apiWithPendingChanges.relations.values()].forEach((relation) => {
    if (!relationsTo[relation.to.href]) {
      relationsTo[relation.to.href] = [];
    }
    relationsTo[relation.to.href].push(relation);

    if (!relationsFrom[relation.from.href]) {
      relationsFrom[relation.from.href] = [];
    }
    relationsFrom[relation.from.href].push(relation);
  });

  apiWithPendingChanges.contentRelations = {
    from: relationsFrom,
    to: relationsTo,
  };

  return apiWithPendingChanges;
};

/**
 * In suggestion mode a node can be removed if it doesn't have childs
 * with submitted suggestions already #17581
 * @param {*} key
 * @param {*} apiWithPendingChanges
 */
export const isValidSuggestionDeleteAction = (mode, nodeKeys, proposals) => {
  if (mode !== 'SUGGESTING') {
    return true;
  }

  for (const key of nodeKeys) {
    const proposal = proposals.get(`/content/${key}`);
    if (
      proposal &&
      getProposalType(proposal) !== 'DELETE' &&
      proposal.status === 'SUBMITTED_FOR_REVIEW'
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Checks whether a given relation change will be patched by a later change.
 * Eg when a node is dragged to another place.
 * @param {String} relHref Permalink of the relation.
 * @param {Number} index The array index of the change.
 * @param {Array} changes The list of requested changes in a proposal.
 * @returns {Boolean} True if the relation is patched, false if it isn't.
 */
const isRelationPatched = (relHref, index, changes) => {
  return changes.some(
    (c, i) =>
      i > index &&
      c.appliesTo.href === relHref &&
      c.patch.some((p) => p.op === 'replace' && p.path === '/to')
  );
};

/**
 * Checks if a given node contains a given proposal.
 * To take dragging of nodes into account we have to check other changes as well :
 * an IS_PART_OF relation that was created or patched could be overridden by a later patch.
 * @param {String} nodeKey The given node's key.
 * @param {Object} proposal The given proposal.
 * @returns {Boolean} True if the proposal contains a change in the node itself or is a newly created child.
 * False in all other cases.
 */
const isProposalPartOfNode = (nodeKey, proposal) => {
  return proposal.listOfRequestedChanges.some((change, index, list) => {
    switch (change.type) {
      // new nodes within the given node
      case proposalTypes.create:
        return (
          change.resource.relationtype === relationTypes.isPartOf &&
          nodeKey === getResourceKey(change.resource.to.href) &&
          !isRelationPatched(change.appliesTo.href, index, list)
        );
      // changes in given node
      case proposalTypes.patch:
        return (
          nodeKey === getResourceKey(change.appliesTo.href) ||
          (change.patch.some(
            (p) =>
              p.op === 'replace' && p.path === '/to' && getResourceKey(p.value.href) === nodeKey
          ) &&
            !isRelationPatched(change.appliesTo.href, index, list))
        );
      case proposalTypes.upload:
      case proposalTypes.deleteUpload:
        return nodeKey === getResourceKey(change.relatedTo.href);
      default:
        return false;
    }
  });
};

/**
 * Calculates the not applicable proposals that are within a given set of nodes.
 * In edit mode both proposals with status IN_PROGRESS and SUBMITTED_FOR_REVIEW are considered.
 * In review mode only proposals with status IN_PROGRESS are considered.
 * In suggestion mode it is not possible to have not applicable proposals.
 * @param {Array} nodeKeys An array of node keys.
 * @param {Map} proposalsMap The proposals map in apiWithPendingChanges.
 * @param {String} mode The mode in which the document is shown (suggesting, reviewing or edit).
 * @returns {Map} A map of node keys with the not applicable proposals they contain. If a node doesn't contain any not applicable proposals there's no entry.
 */
export const getNotApplicableProposalsMap = (nodeKeys, proposalsMap, mode) => {
  return [...proposalsMap.values()]
    .filter(
      (p) =>
        mode === modes.edit || (mode === modes.review && p.status === proposalStatuses.inProgress)
    )
    .reduce((notApplicableProposalsMap, proposal) => {
      const nodeKey = nodeKeys.find((key) => isProposalPartOfNode(key, proposal));
      if (nodeKey) {
        const notApplicableProposals = notApplicableProposalsMap.get(nodeKey);
        if (notApplicableProposals) {
          notApplicableProposals.push(proposal);
        } else {
          notApplicableProposalsMap.set(nodeKey, [proposal]);
        }
      }
      return notApplicableProposalsMap;
    }, new Map());
};
