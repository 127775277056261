import React, { useEffect, useState } from 'react';

import FileSelector from '@UI/image/FileSelector';
import { EditAsideFile } from '@nodeTypeConfig/configTypes';
import { addAttachment, removeAttachments } from '@store/actions/documentActions';
import { addNotificationAction } from '@store/actions/notificationActions';
import { getBase64, getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import { useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import './AsideFile.scss';
import { selectAsideFileVM, selectShareableLink } from './asideFileSelectors';

const AsideFile: React.FC<{
  config: EditAsideFile;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();

  const attachment = useTypedSelector((state) => selectAsideFileVM(state, editHref, config));

  const readOnly = useReadOnly();

  const shareableLink = useTypedSelector((state) => selectShareableLink(state, editHref, config));

  const handleFileRemoved = () => {
    dispatch(removeAttachments(getResourceKey(editHref), [attachment?.key]));
    return true;
  };

  const validExtensions = config.options.validExtensions.split(',').map((ext) => ext.trim());

  const handleFileChanged = async ({
    file,
    invalid,
  }: {
    file: File;
    invalid?: boolean;
  }): Promise<void> => {
    if (invalid) {
      dispatch(
        addNotificationAction({
          type: 'ERROR',
          message: `Het bestand dat je probeert te uploaden is ongeldig. Gelieve enkel de volgende bestandstypen te uploaden: ${validExtensions.join(
            ', '
          )}.`,
        })
      );
      return;
    }

    const base64 = await getBase64(file);
    const newAttachment = {
      key: attachment?.key || uuidv4(),
      newKey: uuidv4(),
      type: config.options.type,
      name: file.name,
      size: file.size,
      $$base64: base64,
      isNew: true,
    };

    dispatch(addAttachment(getResourceKey(editHref), newAttachment, file));
  };

  const [fileLink, setFileLink] = useState<string | null>(null);

  // Load fileLink when on 'Edit' mode
  useEffect(() => {
    if (attachment) {
      setFileLink(attachment.fileLink);
    } else {
      setFileLink(null);
    }
  }, [attachment]);

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension && validExtensions.includes(`.${fileExtension}`)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setFileLink(reader.result);
        } else {
          console.error('FileReader result is not a string');
          setFileLink(null);
        }
      };
      reader.onerror = (error) => {
        console.error('Error: ', error);
      };
      handleFileChanged({ file });
    } else {
      handleFileChanged({ file, invalid: true });
    }
  };

  const removeFile = async () => {
    const remove = await handleFileRemoved?.();
    if (remove) {
      setFileLink(null);
    }
  };

  return (
    <>
      <div className={`panel panel-default ${readOnly ? 'aside-component-disabled' : ''}`}>
        <AsideChangeIndicator config={config} />
        {config.label && <label className="panel-heading control-label">{config.label}</label>}
        <div className="panel-body">
          <AsideValidation property={config.property} component={config.component}>
            <div className="file-component col-md-12" style={{ marginBottom: '1rem' }}>
              {fileLink && attachment && (
                <table className="aside-file-table">
                  <tr>
                    <td>Bestandsnaam:</td>
                    <td>
                      <a href={fileLink} target="_blank" rel="noopener noreferrer">
                        <img src={attachment.iconSrc} width="20" />
                        <span>{attachment.name}</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Type:</td>
                    <td>
                      <span>{attachment.fileType}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Grootte:</td>
                    <td>
                      <span>{attachment.fileSize}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Link:</td>
                    <td>
                      <div className="input-group">
                        <input
                          type="text"
                          readOnly
                          value={shareableLink}
                          onFocus={(e) => e.target.select()}
                          className="form-control"
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-default"
                            onClick={() => {
                              navigator.clipboard.writeText(shareableLink);
                              dispatch(
                                addNotificationAction({
                                  type: 'SUCCESS',
                                  message: 'Link gekopieerd naar klembord.',
                                })
                              );
                            }}
                          >
                            Kopieer
                          </button>
                        </span>
                      </div>
                    </td>
                  </tr>
                  {!readOnly && (
                    <button className="btn btn-danger delete-img" onClick={removeFile}>
                      <span className="glyphicon glyphicon-remove"></span>
                      <span>Verwijderen</span>
                    </button>
                  )}
                </table>
              )}
              {!fileLink && (
                <div>
                  <FileSelector onFileSelected={uploadFile} label={config.label} />
                </div>
              )}
            </div>
          </AsideValidation>
        </div>
      </div>
    </>
  );
};

export default AsideFile;

AsideFile.displayName = 'AsideFile';

AsideFile.propTypes = {
  config: any,
};
