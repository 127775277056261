import { NamedSetHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { selectIsRoot } from '@newStore/documentUI/documentUISelectors';
import { EditAsideAccessRights } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { escapeRegExp, set } from 'lodash';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessRight, accessRights } from '@store/helpers/accessRights';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import AsideValidation from '../asideValidationErrors/AsideValidation';

import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import {
  selectCurrentSelectedAccessRightsOption,
  selectIsGlobalDocument,
} from './asideAccessRightsSelectors';
import { useReadOnly } from '../../../hooks/UseReadonly';
import './AsideAccessRights.scss';

const AsideAccessRights: React.FC<{
  config: EditAsideAccessRights;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const readOnly = useReadOnly();

  const itemValue: NamedSetHref[] | undefined = useInitialValue(config.property);
  const isRoot = useSelector((state: RootState) => selectIsRoot(state, editHref));
  const isInherited = !isRoot && !itemValue;
  const isGlobalDocument = useSelector((state: RootState) => selectIsGlobalDocument(state));

  const selectedAccessRights: AccessRight = useSelector((state: RootState) =>
    selectCurrentSelectedAccessRightsOption(state, editHref, config)
  );

  const onChange = (value: AccessRight) => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, value.namedsets)));
  };

  const backToInherited = () => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, undefined)));
  };

  const isUsed = useSelector((state: RootState) => state.document.isIncludedInProTheme);

  const filterData = (data, str) => {
    if (str.length === 0) {
      return data;
    }
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />

      <div className={`access-rights${isInherited ? ' inherited' : ''}`}>
        <div className="title">
          <label className="control-label">{config.label}</label>
          {isInherited && <span className="inherited-info">Overgenomen</span>}
          {isUsed && isGlobalDocument && <span className="inherited-info">berekend</span>}
          {!isInherited && !isRoot && (
            <span className="inherited-revert" onClick={backToInherited}>
              Overnemen
            </span>
          )}
        </div>

        <KovResourcePicker
          value={selectedAccessRights}
          localData={Object.values(accessRights)}
          display={(i: AccessRight) => i.label}
          onChange={onChange}
          disabled={readOnly || (isUsed && isGlobalDocument)}
          filter={filterData}
          className="access-rights-picker"
        />
        {isGlobalDocument && (
          <div>
            <div className="ng-scope">
              <label className="warning-label ng-binding">
                {`${
                  isUsed
                    ? 'De afscherming van dit document wordt bepaald door de afscherming van de themapagina(s) waarin het is opgenomen.'
                    : 'Vul dit veld enkel in wanneer je een globaal document rechtstreeks wil delen (bv. in een teaser), en niet via een themapagina. Wanneer je dit document opneemt in een themapagina, dan vervalt deze afscherming.'
                }`}
              </label>
            </div>
          </div>
        )}
      </div>
    </AsideValidation>
  );
};

export default AsideAccessRights;

AsideAccessRights.displayName = 'AsideAccessRights';

AsideAccessRights.propTypes = {
  config: any,
};
