import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';
import { get, isEqual } from 'lodash';
import { teaserImportanceTranslations } from '@nodeTypeConfig/PRO/proNews/TEASER';

export const selectChangeMessageForTeaserImportance: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newValue =
        teaserImportanceTranslations[(get(content, config.property) as string) ?? ''];
      const oldValue =
        teaserImportanceTranslations[(get(originalContent, config.property) as string) ?? ''];

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      return stringDiffArrowFunction(newValue, oldValue);
    }
  );
