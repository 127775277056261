import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectApiWithPendingChangesRelationsToAndFromMap } from '@newStore/documentApi/documentApiSelectors';
import { selectReferencesRelation } from '@newStore/documentUI/transformContent/teaserSelectors';
import {
  createTypedSelector,
  isValidExternalUrl,
  isValidInternalReference,
} from '@newStore/genericHelpers';
import { EditComponent } from '@nodeTypeConfig/configTypes';
import { pick } from 'lodash';

import { createError } from '../createError';
import { AsideValidationRule, ValidationResult } from '../validationTypes';

/**
 * A node can reference either to content or to an ExternalUrl
 * Method is called selectIsNodeReferenceValid but the UI does not allow to enter invalid internal references,
 * for the external link however, the user can enter free text so this validation rule is mainly about the validity of an external url
 * There are some other cases where you also can enter external links, but we can not reuse this validation rule because the reference is not coming from the node itself:
 * - teasers (see validation below) can point to a training (but that is not the node itself, but the object below so that is in the teaserLinkedContentValidation rule)
 * - references inside a reference group can point to events but those are not nodes either but part of the reference group
 */
export const selectIsNodeReferenceValid = createTypedSelector(
  [
    (state: RootState, href: ContentHref) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).from[href],
    (state, href, parent, config: EditComponent) => config,
  ],
  (relationsFrom, config): ValidationResult => {
    // in the menuItemReference you can only make one REFERENCE relation
    const referenceRelation = relationsFrom.find((rel) => rel.relationtype === 'REFERENCES');
    if (!referenceRelation) {
      return true;
    }

    const reference = referenceRelation.to.href;
    if (!isValidInternalReference(reference) && !isValidExternalUrl(reference)) {
      return createError(
        'referencedResourceNotValid',
        'selectInvalidReferences',
        `<strong>${reference}</strong> is geen geldige <strong>externe url</strong>.${
          !reference.startsWith('http') ? ' De url moet beginnen met https:// of http://.' : ''
        }`,
        pick(config, ['component', 'property'])
      );
    }

    return true;
  }
);

/**
 * Teasers can only point to anohter website (content), a training or an External Url
 */
export const selectIsValidReferenceFromTeaser: AsideValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectReferencesRelation(state, href),
    (state, href, parent, config: EditComponent) => config,
  ],
  (referenceRelation, config): ValidationResult => {
    const referenceLink = referenceRelation?.to.href;
    if (referenceLink) {
      if (!isValidInternalReference(referenceLink) && !isValidExternalUrl(referenceLink)) {
        return createError(
          'externalUrlNotValidOrUnsupported',
          'selectResourceLinkValidation',
          `<strong>${referenceLink}</strong> is geen geldige <strong>externe url</strong>.${
            !referenceLink.startsWith('http')
              ? ' De url moet beginnen met https:// of http://.'
              : ''
          }`,
          pick(config, ['component', 'property'])
        );
      }
    }

    return true;
  }
);
