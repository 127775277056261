import { AttachmentAttachmentMetaInfo, ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { selectDocumentRootType } from '@newStore/documentUI/nodeTypeConfigSelectors';
import {
  getAttachmentFileSize,
  getFileType,
  getIconSrc,
} from '@newStore/documentUI/transformContent/attachmentHelpers';
import { htmlDiffFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';
import { createError } from '@newStore/validation/createError';
import { AsideValidationRule, ValidationResult } from '@newStore/validation/validationTypes';
import {
  AsideChangeMessageSelector,
  EditAsideFile,
  EditComponent,
  RequiredType,
} from '@nodeTypeConfig/configTypes';
import { get, isEqual, pick } from 'lodash';
import { settings } from '../../../../config/settings';

const getAsideFileVM = (attachment: AttachmentAttachmentMetaInfo | null) => {
  if (!attachment) {
    return null;
  }
  return {
    key: attachment.key,
    name: attachment.name,
    fileLink: getAttachmentUrl(attachment),
    iconSrc: getIconSrc(attachment) as string,
    fileType: getFileType(attachment) as string,
    fileSize: getAttachmentFileSize(attachment),
  };
};

const getAttachmentChangeMessageHtml = (attachment: ReturnType<typeof getAsideFileVM>) => {
  if (!attachment) {
    return '';
  }
  const tableTemplate = `
  <table className="aside-file-table">
    <tr>
      <td>Bestandsnaam:</td>
      <td>
          <img src="${attachment.iconSrc}" width="20" />
          <span>${attachment.name}</span>
      </td>
    </tr>
    <tr>
      <td>Type:</td>
      <td>
        <span>${attachment.fileType}</span>
      </td>
    </tr>
    <tr>
      <td>Grootte:</td>
      <td>
        <span>${attachment.fileSize}</span>
      </td>
    </tr>
  </table>`;
  return tableTemplate;
};

export const selectAsideFileVM = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref, config: EditAsideFile) => config,
  ],
  (content, config) => {
    const attachment: AttachmentAttachmentMetaInfo | null =
      (get(content, config.property).find(
        (z) => z.type === config.options.type
      ) as AttachmentAttachmentMetaInfo) || null;
    return getAsideFileVM(attachment);
  }
);

export const selectAttachmentReplacementNameRule: AsideValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectRawContentItem(state, href),
    (state, href, parent, config: EditComponent) => config as EditAsideFile,
  ],
  (content, originalContent, config): ValidationResult => {
    if (!config.property) {
      console.error('No property found for config', config);
      throw Error(`Can not convert ${config.property} to a string`);
    }

    const newAtt =
      (get(content, config.property)?.find(
        (z) => z.type === config.options.type
      ) as AttachmentAttachmentMetaInfo) || null;
    const oldAtt =
      (get(originalContent, config.property)?.find(
        (z) => z.type === config.options.type
      ) as AttachmentAttachmentMetaInfo) || null;

    if (oldAtt && newAtt && oldAtt.name !== newAtt.name) {
      return createError(
        'replacementAttachmentNotSameName',
        'selectAttachmentReplacementNameRule',
        `Je document heeft een nieuwe naam waardoor de bestaande link naar dit document niet meer zal werken. Wil je de link toch behouden (bv. omdat deze gedeeld werd via de nieuwsbrief), behoud dan je oude bestandsnaam: <strong>${oldAtt.name}</strong>`,
        pick(config, ['component', 'property']),
        RequiredType.WARNING
      );
    }
    return true;
  }
);

export const selectChangeMessageForFile: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config as EditAsideFile,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newAtt =
        (get(content, config.property)?.find(
          (z) => z.type === config.options.type
        ) as AttachmentAttachmentMetaInfo) || null;
      const oldAtt =
        (get(originalContent, config.property)?.find(
          (z) => z.type === config.options.type
        ) as AttachmentAttachmentMetaInfo) || null;

      const newValue = getAsideFileVM(newAtt);
      const oldValue = getAsideFileVM(oldAtt);

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      const diffHtml = htmlDiffFunction(
        getAttachmentChangeMessageHtml(newValue),
        getAttachmentChangeMessageHtml(oldValue)
      );

      return diffHtml;
    }
  );

export const selectShareableLink = createTypedSelector(
  [
    (state, _href: ContentHref) => selectDocumentRootType(state),
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref, config: EditAsideFile) => config,
  ],
  (rootType, content, config) => {
    // getStableLink() {
    //   if (!this.uploadedDocument || !this.uploadedDocument.file) {
    //     return '';
    //   }
    //   const href = this.uploadedDocument.proposal
    //     ? `/content/${this.uploadedDocument.key}/attachments/${this.uploadedDocument.file.name}`
    //     : this.uploadedDocument.file.href;
    //   return this.sDocument.$$root.tags.includes(documentTags.webPage2) ||
    //     this.sDocument.$$root.tags.includes(documentTags.proNewsItem)
    //     ? `${this.settings.apisAndUrls.proWebsite}/download${href}`
    //     : this.settings.apisAndUrls.contentApi + href;
    // }
    const attachment =
      (get(content, config.property).find(
        (z) => z.type === config.options.type
      ) as AttachmentAttachmentMetaInfo) || null;

    if (attachment && attachment.href) {
      const href = attachment.href.startsWith('/proposals/')
        ? `${content.$$meta.permalink}/attachments/${attachment.name}`
        : attachment.href;

      return `${settings.apisAndUrls.proWebsite}/download${href}`;
    }
    return '';
  }
);
