import React from 'react';
import { useReadOnly } from '../../hooks/UseReadonly';

interface FileSelectorProps {
  onFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const FileSelector: React.FC<FileSelectorProps> = ({ onFileSelected, label }) => {
  const readOnly = useReadOnly();
  return (
    <div>
      <div
        className="drop-zone"
        style={{ marginRight: 0 }}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onFileSelected({
              target: { files: e.dataTransfer.files },
            } as React.ChangeEvent<HTMLInputElement>);
            e.dataTransfer.clearData();
          }
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        Sleep je {label.toLowerCase()} hier
      </div>

      <span className="input-group">
        <span className="input-group-btn">
          <span className="btn btn-default btn-file">
            Blader...
            <input type="file" readOnly={readOnly} onChange={onFileSelected} />
          </span>
        </span>
        <input type="text" className="form-control" readOnly />
      </span>
    </div>
  );
};

export default FileSelector;
