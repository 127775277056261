/* eslint-disable @typescript-eslint/no-explicit-any */

import { EventHref } from './eventTypes';
import {
  EducationalActivityTypeHref,
  StudyProgrammeGroupHref,
  StudyProgrammeHref,
} from './samTypes';
import { IApiResouce } from './sriTypes';
import { TrainingModuleHref } from './trainingTypes';

export type Guid = `${string}-${string}-${string}-${string}-${string}`;

export type ContentKey = Guid & { __brand: 'ContentKey' };
export type ContentRelationKey = Guid & { __brand: 'ContentRelationKey' };
export type ContentAttachmentKey = Guid & { __brand: 'ContentAttachmentKey' };

export type WebSiteKey = Guid & { __brand: 'WebSiteKey' };
export type WebTemplateKey = Guid & { __brand: 'WebTemplate' };
export type WebPageKey = Guid & { __brand: 'WebPageKey' };

export type ProposalKey = Guid & { __brand: 'ProposalKey' };

export type PersonKey = Guid & { __brand: 'PersonKey' };

export type NamedSetKey = Guid & { __brand: 'NamedSetKey' };
export type NamedSetHref = `/namedsets/${NamedSetKey}`;

export type CoverageKey = Guid & { __brand: 'CoverageKey' };
export type CoverageHref = `/dioceses/${CoverageKey}`;

export type VersionKey = Guid & { __brand: 'VersionKey' };
export type VersionHref = `/versions/${VersionKey}`;

export type RedactieHref = `/edit/${ContentKey}`; // & { __brand: 'ContentHref' };
export type ContentHref = `/content/${ContentKey}`; // & { __brand: 'ContentHref' };
export type ContentRelationHref = `/content/relations/${ContentRelationKey}`; // & { __brand: 'ContentRelationHref' };
export type ContentAttachmentHref = `${ContentHref}/attachments/${ContentAttachmentKey}`; // & { __brand: 'ContentAttachmentHref' };

export type WebSiteHref = `/web/sites/${WebSiteKey}`; // & { __brand: 'WebSiteHref' };
export type WebTemplateHref = `/web/templates/${WebTemplateKey}`; // & { __brand: 'WebTemplateHref' };
export type WebPageHref = `/web/pages/${WebPageKey}`; // & { __brand: 'WebPageHref' };

export type ProposalHref = `/proposals/${ProposalKey}`; // & { __brand: 'ProposalHref' };

export type PersonHref = `/persons/${PersonKey}`; // & { __brand: 'PersonHref' };

export type ExternalUrl = `http${string}`;

// ++++++++++++++++++++++ API TYPES ++++++++++++++++++++++++++
export type CommonResourceType =
  | 'COMMONS_SUBJECT'
  | 'COMMONS_STUDY_PROGRAMME'
  | 'COMMONS_STUDY_PROGRAMME_GROUP'
  | 'COMMONS_STUDIEGEBIED'
  | 'VAK';
export type ResourceType =
  | 'CONTENT'
  | 'RELATION'
  | 'WEBPAGE'
  | 'PERSON'
  | 'COMMONS_SUBJECT'
  | 'COMMONS_STUDY_PROGRAMME'
  | 'COMMONS_STUDY_PROGRAMME_GROUP'
  | 'COMMONS_STUDIEGEBIED'
  | 'VAK'
  | 'COMMONS_EDUCATIONAL_ACTIVITIES_TYPE'
  | 'NEWSLETTER_SENDER'
  | 'CONTENT_PROPOSAL'
  | 'WEBSITE'
  | 'WEB_TEMPLATE'
  | 'NEWSLETTER_SETTINGS'
  | 'ORGANISATIONAL_UNIT'
  | 'ORGANISATION'
  | 'NAMED_SET'
  | 'MODULE' // Training Module
  | 'EVENT';

// select distinct tag from content, unnest(tags) as tag order by tag;
export type ContentTag =
  | 'ADVISE'
  | 'ANNOUNCEMENT'
  | 'ARREST'
  | 'ARTICLE'
  | 'BILL'
  | 'CALENDAR'
  | 'CAO'
  | 'CONVENANT'
  | 'CURRICULUM'
  | 'DECISIONS'
  | 'DOCUMENTATIE'
  | 'DRAFT_LEGISLATION'
  | 'FAQ'
  | 'FORM'
  | 'IMAGE'
  | 'INLINE_ATTACHMENTS_GROUP'
  | 'DOWNLOAD'
  | 'INVITATION'
  | 'JOB_OFFER'
  | 'LECTURE'
  | 'LEGISLATION'
  | 'LETTER'
  | 'LINK'
  | 'LIST'
  | 'MANUAL'
  | 'MARK_EXPLANATION'
  | 'MODEL'
  | 'NEWS_ITEM'
  | 'NEWSLETTER'
  | 'PARLIAMENTARY_QUESTION'
  | 'PRACTICAL_EXAMPLE'
  | 'PRESENTATION'
  | 'PRESS_RELEASE'
  | 'PRONEWSITEM'
  | 'PRONEWSLETTER'
  | 'PRONEWSLETTERTEMPLATE'
  | 'PRO_UNSTRUCTURED_DOCUMENT'
  | 'PUBLICATION'
  | 'QUESTION'
  | 'REPORT'
  | 'RULES'
  | 'SHARED_ATTACHMENTS_GROUP'
  | 'SHARED_FAQ'
  | 'SHARED_MINI_DATABASE_ITEM'
  | 'TEASER'
  | 'TERM'
  | 'THEME_ROOT'
  | 'VACANCY'
  | 'VISIONTEXT'
  | 'VISION_TEXT'
  | 'WEBPAGE' // webpage for Zill
  | 'WEBPAGE2' // webpage for pro = PRO-Theme
  | 'WWW_CONTENT'
  | 'ZILL_ILLUSTRATION';

// select distinct ' | "' || type || '"' from content order by ' | "' || type || '"';
// eslint-disable-next-line no-shadow
export enum ContentType {
  ATTACHMENT = 'ATTACHMENT',
  ATTACHMENTS_GROUP = 'ATTACHMENTS_GROUP',
  CURRCICULUM_ODET_LEERGEBIED = 'CURRCICULUM_ODET_LEERGEBIED',
  CURRICULUM_CLUSTER = 'CURRICULUM_CLUSTER',
  CURRICULUM_ODET = 'CURRICULUM_ODET',
  CURRICULUM_ODET_DEVELOPMENT_GOAL = 'CURRICULUM_ODET_DEVELOPMENT_GOAL',
  CURRICULUM_ODET_ENDTERM = 'CURRICULUM_ODET_ENDTERM',
  CURRICULUM_ODET_LEERGEBIED = 'CURRICULUM_ODET_LEERGEBIED',
  CURRICULUM_ODET_KEY_COMPETENCE = 'CURRICULUM_ODET_KEY_COMPETENCE',
  CURRICULUM_ODET_LEVEL = 'CURRICULUM_ODET_LEVEL',
  CURRICULUM_ODET_SUBSECTION = 'CURRICULUM_ODET_SUBSECTION',
  CURRICULUM_THEME = 'CURRICULUM_THEME',
  CURRICULUM_ZILL = 'CURRICULUM_ZILL',
  CURRICULUM_ZILL_CLUSTER = 'CURRICULUM_ZILL_CLUSTER',
  CURRICULUM_ZILL_CONTENT_GROUP = 'CURRICULUM_ZILL_CONTENT_GROUP',
  CURRICULUM_ZILL_DEVELOPMENT_CONTENT = 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT',
  CURRICULUM_ZILL_DEVELOPMENT_FIELD = 'CURRICULUM_ZILL_DEVELOPMENT_FIELD',
  CURRICULUM_ZILL_DEVELOPMENT_THEME = 'CURRICULUM_ZILL_DEVELOPMENT_THEME',
  CURRICULUM_ZILL_GENERIC_GOAL = 'CURRICULUM_ZILL_GENERIC_GOAL',
  CURRICULUM_ZILL_LEERLIJN = 'CURRICULUM_ZILL_LEERLIJN',
  CURRICULUM_ZILL_LEERLIJN_CLUSTER = 'CURRICULUM_ZILL_LEERLIJN_CLUSTER',
  CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE = 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE',
  CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE = 'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE',
  DOCUMENTATIE = 'DOCUMENTATIE',
  EXAMPLE = 'EXAMPLE',
  FAQ = 'FAQ',
  FAQ_GROUP = 'FAQ_GROUP',
  IMAGE = 'IMAGE',
  IMAGE_GROUP = 'IMAGE_GROUP',
  LEGAL = 'LEGAL',
  LINK_GROUP = 'LINK_GROUP',
  LLINKID_BASIC_MATERIAL_REQUIREMENTS = 'LLINKID_BASIC_MATERIAL_REQUIREMENTS',
  LLINKID_CONCORDANCE = 'LLINKID_CONCORDANCE',
  LLINKID_CONCRETIZATION = 'LLINKID_CONCRETIZATION',
  LLINKID_CONTEXT = 'LLINKID_CONTEXT',
  LLINKID_CORRELATION_ENDTERMS = 'LLINKID_CORRELATION_ENDTERMS',
  LLINKID_CURRICULUM = 'LLINKID_CURRICULUM',
  LLINKID_EXTRA_GOAL_INFORMATION = 'LLINKID_EXTRA_GOAL_INFORMATION',
  LLINKID_GOAL = 'LLINKID_GOAL',
  LLINKID_GOAL_DEMARCATION = 'LLINKID_GOAL_DEMARCATION',
  LLINKID_GOAL_EXPLANATION = 'LLINKID_GOAL_EXPLANATION',
  LLINKID_GOAL_LIST = 'LLINKID_GOAL_LIST',
  LLINKID_GOAL_SECTION = 'LLINKID_GOAL_SECTION',
  LLINKID_GUIDING_PRINCIPLE = 'LLINKID_GUIDING_PRINCIPLE',
  LLINKID_GUIDING_PRINCIPLE_GROUP = 'LLINKID_GUIDING_PRINCIPLE_GROUP',
  LLINKID_INITIAL_SITUATION = 'LLINKID_INITIAL_SITUATION',
  LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT = 'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT',
  LLINKID_PEDAGOGICAL_TIP = 'LLINKID_PEDAGOGICAL_TIP',
  LLINKID_STATIC_PART = 'LLINKID_STATIC_PART',
  MAGAZINE = 'MAGAZINE',
  MARK_EXPLANATION = 'MARK_EXPLANATION',
  MUSIC = 'MUSIC',
  PARAGRAPH = 'PARAGRAPH',
  QUESTION = 'QUESTION',
  QUOTE = 'QUOTE',
  REFERENCE = 'REFERENCE',
  REFERENCE_FRAME = 'REFERENCE_FRAME',
  REFERENCE_GROUP = 'REFERENCE_GROUP',
  SECTION = 'SECTION',
  SHARED_ATTACHMENTS_GROUP = 'SHARED_ATTACHMENTS_GROUP',
  SHARED_FAQ = 'SHARED_FAQ',
  SHARED_MINI_DATABASE_ITEM = 'SHARED_MINI_DATABASE_ITEM',
  SOURCE = 'SOURCE',
  STRUCTURED_DOCUMENT = 'STRUCTURED_DOCUMENT',
  SUMMARY = 'SUMMARY',
  TEASER = 'TEASER',
  TERM = 'TERM',
  THEME = 'THEME',
  UNSTRUCTURED_DOCUMENT = 'UNSTRUCTURED_DOCUMENT',
  VIDEO = 'VIDEO',
  VIDEO_GROUP = 'VIDEO_GROUP',
  VISION_TEXT = 'VISION_TEXT',
  VISION_TEXT_PARAGRAPH = 'VISION_TEXT_PARAGRAPH',
  VISION_TEXT_SECTION = 'VISION_TEXT_SECTION',
  WEBPAGE = 'WEBPAGE',
  ZILL_ILLUSTRATION = 'ZILL_ILLUSTRATION',
}

// export type ContentType = (typeof contentTypes)[number];

// export const ContentType = Object.fromEntries(contentTypes.map((type) => [type, type])) as {
//   [K in (typeof contentTypes)[number]]: K;
// };

export type AccessRights =
  | 'publiek'
  | 'gevoelige_informatie'
  | 'verslagen_directiecommissies'
  | 'informatie_voor_bestuurders';

export type WebConfigType =
  | 'THEME_HOME_FULL'
  | 'THEME_HOME_TEXT'
  | 'THEME_HOME_PICTURE'
  | 'THEME_PAGE'
  | 'MINI_DATABASE'
  | 'FAQ_PAGE'
  | 'TEMPORARY_PAGE'
  | 'DOWNLOAD_PAGE'
  | 'BLOG'
  | 'FALLBACK_MENU_LEVEL_2'
  | 'FALLBACK_MENU_LEVEL_3'
  | 'HOME_PAGE'
  | 'MINI_DATABASE_ITEM'
  | 'BLOG_ITEM'
  | 'WWW_HOME_PAGE'
  | 'WWW_DIARY'
  | 'WWW_THEME_HOME_TEXT'
  | 'WWW_THEME_OVERVIEW'
  | 'WWW_INFORMATION_PAGE'
  | 'WWW_CURRENT_THEME_PAGE'
  | 'WWW_HARD_CODED'
  | 'WWW_SCHOOL_SEARCH'
  | 'WWW_NEWS_OVERVIEW'
  | 'WWW_NEWS_DETAIL'
  | 'WWW_COOKIES_PAGE'
  | 'NEWS_ITEM_AND_TEASER_FALLBACK'
  | 'STATIC'
  | 'CURRICULA_NAVIGATION'
  | 'GLOBAL_TRAININGSDATABASE';

type GenericAttachmentMetaInfo = {
  href: ContentAttachmentHref;
  key: ContentAttachmentKey;
  name: string;
  contentType: string;
  $$meta?: {
    created?: string;
    modified?: string;
    deleted?: boolean;
  };
};
export type ContentAttachmentSpecificMetaInfo = GenericAttachmentMetaInfo & {
  type: 'CONTENT';
  text?: string;
};

type GenericImageAttachmentMetaInfo = GenericAttachmentMetaInfo & {
  width: number;
  height: number;
  size: number;
  alt?: string;
  description?: string;
  resized?: true;
};

export type IconAttachmentMetaInfo = GenericImageAttachmentMetaInfo & {
  type: 'ICON';
};

export type IllustrationAttachmentMetaInfo = GenericImageAttachmentMetaInfo & {
  type: 'ILLUSTRATION';
};

export type ThumbnailAttachmentMetaInfo = GenericImageAttachmentMetaInfo & {
  type: 'THUMBNAIL';
};

export type CoverImageAttachmentMetaInfo = GenericImageAttachmentMetaInfo & {
  type: 'COVER_IMAGE';
};

export type BackgroundImageAttachmentMetaInfo = GenericImageAttachmentMetaInfo & {
  type: 'BACKGROUND_IMAGE';
};

export type ContentAttachmentMetaInfo = GenericAttachmentMetaInfo &
  ContentAttachmentSpecificMetaInfo;
export type AttachmentAttachmentSpecificMetaInfo = {
  type: 'ATTACHMENT' | 'CONTENT'; // Shared attachments have type content
  contentType:
    | 'image/png'
    | 'image/jpeg'
    | 'image/tiff'
    | 'application/pdf'
    | 'application/msword'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    | 'application/vnd.ms-excel'
    | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    | 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    | 'application/vnd.ms-powerpoint'
    | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    | 'application/vnd.openxmlformats-officedocument.presentationml.template'
    | 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
  size: number;
  $$base64?: string;
};

export type AttachmentAttachmentMetaInfo = GenericAttachmentMetaInfo &
  AttachmentAttachmentSpecificMetaInfo;

export type AttachmentToUploadMetaInfo = AttachmentAttachmentSpecificMetaInfo & {
  key: string;
  name: string;
  isNew: true;
  created: Date;
};

export type VideoAttachmentMetaInfo = GenericAttachmentMetaInfo & {
  type: 'VIDEO';
  playbackUrl: string;
};

export type GlobalAttachmentMetaInfo = GenericAttachmentMetaInfo & {
  type: 'CONTENT' | 'CONTENT_PREVIEW' | 'THUMBNAIL';
};

export type AttachmentMetaInfo =
  | AttachmentAttachmentMetaInfo
  | IllustrationAttachmentMetaInfo
  | CoverImageAttachmentMetaInfo
  | BackgroundImageAttachmentMetaInfo
  | ThumbnailAttachmentMetaInfo
  | ContentAttachmentMetaInfo
  | IconAttachmentMetaInfo
  | VideoAttachmentMetaInfo
  | GlobalAttachmentMetaInfo;

export type AttachmentImageMetaInfo =
  | IllustrationAttachmentMetaInfo
  | ThumbnailAttachmentMetaInfo
  | CoverImageAttachmentMetaInfo
  | BackgroundImageAttachmentMetaInfo
  | IconAttachmentMetaInfo;

export type AttachmentType = AttachmentMetaInfo['type'];

export const isIllustrationAttachmentMetaInfo = (
  attachment: AttachmentMetaInfo
): attachment is IllustrationAttachmentMetaInfo => {
  return attachment.type === 'ILLUSTRATION';
};

export const isThumbnailAttachmentMetaInfo = (
  attachment: AttachmentMetaInfo
): attachment is ThumbnailAttachmentMetaInfo => {
  return attachment.type === 'THUMBNAIL';
};

export const isVideoAttachmentMetaInfo = (
  attachment: AttachmentMetaInfo
): attachment is VideoAttachmentMetaInfo => {
  return attachment.type === 'VIDEO';
};

export const isAttachmentAttachmentMetaInfo = (
  attachment: AttachmentMetaInfo
): attachment is AttachmentAttachmentMetaInfo => {
  return ['CONTENT', 'ATTACHMENT'].includes(attachment.type);
};

export const isContentAttachmentMetaInfo = (
  attachment: AttachmentMetaInfo
): attachment is ContentAttachmentMetaInfo => {
  return attachment.type === 'CONTENT';
};

export type ContentAttachment = {
  $$meta: {
    hash: string;
    created: string;
    modified: string;
  };
  /**
   * GUID for this attachment.
   */
  key: ContentAttachmentKey;
  /**
   * Name of the attachment
   */
  name?: string;
  /**
   * Content type of the attachment
   */
  contentType?: string;
  /**
   * External url, if needed, for the attachment
   */
  externalUrl?: string;
  /**
   * Type of the attachment.
   */
  type?:
    | 'CONTENT'
    | 'CONTENT_AS_TEXT'
    | 'CONTENT_PREVIEW'
    | 'COVER_IMAGE'
    | 'THUMBNAIL'
    | 'ICON'
    | 'ATTACHMENT'
    | 'BACKGROUND_IMAGE'
    | 'ILLUSTRATION'
    | 'VIDEO';
  /**
   * Link to download the content of the attachment. Only present in GET
   */
  href?: ContentAttachmentHref;
  /**
   * Text to be the content of the attachment. Only present in PUT
   */
  text?: string;
  rightsHolder?: {
    /**
     * A person/organisation/etc. owning or managing rights over the attachment.
     */
    href: string;
  };
  /**
   * Description (abstract).
   */
  description?: string;
  originalName?: string;
  size?: number;
  width?: number;
  height?: number;
}[];

type ContentMutations = {
  $$new?: boolean | undefined;
};

export type LlinkidGoalType = 'REGULAR' | 'DEEPENING' | 'EXTRA' | 'CHOICE';

export type Content = {
  $$meta: {
    permalink: ContentHref;
    type: 'CONTENT';
    deleted?: boolean;
    created?: string;
    modified?: string;
    version?: number;
    hash?: string;
  };
  key: ContentKey;
  title?: string;
  description?: string;
  shortdescription?: string;
  $$html?: string | undefined;
  type: ContentType;
  tags: ContentTag[];
  attachments: AttachmentMetaInfo[];
  issued?: string;
  /**
   * Identifier of the document.
   */
  identifiers?: string[];
  /**
   * Creators of the document.
   */
  creators?: string[];
  /**
   * Contributors of the document.
   */
  contributors?: string[];
  /**
   * Contacts of the document.
   */
  contacts?: string[];
  /**
   * Tells us for which regions this content is relevant.
   */
  coverage?: string[];
  /**
   * Education levels of the document.
   */
  mainstructures?: string[];
  /**
   * Mainstructures outype combination of the document.
   */
  mainstructuresOuTypeCombinations?: string[];
  /**
   * Education sorts of the document.
   */
  educationsorts?: string[];
  /**
   * Organisational unit types (such as SCHOOL, GOVERNING_INSTITUTION) for whom this content is intended.
   */
  outypes?: string[];
  /**
   * Date when the node/document expires, and is no longer valid. The node/document is still published for historic reasons.
   */
  expiryDate?: string;
  /**
   * Date until when the node will be labeled as 'nieuw'.
   */
  newUntilDate?: string;
  /**
   * Importance of the document.
   */
  importance: 'VERY_LOW' | 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH';
  /**
   * Version of this document
   */
  version?: {
    /**
     * MAJOR version when you make incompatible changes.
     */
    major: number;
    /**
     * MINOR version when you add functionality in a backwards compatible manner.
     */
    minor: number;
    /**
     * PATCH version when you make backwards compatible bug fixes.
     */
    patch: number;
  };
  $$version?: string;
  /**
   * The time needed (in months) to realise/complete the llinkid curriculum
   */
  realisationPeriod?: 12 | 24;
  /**
   * Language of the document.
   */
  language?: string;
  /**
   * subjects of the document.
   */
  subjects?: string[];
  /**
   * List of themes of the reference frame this document refers to.
   */
  themes?: ContentHref[];
  /**
   * Comment (pincette meta-data).
   */
  comment?: string;
  /**
   * Valid date (pincette meta-data).
   */
  valid?: string;
  /**
   * Date (pincette meta-data).
   */
  documentdate?: string;
  /**
   * Last modification date (pincette meta-data)
   */
  lastmodificationdate?: string;
  /**
   * Jaargang (pincette meta-data).
   */
  jaargang?: string;
  /**
   * Nummer (pincette meta-data).
   */
  nummer?: string;
  /**
   * Paginas (pincette meta-data).
   */
  paginas?: string;
  /**
   * Periode (pincette meta-data).
   */
  periode?: string;
  /**
   * Modification (Wijziging) (pincette meta-data).
   */
  modification?: string;
  /**
   * Publisher (pincette meta-data).
   */
  publisher?: string;
  /**
   * Keywords (pincette meta-data).
   */
  keywords?: string[];
  /**
   * Projects (pincette meta-data).
   */
  projects?: string[];
  /**
   * Magazine (Tijdschrift) (pincette meta-data).
   */
  magazine?: string;
  startage?: number;
  endage?: number;
  /**
   * Color selection for this document.
   */
  color?: string;
  /**
   * Hash of the document.
   */
  '$$meta.hash'?: string;
  /**
   * Indicates if this content is applicable for newcomers that are non-native speakers. Used for Zill timelines.
   */
  specificForAN?: boolean;
  /**
   * indicating that the (LLINKID) curriculum is foundational.
   */
  foundational?: boolean;
  /**
   * indicating that the (LLINKID) curriculum is OKAN.
   */
  okan?: boolean;
  /**
   * Indicates that a content item expresses something about attitude.
   */
  attitudinal?: boolean;
  /**
   * Applicability of this document related to studyprogrammes.
   */
  applicability?: {
    /**
     * List of study programmes.
     */
    studyProgrammes?: {
      href: StudyProgrammeHref;
    }[];
    /**
     * Attachments applied to this document.
     */
    studyProgrammeGroups?: {
      href: StudyProgrammeGroupHref;
    }[][];
  };
  /**
   * References to /positions to indicate for which functions/roles this content is intended.
   */
  positions?: string[];
  /**
   * A time period in which this item is current.
   */
  current?: {
    /**
     * Current start date
     */
    startDate?: string;
    /**
     * Current end date.
     */
    endDate?: string;
  };
  newsletterType?: {
    /**
     * Indicates the type of the current newsletter instance.
     */
    href: string;
  };
  newsletterSender?: { href: string };
  /**
   * Indicates if the newsletter template has a table of contents by default or if a newsletter includes a toc.
   */
  tableOfContents?: boolean;
  /**
   * References to /namedsets that select the exact people that can read this content.
   */
  accessRights?: string[];
  /**
   * References to /namedsets that select the exact people that can read the descendant content node below this node.
   */
  descendantsAccessRights?: string[];
  /**
   * Expresses the type of education (general education, specific education, complementary education) a LLINKID curriculum contains. An array of references to /sam/commons/secondaryeducationtypes.
   */
  secondaryEducationTypes?: string[];
  /**
   * describes what type of LLINKID_GOAL the node is. The mandatory flag is inferred from the type. REGULAR and EXTRA goals are mandatory, DEEPENING and CHOICE goals are not mandatory.
   */
  llinkidGoalType?: LlinkidGoalType;
} & ContentMutations;

export type SriClientMeta = {
  count?: number;
  next?: string;
};

export type SriContentList = Content[] & SriClientMeta;

export type InternalReferenceHref =
  | ContentHref
  /**
   * href to a training module.
   * used ONLY in REFERENCES relations in TEASERS only
   */
  | TrainingModuleHref
  /**
   * href to an event.
   * used ONLY in REFERENCES relations in Calendars (REFERENCE_GROUP) of newsletters only
   */
  | EventHref
  /**
   * href to an educational activity type, used in SUGGESTED_EDUCATIONAL_ACTIVITY relations used in LLINKID_GOALS
   */
  | EducationalActivityTypeHref;

export type InvalidExternalUrl = string & { __brand: 'InvalidExternalUrl' };

type BaseRelation = {
  $$meta: {
    permalink: ContentRelationHref;
    type: 'RELATION'; // in the expanded response of content type is not filled in :(
    deleted?: boolean;
    created?: string;
    modified?: string;
  };
  key: ContentRelationKey;
};

export type IsPartOfRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'IS_PART_OF';
  readorder: number | undefined;
  // strength: 'MEDIUM'; // strength is always MEDIUM but it has no meaning...
  strength?: never;
};

export type IsIncludedInRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'IS_INCLUDED_IN';
  readorder: number | undefined;
  strength: 'MEDIUM' | undefined; // strength is sometimes filled in? @matthias can you explain why?
};

export type IsVersionOfRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'IS_VERSION_OF';
  readorder?: never;
  // strength: 'MEDIUM'; // strength is always MEDIUM but it has no meaning...
};

export type ReferencesRelation = BaseRelation & {
  from: { href: ContentHref };
  to: {
    href:
      | ContentHref
      /**
       * href to a training module.
       */
      | TrainingModuleHref
      /**
       * href to an event.
       * used ONLY in REFERENCES relations in Calendars (REFERENCE_GROUP) of newsletters only
       */
      | EventHref
      | ExternalUrl // url to a (valid) external website
      | InvalidExternalUrl; // in the pendingActions you can create a REFERENCE relation to an invalid external url
  };
  relationtype: 'REFERENCES';
  readorder?: never; // some have readorder 1 but i doubt it has any meaning. i assume it's in ZILL
  strength: 'LOW' | 'MEDIUM' | undefined;
};

export type ReplacesRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'REPLACES';
  readorder?: never;
  strength?: never; // strength is always MEDIUM but it has no meaning...
};

export type RequiresRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'REQUIRES';
  readorder?: never;
  strength: 'HIGH' | 'MEDIUM' | 'HIGH';
};

export type RelationRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'RELATION';
  readorder?: never;
  strength?: never; // strength is always MEDIUM but it has no meaning...
};

export type SuggestedInclusionRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'SUGGESTED_INCLUSION';
  readorder?: never;
  strength?: never; // strength is always MEDIUM but it has no meaning...
};

export type ImplementsRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: ContentHref };
  relationtype: 'IMPLEMENTS' | 'PARTIALLY_IMPLEMENTS';
  readorder?: never;
  strength?: never; // strength is always MEDIUM but it has no meaning...
};

export type SuggestedEducationalActivityRelation = BaseRelation & {
  from: { href: ContentHref };
  to: { href: EducationalActivityTypeHref };
  relationtype: 'SUGGESTED_EDUCATIONAL_ACTIVITY';
  readorder?: never;
  strength?: never; // strength is always MEDIUM but it has no meaning...
};

export type ContentRelation =
  | IsPartOfRelation
  | IsIncludedInRelation
  | IsVersionOfRelation
  | ReferencesRelation
  | ReplacesRelation
  | RequiresRelation
  | RelationRelation
  | SuggestedInclusionRelation
  | ImplementsRelation
  | SuggestedEducationalActivityRelation;

export type ContentRelationType = ContentRelation['relationtype'];

export const isContentHref = (href: string): href is ContentHref =>
  !!href.match(/^\/content\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);

export const isContentRelationHref = (href: string): href is ContentRelationHref =>
  !!href.match(
    /^\/content\/relations\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
  );

const isContentRelation = (resource: unknown): resource is ContentRelation =>
  typeof resource === 'object' && resource !== null && 'relationtype' in resource;

export const isRequiresRelation = (relation: ContentRelation): relation is RequiresRelation =>
  relation.relationtype === 'REQUIRES';

export const isIncludedInRelation = (resource: unknown): resource is IsIncludedInRelation =>
  isContentRelation(resource) && resource.relationtype === 'IS_INCLUDED_IN';

export const isReferencesRelation = (relation: ContentRelation): relation is ReferencesRelation => {
  return relation.relationtype === 'REFERENCES';
};

export type ContentWith$$Relations = Content & {
  $$relationsFrom:
    | {
        href: ContentRelationHref;
        $$expanded: ContentRelation;
      }[];
  $$relationsTo:
    | {
        href: ContentRelationHref;
        $$expanded: ContentRelation;
      }[];
};

export type $$pathToRoot = string[];

export type WebSite = {
  $$meta: {
    permalink: WebSiteHref;
    type: 'WEBSITE';
  };
  domain: string;
};

export type WebTemplate = {
  $$meta: {
    permalink: WebTemplateHref;
    type: 'WEB_TEMPLATE';
  };
  code: WebConfigType;
  name: string;
  allowedTemplatesForChildNode?: string[];
  allowedContent?: { type: ContentType; tag?: ContentTag }[];
  website: { href: WebSiteHref };
  options?: string[];
};

export type WebPage = {
  $$meta: {
    permalink: WebPageHref;
    type: 'WEBPAGE';
    deleted?: boolean;
  };
  key: WebPageKey;
  website: { href: WebSiteHref };
  type: WebConfigType;
  path: string;
  /**
   * array of old paths.
   */
  oldLocations?: {
    /**
     * old path for this web page.
     */
    path: string;
    website: {
      /**
       * reference to the website where the old path was used.
       */
      href: WebSiteHref;
    };
  }[];
  externalReferences: ContentHref[]; // i assume this is correct
  source: { href: ContentHref };
  template: { href: WebTemplateHref };
  /**
   * object that contains specific parameter for the selected template.
   */
  options?: {
    /**
     * array of facets.
     */
    facets?: {
      /**
       * the display label on the screen for this facet.
       */
      label?: string;
      /**
       * component enum describing the input component to use.
       */
      component?:
        | 'FULL_TEXT_SEARCH'
        | 'AGE_RANGE_SLIDER'
        | 'SELECT_FROM_REFERENCE_FRAME'
        | 'ZILL_GOAL_SELECTOR';
      /**
       * Path to indicate what meta-information this facet filters on.
       */
      path?: string;
      source?: {
        /**
         * a REFERENCE_FRAME that is used
         */
        href: string;
      };
    }[];
    /**
     * Configuration to display a highlight in Pro.
     */
    banner?: {
      /**
       * as of when should the banner appear?
       */
      from?: string;
      /**
       * when should the banner disappear?
       */
      to?: string;
      /**
       * link to the target of the call to action.
       */
      url?: string;
      /**
       * title of the call to action.
       */
      title?: string;
      /**
       * description of the call to action.
       */
      subtitle?: string;
      /**
       * the call-to-action text to show in the banner.
       */
      cta?: string;
      /**
       * is the CTA active?
       */
      highlight?: boolean;
    };
    /**
     * makes the template render multiple alternatives for FALLBACK_MENU_LEVEL_2 as tabs. (example: Didactiek & Leerplannen has versions for Bao / So / …)
     */
    multipleAlternativeMenus?: boolean;
  };
};

export type Common = {
  href: string;
  title: string;
  resourceType: CommonResourceType;
};

type BaseChange = {
  creator: { href: PersonHref };
  appliesTo: { href: ContentHref | ContentRelationHref | WebPageHref | ContentAttachmentHref };
  relatedTo: { href: ContentHref };
};

export type RequestedCreateChange = BaseChange & {
  type: 'CREATE';
  resource: any; // Content | ContentRelation; not possible because it is not true: Old reducer still makes the requested changes and inserts heavy mutated objects in resource
};
export type RequestedPatchChange = BaseChange & {
  relatedTo: { href: ContentHref } | undefined;
  type: 'PATCH';
  patch: {
    op: 'replace' | 'add' | 'remove';
    path: string;
    value: string | Record<string, any> | Record<string, any>[];
  }[];
};

export type RequestedUploadChange = BaseChange & {
  type: 'UPLOAD';
  attachment: { key: string; href: string; name: string } & AttachmentMetaInfo;
  metadata: {
    key: ContentAttachmentKey;
    name: string;
  } & AttachmentMetaInfo;
};

export type RequestedDeleteUploadChange = BaseChange & {
  appliesTo: { href: ContentAttachmentHref };
  type: 'DELETE_UPLOAD';
};

export type RequestedDeleteChange = BaseChange & {
  type: 'DELETE';
};

export type RequestedChange =
  | RequestedCreateChange
  | RequestedPatchChange
  | RequestedDeleteChange
  | RequestedUploadChange
  | RequestedDeleteUploadChange;

export const isCreateChange = (change: RequestedChange): change is RequestedCreateChange => {
  return change.type === 'CREATE';
};

export const isPatchChange = (change: RequestedChange): change is RequestedPatchChange => {
  return change.type === 'PATCH';
};

export const isUploadChange = (change: RequestedChange): change is RequestedUploadChange => {
  return change.type === 'UPLOAD';
};

export const isDeleteUploadChange = (
  change: RequestedChange
): change is RequestedDeleteUploadChange => {
  return change.type === 'DELETE_UPLOAD';
};

export type ProposalStatus = 'IN_PROGRESS' | 'SUBMITTED_FOR_REVIEW';
export type Proposal = {
  $$meta: {
    permalink: ProposalHref;
    modified: string;
    created: string;
    type: 'CONTENT_PROPOSAL';
  };
  key: ProposalKey;
  creators: PersonHref[];
  expandedCreators: any; // TODO needs to be removed
  reviewer: { href: string } | undefined;
  status: ProposalStatus;
  externalReferences: ContentHref[]; // i assume this is correct
  listOfRequestedChanges: RequestedChange[];
};

export const isContent = (r: IApiResouce | undefined): r is Content =>
  !!r && r.$$meta.type === 'CONTENT';

export type NamedSet = {
  $$meta: {
    modified: string;
    created: string;
    permalink: NamedSetHref;
    type: 'NAMED_SET';
  };
  /**
   * GUID
   */
  key: NamedSetKey;
  name: string;
  description: string;
  selectors?: Array<{
    type: string;
    value: Array<{
      ouType: string;
      mainstructure: string;
    }>;
  }>;
  tags: Array<string>;
  type?: string;
};

export type Version = {
  $$meta: {
    modified: string;
    created: string;
    permalink: VersionHref;
    type: 'SRI_RESOURCE_VERSION';
  };
  key: VersionKey;
  timestamp: string;
  person: PersonHref;
  component: string;
  operation: string;
  type: 'CONTENT';
  resource: ContentHref;
  mergedResource: null;
  document: Omit<Content, '$$meta'>;
};
