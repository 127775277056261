import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector, stripHtml } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

const getLinkTitle = (relations, externalContentRelations, externalContent, content, href) => {
  const relation = relations.from[href]?.find(
    (rel) => rel.relationtype === 'REFERENCES' && !rel.deleteProposal
  );
  if (!relation) {
    return null;
  }
  const foundContent = content[relation.to.href] || externalContent[relation.to.href];
  const pathToRoot = getPathToRoot(externalContentRelations, externalContent, relation.to.href);

  return {
    key: relation?.key,
    externalHref: relation.to.href.match(/^\/content\//) ? null : relation?.to.href,
    $$expanded: {
      ...foundContent,
      $$pathToRoot: pathToRoot.map((c) => stripHtml(c?.title)),
    },
  };
};

const getFullLinkTitle = (relations, externalContentRelations, externalContent, content, href) => {
  const data = getLinkTitle(relations, externalContentRelations, externalContent, content, href);
  if (!data) {
    return null;
  }
  if (data.externalHref) {
    return data.externalHref;
  }
  return `${data.$$expanded.title} - ${data.$$expanded.$$pathToRoot.slice(1).join(' > ')}`;
};

export const selectReferenceRelation = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state, href) => href,
  ],
  (relations, externalContent, externalContentRelations, content, href) =>
    getLinkTitle(relations, externalContentRelations, externalContent, content, href)
);

export const selectChangeMessageForMenuItemReference: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectExternalContent(state),
      (state) => selectExternalContentRelationsMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href) => href,
    ],
    (
      relations,
      rawRelations,
      externalContent,
      externalContentRelations,
      content,
      href
    ): string | null => {
      const from = getFullLinkTitle(
        rawRelations,
        externalContentRelations,
        externalContent,
        content,
        href
      );
      const to = getFullLinkTitle(
        relations,
        externalContentRelations,
        externalContent,
        content,
        href
      );
      return stringDiffArrowFunction(to, from);
    }
  );
