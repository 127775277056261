import { AttachmentsGroupNode } from '@newStore/documentUI/documentUITypes';
import React from 'react';

const AttachmentsGroupRow: React.FC<{ contentNode: AttachmentsGroupNode }> = ({ contentNode }) => {
  return (
    <div className="node-title" style={{ color: '#a0a0a0', cursor: 'default' }}>
      [{contentNode.title}] <span className="sub-title">{contentNode.subTitle}</span>
    </div>
  );
};

export default AttachmentsGroupRow;
