import { Content, ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  DropDownOptionsType,
  CurriculumTypeKeys,
  EditAsideCurriculumType,
  EditComponent,
} from '@nodeTypeConfig/configTypes';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';
import { get } from 'lodash';

const getSelectedOptionForContent = (
  content: Content | undefined,
  options: Array<DropDownOptionsType>
): DropDownOptionsType => {
  const isOkan = Boolean(get(content, 'okan', false));
  const isFoundational = Boolean(get(content, 'foundational', false));

  // eslint-disable-next-line no-nested-ternary
  const type = isOkan
    ? CurriculumTypeKeys.OKAN
    : isFoundational
    ? CurriculumTypeKeys.FOUNDATIONAL
    : CurriculumTypeKeys.REGULAR;

  return options.find((ct: DropDownOptionsType) => ct.key === type) || options[0];
};

export const selectCurrentCurriculumType = createTypedSelector(
  [
    (state: RootState) => selectCurrentEditingNode(state),
    (state: RootState, config: EditAsideCurriculumType) => config.options,
  ],
  (currentEditNode, curriculumTypes): DropDownOptionsType => {
    return getSelectedOptionForContent(currentEditNode, curriculumTypes);
  }
);

export const selectChangeMessageForCurriculumType: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      const newValue = getSelectedOptionForContent(content, get(config, 'options'))?.label;
      const oldValue = getSelectedOptionForContent(originalContent, get(config, 'options'))?.label;

      return stringDiffArrowFunction(newValue, oldValue);
    }
  );
