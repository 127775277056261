import React, { useState } from 'react';

import { KovResourcePicker, Modal } from '@kathondvla/react-shared-components/src/components';

import { Content, ContentHref } from '@generalTypes/apiTypes';
import { createAttachmentsGroup } from '@newStore/documentUI/documentUISagas';
import { closeAttachmentGroupModal } from '@newStore/documentUI/documentUIState';
import ATTACHMENTS_GROUP from '@nodeTypeConfig/BUILDING_BLOCKS/ATTACHMENTS_GROUP';
import DOWNLOAD from '@nodeTypeConfig/BUILDING_BLOCKS/DOWNLOAD';
import {
  DropDownOptionsType,
  EditAsideAttachmentGroupType,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-webpack-loader-syntax
import GlobalDocIcon from '!!raw-loader!../../../../img/buildingBlocks/GLOBAL_DOCUMENT.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import AttachmentIcon from '!!raw-loader!../../../../img/buildingBlocks/ATTACHMENT.svg';
import { selectPathToRootHrefs } from '@newStore/documentApi/documentApiSelectors';
import { ancestorTypeFilter } from '@newStore/documentUI/nodeTypeConfigHelpers';
import { selectAllNodeTypesMap } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { GlobalDocumentDropdown } from './GlobalDocumentDropdown';
import './modals.scss';

const attachmentOptions = [
  { key: 'ATTACHMENT', label: 'Bijlage', icon: AttachmentIcon },
  { key: 'GLOBAL_DOCUMENT', label: 'Gedeeld document', icon: GlobalDocIcon },
];

const AttachmentGroupCreateModal: React.FC = () => {
  const nodeType = useTypedSelector(
    (state) => state.documentUI.attachmentGroupModalData.data?.nodeType
  );
  // ideally we just check if the edit of selectAppliedNodeConfig has the attachmentGroupType component or not.
  // however, we are creating this attachments group, so it doesn't exist yet.
  // instead we get the config from the constants  (unfiltered/unapplied) and filter the edit here using the same data.
  const pathToRootHrefs = useTypedSelector((state) =>
    selectPathToRootHrefs(
      state,
      state.documentUI.attachmentGroupModalData.data?.parentHref as ContentHref
    )
  );
  const allNodeTypesMap = useTypedSelector((state) => selectAllNodeTypesMap(state));

  const nodeTypeConfig = nodeType === NodeType.ATTACHMENTS_GROUP ? ATTACHMENTS_GROUP : DOWNLOAD;
  const title = nodeTypeConfig.information.single;
  const config: EditAsideAttachmentGroupType = nodeTypeConfig.edit
    .filter((c) => ancestorTypeFilter(c, pathToRootHrefs, allNodeTypesMap))
    .find((e) => e.component === 'attachmentGroupType') as EditAsideAttachmentGroupType;
  const [importance, setImportance] = useState<Content['importance']>('MEDIUM');
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<
    NodeType.ATTACHMENT | NodeType.GLOBAL_DOCUMENT | null
  >(null);
  const [selectedGlobalDocument, setSelectedGlobalDocument] = useState<Content | null>(null);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (!selectedAttachmentType || !nodeType) {
      return;
    }
    dispatch(
      createAttachmentsGroup({
        importance,
        createChildType: selectedAttachmentType,
        selectedGlobalDocument,
        nodeType,
      })
    );
  };

  const onClose = () => {
    dispatch(closeAttachmentGroupModal());
  };

  const onChange = (value: DropDownOptionsType) => {
    setImportance(value.key as Content['importance']);
  };

  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAttachmentType(event.target.value as any);
  };

  return (
    <div className="overflown-modal">
      <Modal.Header title={`Aanmaken ${title.toLowerCase()}`} onClose={onClose} />
      <Modal.Body>
        {config && (
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <div style={{ maxWidth: '400px' }}>
                  <label className="control-label">{config?.label}</label>

                  <KovResourcePicker
                    value={config.options.find((o) => o.key === importance)}
                    localData={config.options}
                    disabled={false}
                    clearable={false}
                    onChange={onChange}
                    menuPlacement="bottom"
                    disableSearch={true}
                    optionValue={(o: DropDownOptionsType) => o.key}
                    optionTemplate={(o: DropDownOptionsType) => o.label}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <br />
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <div>
                <label className="control-label">Welk type bijlage wil je toevoegen?</label>
                {attachmentOptions.map((option) => (
                  <div key={option.key} className="radio-container">
                    <input
                      className="form-control"
                      type="radio"
                      id={option.key}
                      value={option.key}
                      checked={selectedAttachmentType === option.key}
                      onChange={handleAttachmentChange}
                    />
                    <label className="radiolabel" htmlFor={option.key}>
                      <div
                        className="svg-crop"
                        dangerouslySetInnerHTML={{ __html: option.icon }}
                      ></div>
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {selectedAttachmentType === 'GLOBAL_DOCUMENT' && (
          <GlobalDocumentDropdown
            onChange={setSelectedGlobalDocument}
            selectedGlobalDocument={selectedGlobalDocument}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-link pull-left" onClick={onClose}>
          Annuleren
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Ok
        </button>
      </Modal.Footer>
    </div>
  );
};

AttachmentGroupCreateModal.displayName = 'AttachmentGroupCreateModal';

export default AttachmentGroupCreateModal;
