import { Content, ContentHref } from '@generalTypes/apiTypes';
import { selectPathToRoot } from '@newStore/documentApi/documentApiSelectors';
import {
  selectAppliedNodeConfig,
  selectNodeType,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector, stripHtml } from '@newStore/genericHelpers';
import {
  AudienceTabComponent,
  NodeType,
  RequiredType,
  isHiddenNodeConfig,
} from '@nodeTypeConfig/configTypes';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { isMoreStrict } from '@store/helpers/accessRights';
import { get, pick } from 'lodash';
import { createError } from '../createError';
import { ValidationError } from '../validationTypes';

const emptyArray = [];
/**
 * The audienceTab components are inheritable, so the root can not have no value, children can only not have an empty array.
 * Because if a child node does not have a value it means: take the value from your parent (which might have got it from his parent)
 */
const getAudienceTabComponentsRequiredErrors = (
  audienceTabComponents: AudienceTabComponent[],
  pathToRoot: Content[]
) => {
  const parent = pathToRoot[1];
  const errors: ValidationError[] = [];
  const contentItem = pathToRoot[0];
  audienceTabComponents.forEach((comp) => {
    const property = comp.property || comp.component;
    const value = get(contentItem, property);
    // the root must have a value, any node can not have an empty array
    if ((!value && !parent) || (value && !value.length)) {
      errors.push(
        createError(
          `${property}Required`,
          'getAudienceTabComponentsRequiredErrors',
          `Selecteer minstens één <strong>${comp.labelSingular.toLowerCase()}</strong>.`,
          pick(comp, ['component', 'property'])
        )
      );
    }
  });
  return errors;
};

/**
 * A child node can not have a value that has a "bigger" audience than it parents.
 * for example if a parent is only for directors, the child can not say: I'm for directors AND TEACHERS
 */
const getAudienceTabComponentsInconsistentErrors = (
  audienceTabComponents: AudienceTabComponent[],
  pathToRoot: Content[]
) => {
  const parent = pathToRoot[1];
  if (!parent) {
    return emptyArray;
  }
  const errors: ValidationError[] = [];
  const contentItem = pathToRoot[0];
  audienceTabComponents
    .filter((comp) => comp.component !== 'accessRights') // checked seperately because works differently
    .forEach((comp) => {
      const property = comp.property || comp.component;
      const value = contentItem[property];
      const pathToRootFromParent = pathToRoot.slice(1);
      if (value) {
        const ancestorThatDefinesValue = pathToRootFromParent.find((ancestor) =>
          get(ancestor, property)
        );
        const ancestorValue = get(ancestorThatDefinesValue, property);
        if (ancestorValue && value.some((elem) => !ancestorValue.includes(elem))) {
          errors.push(
            createError(
              `${property}Inconsistent`,
              'getAudienceTabComponentsInconsistentErrors',
              `Je mag de <strong>${comp.label.toLowerCase()}</strong> enkel beperken t.o.v. de ${comp.label.toLowerCase()} van ${
                ancestorThatDefinesValue?.title
                  ? `"${stripHtml(ancestorThatDefinesValue?.title)}"`
                  : 'het item hierboven'
              }.`,
              pick(comp, ['component', 'property']),
              RequiredType.WARNING
            )
          );
        }
      }
    });
  return errors;
};

/**
 * A Child node can not define broader accessRights than it parent, you can only go stricter
 * For example if a parent is onlyForDirectors then the child can not be public.
 */
const getAccessRightsInconsistentErrors = (nodeType: NodeType, pathToRoot: Content[]) => {
  const contentItem = pathToRoot[0];
  const nodeTypeConfig = getNodeTypeConfig(nodeType);
  if (
    !isHiddenNodeConfig(nodeTypeConfig) &&
    nodeTypeConfig.addAccessRightsToAudienceTab &&
    pathToRoot[1]
  ) {
    if (contentItem.descendantsAccessRights) {
      const pathToRootFromParent = pathToRoot.slice(1);
      const ancestorThatDefinesValue = pathToRootFromParent.find(
        (ancestor) => ancestor.descendantsAccessRights
      );
      const parentValue = ancestorThatDefinesValue?.descendantsAccessRights;
      if (
        parentValue &&
        contentItem.descendantsAccessRights[0] !== parentValue[0] &&
        !isMoreStrict(contentItem.descendantsAccessRights[0], parentValue[0])
      ) {
        return [
          createError(
            'accessRightsInconsistent',
            'getAccessRightsInconsistentErrors',
            `Je mag de afscherming enkel beperken t.o.v. de afscherming van ${
              stripHtml(ancestorThatDefinesValue?.title) || 'het item hierboven'
            }.`,
            { component: 'accessRights', property: 'descendantsAccessRights' }
          ),
        ];
      }
    }
  }
  return emptyArray;
};

/**
 * Validates the components you have configured in the audience tab.
 */
export const selectAudienceValidationErrors = createTypedSelector(
  [
    (state, href: ContentHref) => selectPathToRoot(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
  ],
  (pathToRoot, nodeType, nodeTypeConfig): Array<ValidationError> => {
    if (!nodeTypeConfig || !nodeTypeConfig.audienceTab) {
      return emptyArray;
    }
    return [
      ...getAudienceTabComponentsRequiredErrors(nodeTypeConfig.audienceTab, pathToRoot),
      ...getAudienceTabComponentsInconsistentErrors(nodeTypeConfig.audienceTab, pathToRoot),
      ...getAccessRightsInconsistentErrors(nodeType, pathToRoot),
    ];
  }
);
