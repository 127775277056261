import { ContentHref } from '@generalTypes/apiTypes';
import { ValidationErrorWithNode } from '@newStore/validation/validationTypes';
import { useDispatch } from 'react-redux';

import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { updateAsideViewModelAction } from '@store/actions/documentActions';
import './ValidationErrors.scss';

import { getResourceKey } from '@store/helpers/documentHelpers';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { useScrollToNode } from '../scrollToNode/useScrollToNode';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const ValidationErrors: React.FC<{
  href: ContentHref;
  validationErrors: Array<ValidationErrorWithNode> | undefined;
  defaultMinimized: boolean;
}> = ({ href, validationErrors, defaultMinimized }) => {
  const dispatch = useDispatch();
  const scrollToNode = useScrollToNode(true);
  const nodeTypeConfig = useTypedSelector((state) => selectAppliedNodeConfig(state, href));

  if (!validationErrors || !(validationErrors?.length > 0)) {
    return null;
  }

  const $state = getAngularService('$state');

  const hasErrorType = (validations: Array<ValidationErrorWithNode>) => {
    return validations.some((v) => v.type === 'ERROR');
  };

  const scrollToAndOpenAside = (error: ValidationErrorWithNode) => {
    scrollToNode(error.node.href, error.node.parentHref);
    if (error.node.href === href) {
      const key = getResourceKey(href);
      if ('onEditIncludedNode' in nodeTypeConfig) {
        nodeTypeConfig.onEditIncludedNode(href);
      } else {
        $state.go('edit.aside', { editKey: key });
        dispatch(updateAsideViewModelAction(key));
      }
    }
  };

  return (
    <div className={`validation-message-container ${defaultMinimized ? 'minimized' : 'maximized'}`}>
      {validationErrors.length > 1 && (
        <span
          className={`show-more-icon ${hasErrorType(validationErrors) ? 'error' : 'warning'}`}
        >{`+${validationErrors.length - 1}`}</span>
      )}

      {validationErrors.map((error) => (
        <div
          key={error.code}
          className={`error-message type-${error.type.toLowerCase()}`}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            scrollToAndOpenAside(error);
          }}
        >
          <div className="error-message-content">
            <span dangerouslySetInnerHTML={{ __html: error.message }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ValidationErrors;
