import { ContentHref, ContentType, WebSiteHref, WebTemplateHref } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import {
  selectChangeMessageForLinkedContent,
  selectTeaserNode,
} from '@newStore/documentUI/transformContent/teaserSelectors';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { selectValidateTeaserAudienceParentIntersectionNotEmpty } from '@newStore/validation/validationRules/audienceIntersectionRules';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { selectCallToActionTitleValidation } from '@newStore/validation/validationRules/teaserCallToActionValidationRules';
import { selectValidateTeaserMatchesSection } from '@newStore/validation/validationRules/teaserMatchesSection';
import {
  validateWebConfigurationRequired,
  validateWebTemplateConfig,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { BuildingBlockNodeConfig, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { teasersDeleteValidationCmd } from '@store/commands/documentListCommands';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
// import { teasersDeleteValidationCmd } from '../../../reduxLoop/commands/documentListCommands';
import { selectIsValidReferenceFromTeaser } from '@newStore/validation/validationRules/externalLinkValidationRules';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { imageComponent } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import { proNewsletterAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proNewsletterAudienceTab';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { loadNamedSetsAction } from '@store/actions/documentActions';
import { websites } from '@store/api/apiRoutes';
import { selectChangeMessageForTeaserImportance } from '@UI/aside/asideTeaserImportance/asideTeaserImportanceLabel/asideTeaserImportanceSelectors';
import constants from '../../../../reduxLoop/constants/constants';
import { settings } from '../../../settings';

export const teaserImportanceTranslations = {
  LOW: 'Niet opnemen in nieuwsbrief',
  MEDIUM: 'Gewone teaser',
  HIGH: 'Prioritaire teaser',
};

const TEASER: RootNodeConfig & BuildingBlockNodeConfig = {
  nodeSelector: selectTeaserNode,
  deleteFromListValidations: [teasersDeleteValidationCmd],
  validationRules: [
    validateWebConfigurationRequired,
    validateWebTemplateConfig,
    selectValidateTeaserMatchesSection,
    selectCurriculumThemesValidation,
    selectValidateTeaserAudienceParentIntersectionNotEmpty,
  ],
  information: {
    definiteArticle: false,
    single: 'PRO.-teaser',
    plural: 'PRO.-teasers',
    ribonIcon: require('../../../../../img/buildingBlocks/TEASER.svg'),
    category: 'PRO',
  },
  webconfiguration: {
    type: 'NEWS_ITEM_AND_TEASER_FALLBACK',
    website: {
      href: `${websites}/${settings.websites.pro}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/d153f5d5-78b3-4de7-a231-281da633a8bb' as WebTemplateHref,
    },
  },
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
    /**
     * the ACTION_TYPES.PATCH_THEMES action still needs the doelgroepen namedset to be present
     * in the old reducer.
     * Once the PATCH_xxx actions are removed, this action can be removed as well.
     */
    loadNamedSetsAction('doelgroepen'),
  ],
  createModal: {
    authors: {
      addUser: false,
    },
  },
  tocTypes: [],
  buildingBlocks: [],
  edit: [
    {
      ...titleComponent,
      options: {
        hideTermButton: true,
      },
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Teaser',
      required: RequiredType.ERROR,
      maxLength: 400,
      options: {
        hideTermButton: true,
      },
    },
    authorsComponent,
    publishComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
    },
    {
      property: 'importance',
      label: 'Belangrijkheid',
      component: 'teaserImportance',
      reactComponent: 'AsideTeaserImportance',
      valueToString: (c) => teaserImportanceTranslations[c.importance],
      asideChangeMessageSelector: selectChangeMessageForTeaserImportance,
    },
    {
      ...imageComponent,
      options: {
        type: 'THUMBNAIL',
        crop: {
          aspectRatio: 4 / 3,
          resize: {
            width: 710,
            height: 533,
            quality: 0.6,
          },
        },
      },
    },
    {
      component: 'teaserLinkedContent',
      reactComponent: 'AsideTeaserLinkedContent',
      asideChangeMessageSelector: selectChangeMessageForLinkedContent,
      label: 'Linkt naar',
      validations: [selectIsValidReferenceFromTeaser, selectCallToActionTitleValidation],
      hiddenChildren: {
        containerType: ContentType.TEASER,
        items: [
          {
            type: ContentType.REFERENCE,
            label: 'link',
          },
        ],
      },
    },
  ],
  audienceTab: proNewsletterAudienceTab,
  onNewNodeDropped: {
    openAside: false,
  },
  isCreatable: true,
  isSearchable: true,
  isCollapsible: false,
  isImportable: false,
  allowSuggestions: true,
  // customRender: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  confirmDelete: true,
  // customConfirmDeleteMessage: () => '<li>Ben je zeker dat je dit wil verwijderen?</li>',
  hideChildrenInDocument: true,
  customEditorOptions: {
    showMarkerButton: false,
    hideTermButton: true,
  },
};

export default TEASER;
