import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import {
  DropDownOptionsType,
  CurriculumTypeKeys,
  EditAsideCurriculumType,
} from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { set } from 'lodash';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectCurrentCurriculumType } from './asideCurriculumTypeSelectors';
import { useEditHref } from '../../../hooks/UseEditHref';
import AsideValidation from '../asideValidationErrors/AsideValidation';

import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { useReadOnly } from '../../../hooks/UseReadonly';

const AsideCurriculumType: React.FC<{
  config: EditAsideCurriculumType;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const readOnly = useReadOnly();

  const selectedOption: DropDownOptionsType = useSelector((state: RootState) =>
    selectCurrentCurriculumType(state, config)
  );

  const onChange = (value: DropDownOptionsType) => {
    const okan = value.key === CurriculumTypeKeys.OKAN;
    const foundational = value.key === CurriculumTypeKeys.FOUNDATIONAL;

    const payload = {};
    set(payload, 'foundational', foundational);
    set(payload, 'okan', okan);

    dispatch(patchNodeAction(getResourceKey(editHref), payload));
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <div style={{ maxWidth: '400px' }}>
        <label className="control-label">{config.label}</label>

        <KovResourcePicker
          value={selectedOption}
          localData={config.options}
          disabled={readOnly}
          clearable={false}
          onChange={onChange}
          menuPlacement="bottom"
          disableSearch={true}
          optionValue={(o: DropDownOptionsType) => o.key}
          optionTemplate={(o: DropDownOptionsType) => o.label}
        />
      </div>
    </AsideValidation>
  );
};

export default AsideCurriculumType;

AsideCurriculumType.displayName = 'AsideCurriculumType';

AsideCurriculumType.propTypes = {
  config: any,
};
