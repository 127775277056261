import { AttachmentImageMetaInfo } from '@generalTypes/apiTypes';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import { getAttachmentUrl } from '@newStore/genericHelpers';
import { ImageType } from '@nodeTypeConfig/configTypes';
import ModalErrorBoundary from '@UI/modals/ModalErrorBoundary';
import React, { useEffect, useRef, useState } from 'react';
import ImageCropSelector from './ImageCropSelector';
import FileSelector from './FileSelector';
import './ImageComponent.scss';

interface ImageComponentProps {
  image: AttachmentImageMetaInfo | null;
  label: string;
  crop?: { aspectRatio?: number } | undefined;
  disabled: boolean;
  isRemovable?: boolean;
  onImageChanged: ({ file, invalid }: { file: File; invalid?: boolean }) => void;
  onImageRemoved?: () => Promise<boolean> | boolean;
  onImageCropped?: (data: {
    file: File;
    base64: string;
    type: ImageType;
    width: number;
    height: number;
  }) => void;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  image,
  label,
  crop,
  disabled,
  isRemovable = true,
  onImageChanged,
  onImageRemoved,
  onImageCropped,
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const imageCropModalRef = useRef<Modal>();

  // Load image when on 'Edit' mode
  useEffect(() => {
    if (image) {
      setImageSrc(getAttachmentUrl(image));
    } else {
      setImageSrc(null);
    }
  }, [image]);

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }
    if (/\.(jpe?g|png)$/i.test(file.name)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setImageSrc(reader.result);
        } else {
          console.error('FileReader result is not a string');
          setImageSrc(null);
        }
      };
      reader.onerror = (error) => {
        console.error('Error: ', error);
      };
      onImageChanged({ file });
    } else {
      onImageChanged({ file, invalid: true });
    }
  };

  const removeImage = async () => {
    const remove = await onImageRemoved?.();
    if (remove) {
      setImageSrc(null);
    }
  };

  const onOpenCropModal = (): void => {
    imageCropModalRef.current?.open({
      modalTitle: `${label} toevoegen`,
      ratio: crop?.aspectRatio,
      onSubmit: async (prop: {
        file: File;
        base64: string;
        type: ImageType;
        width: number;
        height: number;
      }): Promise<void> => {
        const { base64 } = prop;
        setImageSrc(base64);
        onImageCropped?.(prop);
      },
    });
  };

  return (
    <div className="image-component col-md-12" style={{ marginBottom: '1rem' }}>
      {imageSrc && (
        <div className="image">
          <img src={imageSrc} />
          {!disabled && isRemovable && (
            <button className="btn btn-danger delete-img" onClick={removeImage}>
              <span className="glyphicon glyphicon-remove"></span>
            </button>
          )}
        </div>
      )}
      {!imageSrc && (
        <div>
          {!crop && <FileSelector onFileSelected={uploadFile} label={label} />}
          {crop && (
            <>
              <button className="btn btn-default pull-left" onClick={onOpenCropModal}>
                <span className="glyphicon glyphicon-add"></span> {label} toevoegen
              </button>
              <Modal
                ref={imageCropModalRef}
                size="sm"
                component={<ImageCropSelector />}
                errorBoundaryComponent={ModalErrorBoundary}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageComponent;
