import { ContentHref, ContentType, NamedSetHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideAccessRights,
  EditComponent,
} from '@nodeTypeConfig/configTypes';
import { AccessRight, accessRights as AccessRightsMap } from '@store/helpers/accessRights';
import {
  selectCurrentEditingNode,
  selectCurrentOrInheritedPropertyValue,
  selectInheritedPropertyValue,
} from '@store/selectors/asideSelectors';
import { get } from 'lodash';

const getAccessRightForHref = ([href]: [NamedSetHref]) => {
  return Object.values(AccessRightsMap).find(
    (right: AccessRight) => href === right.namedSetHref
  ) as AccessRight;
};

export const selectCurrentSelectedAccessRightsOption = createTypedSelector(
  [
    (state, href: ContentHref, config: EditComponent) =>
      selectCurrentOrInheritedPropertyValue(state, href, config),
  ],
  (currentSelectedValue) => {
    return getAccessRightForHref(currentSelectedValue);
  }
);

export const selectIsGlobalDocument = createTypedSelector(
  [(state) => selectCurrentEditingNode(state)],
  (currentValue) => {
    return currentValue.type === ContentType.UNSTRUCTURED_DOCUMENT;
  }
);

export const selectChangeMessageForAccessRights: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) =>
        selectInheritedPropertyValue(state, href, config),
      (state, href: ContentHref, config: EditComponent) => config as EditAsideAccessRights,
    ],
    (content, originalContent, inheritedValues, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newValue = (get(content, config.property) ?? inheritedValues) as [NamedSetHref];
      const oldValue = (get(originalContent, config.property) ?? inheritedValues) as [NamedSetHref];

      const newValueLabel = getAccessRightForHref(newValue)?.label;
      const oldValueLabel = getAccessRightForHref(oldValue)?.label;

      return stringDiffArrowFunction(newValueLabel, oldValueLabel);
    }
  );
