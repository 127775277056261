import { ContentType } from '@generalTypes/apiTypes';
import { EditAsideLinks, RootEditComponent } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForLinks } from '@UI/aside/asideLinks/asideLinksSelectors';
import { menuItemReference } from './menuItemReference';

export const proLinks: EditAsideLinks = {
  component: 'linkGroup',
  reactComponent: 'AsideLinks',
  asideChangeMessageSelector: selectChangeMessageForLinks,
  label: 'Links',
  width: '12',
  options: {
    type: 'group',
  },
  hiddenChildren: {
    containerType: ContentType.LINK_GROUP,
    items: [
      {
        type: ContentType.REFERENCE,
        label: 'link',
      },
    ],
  },
};

export const proLinksAndMenuReferences: Array<RootEditComponent> = [menuItemReference, proLinks];
