import { selectAttachmentsGroupPlaceholderNode } from '@newStore/documentUI/transformContent/attachmentSelectors';
import { selectValidateChildrenAreRequired } from '@newStore/validation/validationRules/childrenAreRequired';
import { validateGlobalDocumentAllowedInAttachmentsGroup } from '@newStore/validation/validationRules/globalDocumentSecurityConsistency';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { attachmentGroupTypeComponent } from '@nodeTypeConfig/helpers/editComponents/attachmentGroupTypeComponent';
import { proWebFacetAncestorTypes } from '@nodeTypeConfig/helpers/editComponents/proWebFacetAncestorTypes';

const ATTACHMENTS_GROUP: BuildingBlockNodeConfig = {
  nodeSelector: selectAttachmentsGroupPlaceholderNode,
  validationRules: [
    selectValidateChildrenAreRequired,
    validateGlobalDocumentAllowedInAttachmentsGroup,
  ],
  information: {
    single: 'Bijlagegroep',
    plural: 'Bijlagegroep',
    description:
      'Download or bijlagegroep that is a building block (as opposed to PAGE_ATTACHMENTS_GROUP) and appears inline in the document',
    conditionalTitle: (node) => (node.isDownloadAttachmentsGroup ? 'Download' : 'Bijlagegroep'),
    ribbonTitle: 'Bijlagegroep',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENTS_GROUP.svg'),
  },
  buildingBlocks: [
    {
      type: NodeType.ATTACHMENT,
    },
    {
      type: NodeType.GLOBAL_DOCUMENT,
    },
  ],
  edit: [
    attachmentGroupTypeComponent,
    {
      component: 'webFacetsPlaceHolder',
      reactComponent: 'AsideWebFacetPlaceHolder',
      label: 'Facetten',
      width: '12',
      showInAncestorTypes: proWebFacetAncestorTypes,
    },
  ],
  addAccessRightsToAudienceTab: true,
  isCollapsible: false,
  confirmDelete: true,
  showPlaceholder: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default ATTACHMENTS_GROUP;
