import { any } from 'prop-types';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { getNow } from '@kathondvla/sri-client/date-utils';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { addRelationAction, removeRelationAction } from '@store/actions/documentActions';
import { RootState } from '@generalTypes/rootStateTypes';
import { EditAsideZillGoals } from '@nodeTypeConfig/configTypes';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { selectZillGoalsRelations } from './AsideWebFacetsSelectors';
import { useEditHref } from '../../../hooks/UseEditHref';
import './AsideZillGoals.scss';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

export const AsideZillGoals: React.FC<{
  config: EditAsideZillGoals;
}> = ({ config }) => {
  const readOnly = useReadOnly();
  const href = useEditHref();
  const key = getResourceKey(href);

  const dispatch = useDispatch();

  const goalRelations = useSelector((state: RootState) => selectZillGoalsRelations(state, href));
  const modalWindowService = getAngularService('ModalWindowService');
  const zillSelectorService = getAngularService('ZillSelectorService');
  const confirmationService = getAngularService('ConfirmationService');

  const deleteGoal = async (zillGoalRelationKey) => {
    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      dispatch(removeRelationAction(zillGoalRelationKey));
    }
  };

  const openSelector = async () => {
    const zillCurriculum = await modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true,
        },
      },
    });
    try {
      // after selecting a zill version, we will open the zill selector where you can adapt the
      // selected goals of that version. We do not touch the goals of different zill versions.
      const currentGoalsRelatedToSelectedVersion = goalRelations.filter(
        (ref) => ref.rootHref === zillCurriculum.$$meta.permalink
      );
      const currentGoalsRelatedToSelectedVersionHrefs = currentGoalsRelatedToSelectedVersion.map(
        (ref) => ref.contentHref
      );

      const selectedGoals = Array.from(
        new Set(
          await zillSelectorService.openZillSelector(
            zillCurriculum.$$meta.permalink,
            currentGoalsRelatedToSelectedVersionHrefs,
            'Pro thema'
          )
        )
      );

      // the selected goals are the goals that were confirmed after making a selection in the zill selector
      const deletedGoals = currentGoalsRelatedToSelectedVersion.filter(
        (r) => !selectedGoals.includes(r.contentHref)
      );
      deletedGoals.forEach(async (r) => {
        dispatch(removeRelationAction(r.relationKey));
      });

      const zillGoals = selectedGoals.filter(
        (goalHref) => !currentGoalsRelatedToSelectedVersionHrefs.some((href) => href === goalHref)
      );

      console.log('UPDATE zill goals:', zillGoals);

      zillGoals.forEach((goalHref) => {
        // save relation to goal item
        const newRelation = {
          relationtype: 'REFERENCES',
          strength: 'MEDIUM',
          from: {
            href: `/content/${key}`,
          },
          to: {
            href: goalHref,
          },
        };

        dispatch(addRelationAction(newRelation));
      });
    } catch (error) {
      if (error === 'ZILL_SELECTOR_ABORTED') {
        console.log('The user closed the zill selector manually.');
      } else {
        console.error(
          'An unexpected error occured when communicating with the Zill selector',
          error
        );
      }
    }
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="panel-controls panel-controls-left">
            <h3 className="panel-title">{config.label}</h3>
          </div>
          <div className="panel-controls panel-controls-right">
            <div className="btn-toolbar">
              {!readOnly && (
                <button className="btn btn-default btn-open-zill" onClick={openSelector}>
                  <span>selector</span>
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="panel-body fixed-height">
          {goalRelations.length > 0 && (
            <div className="structure">
              {goalRelations.map((goalRelation) => (
                <div key={goalRelation.relationKey} className="row table-row">
                  <div className="col-md-12 link-row">
                    <div>
                      <span>
                        <small>
                          <strong>{goalRelation.rootTitle}</strong>
                        </small>
                      </span>
                      <br />
                      <span>
                        <small>{goalRelation.rootPath}</small>
                      </span>
                    </div>
                  </div>

                  <div className="btn-group btn-group-xs row-actions" role="group" aria-label="...">
                    {!readOnly && (
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => deleteGoal(goalRelation.relationKey)}
                      >
                        <span className="glyphicon glyphicon-delete"></span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </AsideValidation>
  );
};

AsideZillGoals.displayName = 'AsideZillGoals';

export default AsideZillGoals;

AsideZillGoals.propTypes = {
  config: any,
};
