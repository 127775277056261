import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { menuItemReference } from '@nodeTypeConfig/helpers/editComponents/menuItemReference';
import { extendsSection } from '../../helpers/genericConfigHelpers';

// Extends SECTION => A SECTION inside the PRO_GLOBAL_DATABASE becomes a PRO_THEME_SECTION
const PRO_GLOBAL_DATABASE_SECTION: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
    {
      ...menuItemReference,
      options: {
        externalLinkHidden: true,
      },
    },
  ],
};
export default PRO_GLOBAL_DATABASE_SECTION;
