import { reactToAngularComponent } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import StoreProvider from '@store/StoreProvider';
import AsideGoalRelations from '@UI/aside/asideGoalRelations/AsideGoalRelations';
import EncodedText from '@UI/encodedText/EncodedText';
import AsideNewsletterSender from '@UI/aside/asideNewsletterSender/AsideNewsletterSender';
import ContentRow from '@UI/mainScreen/contentRow/ContentRow';
import Document from '@UI/mainScreen/document/Document';
import BuildingBlocks from '@UI/buildingBlocks/BuildingBlocks';
import AsideEditor from '@UI/editor/AsideEditor';
import AsideTitle from '@UI/aside/asideRichTextInput/AsideTitle';
import AsideValidityPeriod from '@UI/aside/asideSimpleInput/AsideValidityPeriod';
import AsideImage from '@UI/aside/asideImage/AsideImage';
import AsideVersion from '@UI/aside/asideSimpleInput/AsideVersion';
import AsideIdentifier from '@UI/aside/asideSimpleInput/AsideIdentifier';
import AsideStaticHtml from '@UI/aside/asideStaticHtml/AsideStaticHtml';
import AsidePreviousVersion from '@UI/aside/asidePreviousVersion/AsidePreviousVersion';
import AsideDescription from '@UI/aside/asideDescription/AsideDescription';
import AsidePreviousVersionItems from '@UI/aside/asidePreviousVersionItems/AsidePreviousVersionItems';
import AsideCheckbox from '@UI/aside/asideCheckbox/AsideCheckbox';
import AsideColor from '@UI/aside/asideColor/AsideColor';
import AsideTeaserImportance from '@UI/aside/asideTeaserImportance/AsideTeaserImportance';
import AsideReferenceFrameReferences from '@UI/aside/asideReferenceFrameReferences/AsideReferenceFrameReferences';
import AsideDatePicker from '@UI/aside/asideDatePicker/AsideDatePicker';
import AsideDateToSendPicker from '@UI/aside/asideDatePicker/AsideDateToSendPicker';
import AsideIssuedDatePicker from '@UI/aside/asideDatePicker/AsideIssuedDatePicker';
import AsideLinks from '@UI/aside/asideLinks/AsideLinks';
import AsideSimpleDropDown from '@UI/aside/asideSimpleDropDown/AsideSimpleDropDown';
import AsideCurriculumTheme from '@UI/aside/asideCurriculumTheme/asideCurriculumTheme';
import AsidePersonPicker from '@UI/aside/asidePersonPicker/AsidePersonPicker';
import AsideSources from '@UI/aside/asideSources/AsideSources';
import AsideFreeTextAuthor from '@UI/aside/asideFreeTextAuthor/AsideFreeTextAuthor';
import AsideNamedSet from '@UI/aside/asideNamedSet/AsideNamedSet';
import AsideCoverage from '@UI/aside/asideCoverage/AsideCoverage';
import AsideSecondaryEducationType from '@UI/aside/asideSecondaryEducationType/AsideSecondaryEducationType';
import AsideAttachedContent from '@UI/aside/asideAttachedContent/AsideAttachedContent';
import AsideTeaserLinkedContent from '@UI/aside/asideTeaserLinkedContent/AsideTeaserLinkedContent';
import AsideCurriculumSelector from '@UI/aside/asideCurriculumSelector/AsideCurriculumSelector';
import AsideConcordanties from '@UI/aside/asideConcordanties/AsideConcordanties';
import AsideDevelopmentPhase from '@UI/aside/asideDevelopmentPhase/AsideDevelopmentPhase';
import AsideWebFacetPlaceHolder from '@UI/aside/asideWebFacets/AsideWebFacetPlaceHolder';
import AsideAgeRange from '@UI/aside/asideAgeRange/AsideAgeRange';
import AsideReferenceFramePicker from '@UI/aside/asideWebFacets/AsideReferenceFramePicker';
import AsideNodeVersions from '@UI/aside/asideNodeVersions/AsideNodeVersions';
import AsideZillGoals from '@UI/aside/asideWebFacets/AsideZillGoals';
import AsideThemes from '@UI/aside/asideThemes/AsideThemes';
import AsideVideo from '@UI/aside/asideVideo/AsideVideo';
import AsideStudyProgrammes from '@UI/aside/asideStudyProgrammes/AsideStudyProgrammes';
import AsideSubjects from '@UI/aside/asideSubjects/AsideSubjects';
import AsideAccessRights from '@UI/aside/asideAccessRights/AsideAccessRights';
import AsideCurriculumType from '@UI/aside/asideCurriculumType/AsideCurriculumType';
import AsideFile from '@UI/aside/asideFileSelector/AsideFile';
import AsidePracticalExampleReferences from '@UI/aside/asidePracticalExampleReferences/asidePracticalExampleReferences';
import AsideZillIllustrations from '@UI/aside/asideZillIllustrations/asideZillIllustrations';
import AsideGoalsByZillIllustration from '@UI/aside/asideGoalsByZillIllustration/asideGoalsByZillIllustration';
import AsideAttachmentGroupType from '@UI/aside/asideAttachmentGroupType/AsideAttachmentGroupType';
import AsideMenuItemReference from '@UI/aside/asideMenuItemReference/AsideMenuItemReference';
import app from '../app/app';

app.component(
  'reactAsideGoalRelations',
  reactToAngularComponent(AsideGoalRelations, StoreProvider)
);
app.component('reactEncodedText', reactToAngularComponent(EncodedText, StoreProvider));

app.component('reactContentRow', reactToAngularComponent(ContentRow, StoreProvider));
app.component('reactDocument', reactToAngularComponent(Document, StoreProvider));
app.component('reactBuildingBlocks', reactToAngularComponent(BuildingBlocks, StoreProvider));

app.component('reactAsideEditor', reactToAngularComponent(AsideEditor, StoreProvider));

const asideReactComponents = [
  AsideAttachmentGroupType,
  AsideNodeVersions,
  AsideFreeTextAuthor,
  AsideNewsletterSender,
  AsideSources,
  AsideSimpleDropDown,
  AsideValidityPeriod,
  AsideVersion,
  AsideImage,
  AsideVideo,
  AsideFile,
  AsideIdentifier,
  AsideTitle,
  AsideSubjects,
  AsideStaticHtml,
  AsideDescription,
  AsidePreviousVersion,
  AsidePreviousVersionItems,
  AsideSecondaryEducationType,
  AsideCheckbox,
  AsidePersonPicker,
  AsideColor,
  AsideTeaserImportance,
  AsideReferenceFrameReferences,
  AsideDatePicker,
  AsideDateToSendPicker,
  AsideIssuedDatePicker,
  AsideLinks,
  AsideCurriculumTheme,
  AsideStudyProgrammes,
  AsideNamedSet,
  AsideCoverage,
  AsideCurriculumSelector,
  AsideTeaserLinkedContent,
  AsideAttachedContent,
  AsideConcordanties,
  AsideDevelopmentPhase,
  AsideWebFacetPlaceHolder,
  AsideAgeRange,
  AsideReferenceFramePicker,
  AsideZillGoals,
  AsideThemes,
  AsideAccessRights,
  AsideCurriculumType,
  AsidePracticalExampleReferences,
  AsideZillIllustrations,
  AsideGoalsByZillIllustration,
  AsideMenuItemReference,
];

asideReactComponents.forEach((component) => {
  if (!component.displayName) {
    console.error('Component does not have a displayName', component);
  } else {
    app.component(
      `react${component.displayName}`,
      reactToAngularComponent(component, StoreProvider)
    );
  }
});
