import { Content, ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';
import { get } from 'lodash';

const generateColorChangeMessage = (
  content: Content,
  originalContent: Content | undefined,
  config: EditComponent
): string | null => {
  if (!config.property) {
    console.error('No property found for config', config);
    throw Error(`Can not convert ${config.property} to a string`);
  }

  const newValue = get(content, config.property) ?? '';
  const oldValue = get(originalContent, config.property) ?? '';

  if (newValue === oldValue) {
    return null;
  }

  return `<span style="background-color:${oldValue}">&nbsp;&nbsp;&nbsp;&nbsp;</span> ${oldValue} 🡒 <span style="background-color:${newValue}">&nbsp;&nbsp;&nbsp;&nbsp;</span> ${newValue}`;
};

export const selectChangeMessageForColor: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state) => state.documentUI.mode,
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, mode, config): string | null => {
      return generateColorChangeMessage(content, originalContent, config);
    }
  );
