import { ContentHref, isAttachmentAttachmentMetaInfo } from '@generalTypes/apiTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { attachmentGroupTypeOptions } from '@nodeTypeConfig/helpers/editComponents/attachmentGroupTypeComponent';
import { getSelectedOptionForContent } from '@UI/aside/asideAttachmentGroupType/asideAttachmentGroupSelectors';
import { AttachmentNode, AttachmentsGroupNode } from '../documentUITypes';
import {
  selectAppliedNodeConfig,
  selectNodeType,
  selectTypeNameSingle,
} from '../nodeTypeConfigSelectors';
import {
  getAttachmentCreateDate,
  getAttachmentFileSize,
  getFileTag,
  getFileType,
  getIconSrc,
} from './attachmentHelpers';

export const selectAttachment = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectNodeType(state, href),
  ],
  (content, type): AttachmentNode => {
    if (type !== NodeType.ATTACHMENT) {
      throw new Error('only call selectAttachment for the correct types');
    }
    const attachment = content.attachments.find(isAttachmentAttachmentMetaInfo);
    if (!attachment) {
      return {
        attachmentKey: content.key,
        title: content.title,
        description: content.description,
        fileTypeIconSrc: '',
        createDate: '',
        fileType: '',
        fileTag: '',
        fileSize: '',
        href: content.$$meta.permalink,
        key: content.key,
        type,
        customRender: true,
      };
    }
    return {
      attachmentKey: content.key,
      title: content.title,
      description: content.description,
      fileTypeIconSrc: getIconSrc(attachment),
      createDate: getAttachmentCreateDate(attachment),
      fileType: getFileType(attachment),
      fileTag: getFileTag(content),
      fileSize: getAttachmentFileSize(attachment),
      href: content.$$meta.permalink,
      key: content.key,
      type,
      customRender: true,
    };
  }
);

export const selectAttachmentsGroupPlaceholderNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectTypeNameSingle(state, href),
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
  ],
  (content, name, nodeConfig): AttachmentsGroupNode => {
    const title = name;
    let subTitle = '';
    // only if the type is something we can change, we show the subtitle
    if (nodeConfig.edit.some((edit) => edit.component === 'attachmentGroupType')) {
      const attachmentGroupType = getSelectedOptionForContent(content, attachmentGroupTypeOptions);
      subTitle = attachmentGroupType.label.toLowerCase();
    }

    return {
      href: content.$$meta.permalink,
      key: content.key,
      title,
      subTitle,
      type: NodeType.ATTACHMENTS_GROUP,
      customRender: true,
    };
  }
);
