import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAllExternalData } from '@newStore/externalData/externalDataSelectors';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { relationStrengthTranslations } from '../../../../reduxLoop/constants/relationStrengthTranslations';

const AsideLlinkidReferenceItem = ({ href, options, rel }) => {
  const item = useSelector((state: RootState) => selectAllExternalData(state)[href]);
  const loadingClass = !item ? 'loadingSlider' : '';
  return (
    <>
      <div className={`col-md-8 indented ${loadingClass}`}>
        <span
          dangerouslySetInnerHTML={{
            __html: item?.[options.listColumn],
          }}
        ></span>
      </div>
      {options.referenceFrame === referenceFrames.educationalComponents && (
        <div className={`col-md-4 indented ${loadingClass}`}>
          <span>{relationStrengthTranslations[rel.strength]}</span>
        </div>
      )}
    </>
  );
};

export default AsideLlinkidReferenceItem;
