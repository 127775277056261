import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectValidateChildrenAreRequired } from '@newStore/validation/validationRules/childrenAreRequired';
import { validateGlobalDocumentAllowedInAttachmentsGroup } from '@newStore/validation/validationRules/globalDocumentSecurityConsistency';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { proWebFacetAncestorTypes } from '@nodeTypeConfig/helpers/editComponents/proWebFacetAncestorTypes';

const DOWNLOAD: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  validationRules: [
    selectValidateChildrenAreRequired,
    validateGlobalDocumentAllowedInAttachmentsGroup,
  ],
  information: {
    single: 'Downloadgroep',
    plural: 'Downloadgroep',
    description:
      'Download is a special type of attachment group that has one main attachment and optionally extra attachments',
    ribbonTitle: 'Downloadgroep (voor downloadlijst)',
    ribonIcon: require('../../../../img/buildingBlocks/DOWNLOAD_icon.svg'),
  },
  buildingBlocks: [
    {
      type: NodeType.ATTACHMENT,
    },
    {
      type: NodeType.GLOBAL_DOCUMENT,
    },
  ],
  edit: [
    {
      component: 'webFacetsPlaceHolder',
      reactComponent: 'AsideWebFacetPlaceHolder',
      label: 'Facetten',
      width: '12',
      showInAncestorTypes: proWebFacetAncestorTypes,
    },
  ],
  addAccessRightsToAudienceTab: true,
  isCollapsible: true,
  isCollapsedByDefault: false,
  confirmDelete: true,
  showPlaceholder: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default DOWNLOAD;
