import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

export const getAgeYear = (age) => age && Math.trunc(age);
export const getAgeMonth = (age) => age && Math.round(12 * (age - Math.trunc(age))) % 12;
const getFormatedAge = (age) => {
  const year = getAgeYear(age) || '0';
  const month = getAgeMonth(age);
  return `${year}j${month ? ` ${month}m` : ''}`;
}

export const selectChangeMessageForAgeRange: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref) => selectContentItem(state, href),
    ],
    (originalContent, content): string | null => {
      const from = `${getFormatedAge(originalContent?.startage)} - ${getFormatedAge(
        originalContent?.endage
      )}`;
      const to = `${getFormatedAge(content?.startage)} - ${getFormatedAge(content?.endage)}`;
      return stringDiffArrowFunction(to, from);
    }
  );
