import { Content, ContentHref, ContentRelation, ContentType } from '@generalTypes/apiTypes';
import { ContentRelationsMap } from '@generalTypes/generalTypes';
import { ContentRecord, RelationsToAndFromMap } from '@newStore/documentApi/documentApiTypes';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';
import { sanitizeHTML } from '@store/helpers/documentHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { settings } from '../../config/settings';
import { getPathToRoot } from '../externalData/externalDataHelpers';
import {
  SelectedZillGoal,
  ZillCurriculumMini,
  ZillCurriculumSelection,
  ZillGoal,
  ZillGoalHasFailedToLoad,
  ZillGoalIsLoading,
  ZillGoalSubDetail,
} from './zillTypes';

export const transformZillGoalOrSubDetail = (
  goalOrSubDetail: Content,
  externalContent: ContentRecord,
  externalContentRelationsMap: ContentRelationsMap
): ZillGoal | ZillGoalSubDetail => {
  const pathToRoot = getPathToRoot(
    externalContentRelationsMap,
    externalContent,
    goalOrSubDetail.$$meta.permalink
  );
  const goal = pathToRoot.find((path) => path.type === ContentType.CURRICULUM_ZILL_GENERIC_GOAL);
  if (!goal) {
    throw new Error(
      `Data corruption: ${goalOrSubDetail.$$meta.permalink} has no CURRICULUM_ZILL_GENERIC_GOAL as parent`
    );
  }
  const zillDevelopmentField = pathToRoot.find(
    (path) => path.type === ContentType.CURRICULUM_ZILL_DEVELOPMENT_FIELD
  );
  const zillCurriculum = pathToRoot[pathToRoot.length - 1];
  const developmentContent = pathToRoot.filter(
    (elem) => elem.type === ContentType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT
  );
  const leerlijnCluster = pathToRoot.find(
    (elem) => elem.type === ContentType.CURRICULUM_ZILL_LEERLIJN_CLUSTER
  );

  const code = getGoalIdentifier(pathToRoot.slice(0, -2)); // remove top(last) 2 elements

  const zillGoal: ZillGoal = {
    href: goal.$$meta.permalink,
    code,
    color: zillDevelopmentField?.color,
    icon:
      zillDevelopmentField && zillDevelopmentField.attachments.some((att) => att.type === 'ICON')
        ? settings.apisAndUrls.cachedApi +
          // eslint-disable-next-line no-unsafe-optional-chaining
          zillDevelopmentField.attachments.find((att) => att.type === 'ICON')?.href
        : null,
    title: sanitizeHTML(goal.title, 'clearAll'),
    type: NodeType.CURRICULUM_ZILL_GENERIC_GOAL,
    zillCurriculum: {
      href: zillCurriculum.$$meta.permalink,
      title: zillCurriculum.title,
      version: zillCurriculum.identifiers ? zillCurriculum.identifiers[0] : undefined,
    },
  };

  if (developmentContent.length) {
    const description = [...developmentContent]
      .reverse() // reverse mutates array
      .map((elem) => sanitizeHTML(elem.title, 'clearAll'))
      .join(' > ');
    return {
      href: goalOrSubDetail.$$meta.permalink,
      description,
      shortDescription: `${description.substring(0, 120)}${description.length > 120 ? '...' : ''}`,
      type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT,
      zillGoal,
    };
  }
  if (leerlijnCluster) {
    const description = `[${leerlijnCluster.startage}-${leerlijnCluster.endage}] ${leerlijnCluster.description}`;
    return {
      href: goalOrSubDetail.$$meta.permalink,
      description,
      shortDescription: `${description.substring(0, 120)}${description.length > 120 ? '...' : ''}`,
      type: NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER,
      zillGoal,
    };
  }
  return zillGoal;
};

export const getZillGoalOrSubDetailByHref = (
  goalHref: ContentHref,
  externalContent: ContentRecord,
  externalContentRelationsMap: ContentRelationsMap,
  failedResourceAncestors: readonly string[],
  resourceAncestorsToLoad: readonly string[]
): ZillGoal | ZillGoalSubDetail | ZillGoalIsLoading | ZillGoalHasFailedToLoad => {
  if (failedResourceAncestors.includes(goalHref)) {
    return {
      href: goalHref,
      hasFailedToLoad: true,
    };
  }
  if (!externalContent[goalHref] || resourceAncestorsToLoad.includes(goalHref)) {
    return {
      href: goalHref,
      type: 'ZILL_GOAL_OR_DEVELOPMENTCONTENT',
      isLoading: true,
    };
  }
  const goal = externalContent[goalHref];
  return transformZillGoalOrSubDetail(goal, externalContent, externalContentRelationsMap);
};

export const getGoalByIllustrationToGoalRel = (
  illustrationToGoalRel: ContentRelation,
  externalContent: ContentRecord,
  externalContentRelationsMap: ContentRelationsMap,
  failedResourceAncestors: readonly string[],
  resourceAncestorsToLoad: readonly string[]
): ZillGoal | ZillGoalSubDetail | ZillGoalIsLoading | ZillGoalHasFailedToLoad => {
  const transformedGoal = getZillGoalOrSubDetailByHref(
    illustrationToGoalRel.to.href as ContentHref,
    externalContent,
    externalContentRelationsMap,
    failedResourceAncestors,
    resourceAncestorsToLoad
  );
  return {
    ...transformedGoal,
    relationFromIllustrationHref: illustrationToGoalRel.$$meta.permalink,
  };
};

export const groupGoalsByZillCurriculum = (zillGoals: SelectedZillGoal[]) => {
  // @matthias: can you type zillCurriculumMap?
  const zillCurriculumMap: Record<ContentHref, ZillCurriculumMini & { goals: SelectedZillGoal[] }> =
    {};

  zillGoals.forEach((goal) => {
    if (!zillCurriculumMap[goal.zillCurriculum.href]) {
      zillCurriculumMap[goal.zillCurriculum.href] = {
        ...goal.zillCurriculum,
        goals: [],
      };
    }
    zillCurriculumMap[goal.zillCurriculum.href].goals.push(goal);
  });

  return zillCurriculumMap;
};

export const groupSubDetailsByGoal = (subDetailsArray: ZillGoalSubDetail[]) => {
  const goalMap: Record<string, { goal: ZillGoal; subDetail: ZillGoalSubDetail[] }> = {};
  subDetailsArray.forEach((subDetail) => {
    if (!goalMap[subDetail.zillGoal.href]) {
      goalMap[subDetail.zillGoal.href] = {
        goal: subDetail.zillGoal,
        subDetail: [subDetail],
      };
    } else {
      goalMap[subDetail.zillGoal.href].subDetail.push(subDetail);
    }
  });
  return Object.values(goalMap);
};

export const buildCurrentSelectionForZillSelector = (
  zillGoals: SelectedZillGoal[]
): ZillCurriculumSelection[] => {
  const allSelections: ZillCurriculumSelection[] = [];
  zillGoals.forEach((zillGoal) => {
    if (!zillGoal.subDetails.length) {
      allSelections.push({
        href: zillGoal.href,
        type: 'ZILL_GOAL',
        relationFromIllustrationHref: zillGoal.relationFromIllustrationHref,
        zillGoal,
      });
    }
    zillGoal.subDetails.forEach((subDetail) => {
      allSelections.push({
        href: subDetail.href,
        type: 'ZILL_GOAL_SUB_DETAIL',
        relationFromIllustrationHref: subDetail.relationFromIllustrationHref,
        zillGoal,
      });
    });
  });
  return allSelections;
};

export const getPracticalExampleRelations = (
  documentRootHref: ContentHref | null,
  relationsMap: RelationsToAndFromMap
) => {
  if (!documentRootHref) {
    return [];
  }
  return (
    relationsMap.from[documentRootHref]?.filter(
      (rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'MEDIUM'
    ) || []
  );
};
