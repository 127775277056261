import { any } from 'prop-types';
import {
  addRelationAction,
  patchRelationAction,
  removeRelationAction,
} from '@store/actions/documentActions';
import { useDispatch } from 'react-redux';
import { EditAsideMenuItemReference, NodeType } from '@nodeTypeConfig/configTypes';
import {
  AsyncKovResourcePicker,
  useLoadSriOptions,
} from '@kathondvla/react-shared-components/src/components';
import { contentApi } from '@store/api/apiConfig';
import { useEffect, useState } from 'react';
import { stripHtml } from '@newStore/genericHelpers';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useEditHref } from '../../../hooks/UseEditHref';
import './asideMenuItemReference.scss';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { selectReferenceRelation } from './asideMenuItemReferenceSelectors';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const DisplayName = ({ item }) => {
  if (item.title) {
    return item.title;
  }
  if (item?.$$expanded?.$$pathToRoot?.length > 1) {
    return (
      <span>
        {item.$$expanded.title} -{' '}
        <span className="pathToRoot">
          {stripHtml(item.$$expanded.$$pathToRoot.slice(1).join(' > '))}
        </span>
      </span>
    );
  }

  return item?.$$expanded?.title ?? '';
};
const AsideMenuItemReference: React.FC<{
  config: EditAsideMenuItemReference;
}> = ({ config }) => {
  const readOnly = useReadOnly();
  const href = useEditHref();
  const dispatch = useDispatch();

  const referenceRelation = useTypedSelector((state) => selectReferenceRelation(state, href));
  const [linkedType, setLinkedType] = useState<'external' | 'webpage'>('external');
  const [externalUrl, setExternalUrl] = useState<string>(referenceRelation?.externalHref || '');

  useEffect(() => {
    setLinkedType(!referenceRelation || referenceRelation?.externalHref ? 'external' : 'webpage');
  }, [referenceRelation]);

  const sriLoadOptionsFn = useLoadSriOptions({
    sriClient: contentApi,
    href: '/content',
    parameters: {
      typeIn: 'STRUCTURED_DOCUMENT,SECTION',
      rootTag: [NodeType.WEBPAGE2],
      $$expandPathToRoot: true,
      orderBy: '$$meta.modified',
      descending: true,
    },
  });

  const changeRelation = (toHref) => {
    if (!toHref) {
      if (referenceRelation) {
        dispatch(removeRelationAction(referenceRelation.key));
      }
    } else if (referenceRelation) {
      dispatch(patchRelationAction(referenceRelation.key, { to: { href: toHref } }));
    } else {
      const newRelation = {
        relationtype: 'REFERENCES',
        from: {
          href,
        },
        to: {
          href: toHref,
        },
      };
      dispatch(addRelationAction(newRelation));
    }
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <div className="form-group">
        <label className="control-label">{config.label}</label>
        {!config.options?.externalLinkHidden && (
          <div className="row option-row">
            <div className="col-md-2 nopadding">
              <input
                id="external"
                type="radio"
                value="external"
                checked={linkedType === 'external'}
                onChange={() => setLinkedType('external')}
                disabled={readOnly}
                className="form-control"
                name="cta"
              />
              <label
                htmlFor="external"
                className={`radiolabel${readOnly ? 'aside-component-disabled' : ''}`}
              >
                Externe URL
              </label>
            </div>
            <div className="col-md-10">
              <input
                type="text"
                className="form-control"
                value={externalUrl}
                onBlur={() => changeRelation(externalUrl)}
                onChange={(e) => setExternalUrl(e.target.value)}
                disabled={readOnly}
              />
            </div>
          </div>
        )}
        <div className="row option-row">
          <div className="col-md-2 nopadding">
            {!config.options?.externalLinkHidden && (
              <input
                id="webpage"
                type="radio"
                value="webpage"
                checked={linkedType === 'webpage'}
                onChange={() => setLinkedType('webpage')}
                disabled={readOnly}
                className="form-control"
                name="cta"
              />
            )}
            <label
              htmlFor="webpage"
              className={`radiolabel${readOnly ? 'aside-component-disabled' : ''}`}
            >
              Web pagina
            </label>
          </div>
          <div className="col-md-10">
            <AsyncKovResourcePicker
              value={referenceRelation}
              onChange={(option) => {
                setExternalUrl('');
                changeRelation(option?.href);
              }}
              loadOptions={sriLoadOptionsFn}
              disabled={readOnly}
              optionValue={(option) => option.href}
              placeholder={`Zoek ${config.label.toLowerCase()}`}
              clearable={true}
              multiple={false}
              optionTemplate={(option) => <DisplayName item={option} />}
            />
          </div>
        </div>
      </div>
    </AsideValidation>
  );
};

AsideMenuItemReference.displayName = 'AsideMenuItemReference';

export default AsideMenuItemReference;

AsideMenuItemReference.propTypes = {
  config: any,
};
