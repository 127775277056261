import { EditAsideGoalsByZillIllustration } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addRelationAction, removeRelationAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { selectZillGoalsForZillIllustration } from '@newStore/zill/zillSelectors';
import {
  buildCurrentSelectionForZillSelector,
  groupGoalsByZillCurriculum,
} from '@newStore/zill/zillHelpers';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { getNow } from '@kathondvla/sri-client/date-utils';
import { addResourceAncestorsToLoad } from '@newStore/externalData/externalDataState';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useEditHref } from '../../../hooks/UseEditHref';

const AsideGoalsByZillIllustration: React.FC<{
  config: EditAsideGoalsByZillIllustration;
}> = ({ config }) => {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const editKey = getResourceKey(editHref);
  const confirmationService = getAngularService('ConfirmationService');
  const modalWindowService = getAngularService('ModalWindowService');
  const zillSelectorService = getAngularService('ZillSelectorService');

  const { illustrationsAreLoaded, genericGoals, loadingZillGoals } = useTypedSelector((state) =>
    selectZillGoalsForZillIllustration(state)
  );
  const loading = !illustrationsAreLoaded || loadingZillGoals.length > 0;

  const goalsGroupedByZillCurriculum = groupGoalsByZillCurriculum(genericGoals);
  const zillCurriculumGroups = Object.values(goalsGroupedByZillCurriculum);

  const openSelector = async () => {
    const zillCurriculum = await modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true,
        },
      },
    });

    if (zillCurriculum) {
      try {
        // after selecting a zill version, we will open the zill selector where you can adapt the
        // selected goals of that version. We do not touch the goals of different zill versions.
        const groupForSelectedVersion =
          goalsGroupedByZillCurriculum[zillCurriculum.$$meta.permalink];
        const currentSelections = groupForSelectedVersion
          ? buildCurrentSelectionForZillSelector(groupForSelectedVersion.goals)
          : [];

        const selectedGoals = await zillSelectorService.openZillSelector(
          zillCurriculum.$$meta.permalink,
          currentSelections.map((sel) => sel.href),
          'illustratie'
        );

        const deletedSelections = currentSelections.filter(
          (cs) => !selectedGoals.includes(cs.href)
        );

        deletedSelections.forEach((selection) => {
          dispatch(removeRelationAction(getResourceKey(selection.relationFromIllustrationHref)));
        });

        const newSelections = selectedGoals.filter(
          (href) => !currentSelections.some((sel) => sel.href === href)
        );
        if (newSelections) {
          dispatch(addResourceAncestorsToLoad({ resources: newSelections }));
          newSelections.forEach(async (selection) => {
            dispatch(
              addRelationAction({
                relationtype: 'REFERENCES',
                from: {
                  href: `/content/${editKey}`,
                },
                to: {
                  href: selection,
                },
                strength: 'LOW',
              })
            );
          });
        }
      } catch (error) {
        if (error === 'ZILL_SELECTOR_ABORTED') {
          console.log('The user closed the zill selector manually.');
        } else {
          console.error(
            'An unexpected error occured when communicating with the Zill selector',
            error
          );
        }
      }
    }
  };

  const deleteIllustration = async (goal) => {
    // delete illustration and relation to document
    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      if (goal.subDetails.length) {
        goal.subDetails.forEach((subDetail) => {
          dispatch(removeRelationAction(getResourceKey(subDetail.relationFromIllustrationHref)));
        });
      } else {
        dispatch(removeRelationAction(getResourceKey(goal.relationFromIllustrationHref)));
      }
    }
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        <div>
          <div className="panel panel-default">
            <div className="panel-heading">
              <div className="panel-controls panel-controls-left">
                <h3 className="panel-title">{config.label}</h3>
              </div>
              <div className="panel-controls panel-controls-right">
                <div className="btn-toolbar">
                  {illustrationsAreLoaded && (
                    <button className="btn btn-default" onClick={openSelector}>
                      <span>
                        <img src="/images/logo_zill.png" width="20px" />
                      </span>
                      <span>selector</span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            {loading && <div className="shimmer narrowLine"></div>}

            {!loading && zillCurriculumGroups.length > 0 && (
              <div className="structure">
                {zillCurriculumGroups.map((row) => (
                  <div key={row.href}>
                    <div className="row table-row">
                      <div
                        className="col-md-12"
                        style={{ marginBottom: '-5px', marginTop: '15px' }}
                      >
                        <span>
                          <small>
                            <strong>{row.title}</strong>
                          </small>
                        </span>
                      </div>
                    </div>

                    {row.goals.map((goal) => (
                      <div className="row table-row" key={goal.href}>
                        <div className="col-md-12" style={{ marginLeft: '15px' }}>
                          <div style={{ color: goal.color }}>
                            <small>
                              <span style={{ fontWeight: 'bold' }}>{goal.code}</span>:
                              <span style={{ fontWeight: 'bold' }}>{goal.title}</span>
                              {goal.subDetails.map((subDetail) => (
                                <span key={subDetail.href}>
                                  <br />
                                  <span
                                    style={{ marginLeft: '10px' }}
                                    title="{{subDetail.description}}"
                                  >
                                    -&gt; {subDetail.shortDescription}
                                  </span>
                                </span>
                              ))}
                            </small>
                          </div>
                        </div>

                        <div
                          className="btn-group btn-group-xs row-actions"
                          role="group"
                          aria-label="..."
                        >
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => deleteIllustration(goal)}
                          >
                            <span className="glyphicon glyphicon-delete"></span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </AsideValidation>
    </div>
  );
};

AsideGoalsByZillIllustration.displayName = 'AsideGoalsByZillIllustration';

export default AsideGoalsByZillIllustration;

AsideGoalsByZillIllustration.propTypes = {
  config: any,
};
