import { useEffect } from 'react';
import { RootState } from '@generalTypes/rootStateTypes';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentEditingNodeHref,
  selectIsDocumentReadyToShow,
} from '@newStore/documentUI/documentUISelectors';
import { selectParentHrefs } from '@newStore/documentApi/documentApiSelectors';
import { setDropBelowItem } from '@newStore/documentUI/documentUIState';
import { debounce } from 'lodash';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import ModalErrorBoundary from '@UI/modals/ModalErrorBoundary';
import AttachmentGroupCreateModal from '@UI/modals/AttachmentGroupCreateModal';
import GlobalDocumentInsertModal from '@UI/modals/GlobalDocumentInsertModal';
import TableOfContent from '../TableOfContent/TableOfContent';
import ContentRow from '../contentRow/ContentRow';

import { ScrollBottomDropTarget, ScrollTopDropTarget } from '../DragnDrop/ScrollHelpers';
import TermsList from '../termsList/termsList';
import './Document.scss';
import { useScrollToNode } from '../scrollToNode/useScrollToNode';
import DocumentsRelatedToTerm from '../documentsRelatedToTerm/documentsRelatedToTerm';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const Document: React.FC<Record<string, never>> = () => {
  const href = useTypedSelector((state) => state.documentUI.currentDocument);
  // mode is the only indicator to know if there is an initial loading going on or a reload on save
  const isInitialLoadingDone = useTypedSelector((state: RootState) => !!state.documentUI.mode);
  // on every save we reload document api, in which case we show a spinner overlay over the inconsistent state
  // (the pending changes are gone for a moment) to avoid removing the whole document and losing the scoll position and everything
  const isDocumentDoneLoading = useTypedSelector(selectIsDocumentReadyToShow);
  const currentEditingItemHref = useTypedSelector(selectCurrentEditingNodeHref);
  const isAttachmentGroupModalOpen = useTypedSelector(
    (state) => state.documentUI.attachmentGroupModalData.open
  );
  const isAddGlobalDocumentModalOpen = useTypedSelector(
    (state) => state.documentUI.addGlobalDocumentModalData.open
  );
  const currentEditingItemParentHref = useSelector(
    (state: RootState) =>
      currentEditingItemHref && selectParentHrefs(state, currentEditingItemHref)[0]
  );
  const dispatch = useDispatch();

  const scrollToNode = useScrollToNode(true);

  useEffect(() => {
    if (currentEditingItemHref && currentEditingItemParentHref) {
      scrollToNode(currentEditingItemHref, currentEditingItemParentHref);
    }
  }, [scrollToNode, currentEditingItemHref, currentEditingItemParentHref]);

  useEffect(() => {
    const handleDrop = (event: DragEvent) => {
      if (event.dataTransfer?.files) {
        // when dropping files in places that are not allowed, the dropzones are not removed.
        // this makes sure they are removed.
        dispatch(setDropBelowItem(null));
      }
    };

    const handleDragOver = debounce((_event: DragEvent) => {
      // the dragEnd event is never fired when dragging files into the browser.
      // a workaround is to listen to dragover events and remove the dropzones once the dragover events stop firing.
      dispatch(setDropBelowItem(null));
    }, 250);

    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, [dispatch]);

  if (!href) {
    return null;
  }

  if (!isInitialLoadingDone) {
    return (
      <div className="loadingbox">
        <div className="spinner-icon bigCenter"></div>
      </div>
    );
  }

  return (
    <>
      <div className={`documentBox ${isDocumentDoneLoading ? '' : 'documentBox-loading'}`}>
        <ScrollTopDropTarget />

        <TableOfContent />

        <div data-testid="document-nodes">
          <ContentRow key={href} href={href} isRoot={true}></ContentRow>
        </div>

        <TermsList />
        <DocumentsRelatedToTerm />
        <ScrollBottomDropTarget />
      </div>

      {!isDocumentDoneLoading && (
        <div className="loadingbox">
          <div className="spinner-icon bigCenter"></div>
        </div>
      )}

      <Modal
        size="md"
        component={<AttachmentGroupCreateModal />}
        errorBoundaryComponent={ModalErrorBoundary}
        open={isAttachmentGroupModalOpen}
      />

      <Modal
        size="md"
        component={<GlobalDocumentInsertModal modalTitle={'Toevoegen gedeeld document'} />}
        errorBoundaryComponent={ModalErrorBoundary}
        open={isAddGlobalDocumentModalOpen}
      />
    </>
  );
};

export default Document;
