import { NodeType } from '@nodeTypeConfig/configTypes';

/**
 * create a global rank map for all building blocks
 * missing building blocks will be ranked at the bottom (9999)
 */
const buildingBlockRankMap = new Map<NodeType, number>([
  [NodeType.SECTION, 10],
  [NodeType.PARAGRAPH, 20],
  [NodeType.IMAGE, 30],
  [NodeType.IMAGE_GROUP, 40],
  [NodeType.VIDEO, 50],
  [NodeType.VIDEO_GROUP, 60],
  [NodeType.MUSIC, 65],
  [NodeType.QUOTE, 70],
  [NodeType.FAQ_GROUP, 73],
  [NodeType.FAQ, 75],
  [NodeType.EXAMPLE, 80],
  [NodeType.SUMMARY, 90],
  [NodeType.ATTACHMENTS_GROUP, 101],
  [NodeType.DOWNLOAD, 102],
  [NodeType.ATTACHMENT, 103],
  [NodeType.GLOBAL_DOCUMENT, 104],
  [NodeType.REFERENCE_GROUP, 200],
  [NodeType.WORD_IMPORT, 500],

  // llinkid
  [NodeType.LLINKID_GUIDING_PRINCIPLE, 590],
  [NodeType.LLINKID_GOAL_SECTION, 600],
  [NodeType.LLINKID_STATIC_PART, 605],
  [NodeType.LLINKID_CONCORDANCE, 606],
  [NodeType.LLINKID_GOAL, 610],
  [NodeType.LLINKID_PEDAGOGICAL_TIP, 620],
  [NodeType.LLINKID_CONCRETIZATION, 630],
  [NodeType.LLINKID_GOAL_EXPLANATION, 640],
  [NodeType.LLINKID_EXTRA_GOAL_INFORMATION, 650],
  [NodeType.LLINKID_INITIAL_SITUATION, 660],
  [NodeType.LLINKID_GOAL_DEMARCATION, 680],
]);

export default buildingBlockRankMap;
