import { loadNewsletterSettings } from '@newStore/documentApi/documentApiState';
import { selectChangeMessageForDateToSend } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForDate';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { NodeType, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { proNewsletterAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proNewsletterAudienceTab';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForNewsletterSenders } from '@UI/aside/asideNewsletterSender/asideNewsletterSenderSelectors';
import * as ACTION_TYPES from '@store/constants/actionTypes';
import { selectChangeMessageForCheckbox } from '@UI/aside/asideCheckbox/AsideCheckboxSelectors';
import { loadNamedSetsAction } from '@store/actions/documentActions';
import constants from '../../../../reduxLoop/constants/constants';
import { settings } from '../../../settings';

export const PRONEWSLETTER: RootNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [],
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbrief',
    plural: 'PRO.-nieuwsbrieven',
    icon: require('../../../../../img/icons/document.svg'),
    category: 'PRO',
  },
  onLoadActions: [
    loadNewsletterSettings(),
    getAllOfResource({ resource: '/newsletter/senders', refresh: false }),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
    /**
     * the ACTION_TYPES.PATCH_THEMES action still needs the doelgroepen namedset to be present
     * in the old reducer.
     * Once the PATCH_xxx actions are removed, this action can be removed as well.
     */
    loadNamedSetsAction('doelgroepen'),
  ],
  createDefaults: { newsletterSender: { href: constants.dienstCommunicatieSender } },
  createModal: {
    authors: { addUser: true },
    newsLetterTemplate: { required: true },
    dateToSend: { required: true },
  },
  preloadActions: [{ type: ACTION_TYPES.GET_NEWSLETTER_SETTINGS }],
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.REFERENCE_GROUP },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION, NodeType.PRO_NEWSLETTER_SECTION],
  referenceGroupTypes: [constants.referenceGroupTypes.TRAINING],
  edit: [
    titleComponent,
    {
      component: 'dateToSend',
      label: 'Verwachte verzenddatum',
      reactComponent: 'AsideDateToSendPicker',
      options: {
        openTo: 'day',
      },
      asideChangeMessageSelector: selectChangeMessageForDateToSend,
    },
    {
      component: 'newsletterSender',
      reactComponent: 'AsideNewsletterSender',
      property: 'newsletterSender.href',
      asideChangeMessageSelector: selectChangeMessageForNewsletterSenders,
      label: 'Afzender',
      required: RequiredType.ERROR,
    },
    {
      component: 'tableOfContents',
      property: 'tableOfContents',
      label: 'Inhoudstafel',
      reactComponent: 'AsideCheckbox',
      asideChangeMessageSelector: selectChangeMessageForCheckbox,
    },
  ],
  audienceTab: proNewsletterAudienceTab,
  customEditorOptions: {
    showMarkerButton: false,
    hideTermButton: true,
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
  websiteEdition: [{ domain: settings.domains.www }, { domain: settings.domains.pro }],
};
