import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { selectCurrentDocumentHref } from '@newStore/documentUI/documentUISelectors';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector, stripHtml } from '@newStore/genericHelpers';
import { transformZillGoalOrSubDetail } from '@newStore/zill/zillHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

const getFormatedGoalTitles = (
  relationsMap,
  documentRootHref,
  externalContent,
  externalContentRelationsMap
) => {
  const goalsOrSubDetails = relationsMap.from[documentRootHref]
    .filter((rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW')
    .map((illustrationToGoalRel) => {
      const goal = externalContent[illustrationToGoalRel.to.href];
      if (!goal) {
        return [];
      }
      return transformZillGoalOrSubDetail(goal, externalContent, externalContentRelationsMap);
    });
  return goalsOrSubDetails.map((goal) =>
    goal.zillGoal
      ? `${goal.zillGoal.code}: ${stripHtml(goal.zillGoal.title)} -> ${stripHtml(
          goal.shortDescription
        )} - <em>${goal.zillGoal.zillCurriculum?.title}</em>`
      : `${goal.code}: ${stripHtml(goal.title)} - <em>${goal.zillCurriculum?.title}</em>`
  );
};

const getNotIncludedGoalTitles = (a, b, tag) =>
  a.filter((title) => !b.includes(title)).map((title) => `<li><${tag}>${title}</${tag}></li}>`);

export const selectChangeMessageForGoalsByZillIllustration: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectCurrentDocumentHref(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectExternalContent(state),
      (state) => selectExternalContentRelationsMap(state),
    ],
    (
      documentRootHref,
      rawRelationsMap,
      relationsMap,
      externalContent,
      externalContentRelationsMap
    ): string | null => {
      if (!documentRootHref) {
        return null;
      }

      const newGoalTitles = getFormatedGoalTitles(
        relationsMap,
        documentRootHref,
        externalContent,
        externalContentRelationsMap
      );
      const oldGoalTitles = getFormatedGoalTitles(
        rawRelationsMap,
        documentRootHref,
        externalContent,
        externalContentRelationsMap
      );

      const addedTitles = getNotIncludedGoalTitles(newGoalTitles, oldGoalTitles, 'ins');
      const removedTitles = getNotIncludedGoalTitles(oldGoalTitles, newGoalTitles, 'del');

      return [...addedTitles, ...removedTitles].join('') || null;
    }
  );
