import { isReferencesRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { selectCurrentDocumentHref } from '@newStore/documentUI/documentUISelectors';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector, stripHtml } from '@newStore/genericHelpers';
import { transformZillGoalOrSubDetail } from '@newStore/zill/zillHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

const getFormatedIllustrationTitles = (
  relationsMap,
  documentRootHref,
  content,
  externalContent,
  externalContentRelationsMap
) =>
  relationsMap.to[documentRootHref].flatMap((illustrationRelation) => {
    const illustration = content[illustrationRelation.from.href];
    if (!illustration || !isReferencesRelation(illustrationRelation)) {
      return [];
    }
    const goalsOrSubDetails = relationsMap.from[`/content/${illustration.key}`]
      .filter((rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW')
      .map((illustrationToGoalRel) => {
        const goal = externalContent[illustrationToGoalRel.to.href];
        if (!goal) {
          return [];
        }
        return transformZillGoalOrSubDetail(goal, externalContent, externalContentRelationsMap);
      });
    const title =
      stripHtml(content[illustrationRelation.from.href]?.title) || '[illustratie zonder titel]';
    const subtitle = goalsOrSubDetails
      .map((goal) =>
        goal.zillGoal
          ? `<li>${goal.zillGoal.code}: ${stripHtml(goal.zillGoal.title)} -> ${stripHtml(
              goal.shortDescription
            )} - <em>${goal.zillGoal.zillCurriculum?.title}</em></li>`
          : `<li>${goal.code}: ${stripHtml(goal.title)} - <em>${
              goal.zillCurriculum?.title
            }</em></li>`
      )
      .join('');
    return `<strong>${title}</strong><ul>${subtitle}</ul>`;
  });

const getNotIncludedIllustrationTitles = (a, b, tag) =>
  a.filter((title) => !b.includes(title)).map((title) => `<li><${tag}>${title}</${tag}></li}>`);

export const selectChangeMessageForZillIllustrations: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectCurrentDocumentHref(state),
      (state) => state.documentApi.content,
      (state) => selectApiWithPendingChanges(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectExternalContent(state),
      (state) => selectExternalContentRelationsMap(state),
    ],
    (
      documentRootHref,
      rawContent,
      apiWithPendingChanges,
      rawRelationsMap,
      relationsMap,
      externalContent,
      externalContentRelationsMap
    ): string | null => {
      if (!documentRootHref) {
        return null;
      }

      const newIllustrationTitles = getFormatedIllustrationTitles(
        relationsMap,
        documentRootHref,
        apiWithPendingChanges.content,
        externalContent,
        externalContentRelationsMap
      );
      const oldIllustrationTitles = getFormatedIllustrationTitles(
        rawRelationsMap,
        documentRootHref,
        rawContent,
        externalContent,
        externalContentRelationsMap
      );

      const addedHrefs = getNotIncludedIllustrationTitles(
        newIllustrationTitles,
        oldIllustrationTitles,
        'ins'
      );
      const removedHrefs = getNotIncludedIllustrationTitles(
        oldIllustrationTitles,
        newIllustrationTitles,
        'del'
      );

      return [...addedHrefs, ...removedHrefs].join('') || null;
    }
  );
