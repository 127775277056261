import {
  ContentHref,
  ContentType,
  WebSiteHref,
  WebTemplateHref,
  WebTemplateKey,
} from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { loadSharedItemFacets } from '@newStore/externalData/sharedItemFacets';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { selectEmptyThemesRule } from '@newStore/validation/validationRules/notEmptyThemesValidationRules';
import { RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import * as ACTION_TYPES from '@store/constants/actionTypes';

import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import {
  validateWebConfigurationRequired,
  validateWebTemplateConfig,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { proLinks } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import * as apiRoutes from '../../../../reduxLoop/api/apiRoutes';
import constants from '../../../../reduxLoop/constants/constants';
import { settings } from '../../../settings';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';

export const SHARED_MINI_DATABASE_ITEM: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: [
    validateWebConfigurationRequired,
    validateWebTemplateConfig,
    selectCurriculumThemesValidation,
  ],
  information: {
    definiteArticle: true,
    single: 'Gedeeld databankitem',
    plural: 'Gedeelde databankitems',
    category: 'LEERPLANSITES_SO',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_REFERENCE_FRAME,
      payload: { key: constants.contentTypesKey },
    },
  ],
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
    loadSharedItemFacets({
      matchingParams: {
        themesMatchingOnRoot: { referenceFrameKey: constants.dienstverleningKovKey },
        themesOverlaps: { referenceFrameKey: constants.contentTypesKey },
        'webPage.template': '34371fa8-c06b-4ace-83ad-180e5cd0b50b' as WebTemplateKey, // MINI_DATABASE
        $$includeWebPages: true,
      },
    }),
  ],
  websiteEdition: [
    {
      domain: settings.domains.pro,
    },
  ],
  webconfiguration: {
    type: 'MINI_DATABASE_ITEM',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a' as WebTemplateHref,
    },
  },
  tocTypes: [],
  buildingBlocks: proDefaultBuildingBlocks,
  createModal: {
    authors: {
      addUser: false,
      queryParams: constants.allAuthorsParameters,
    },
  },
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      required: RequiredType.WARNING,
      customRequiredMessage:
        'Je moet de <strong>inleiding</strong> invullen. (Belangrijk: de zoeker toont de inleiding in de zoekresultaten)',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      ...imageComponentBase,
      label: 'Afbeelding',
      options: {
        type: 'THUMBNAIL',
        crop: {
          aspectRatio: 4 / 3,
          resize: {
            width: 710,
            height: 533,
            quality: 0.6,
          },
        },
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      options: {
        queryParams: constants.allAuthorsParameters,
      },
    },
    publishComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      customRequiredMessage:
        'Je moet een <strong>thema</strong> invullen. Anders verschijnt dit item niet in een databank.',
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME],
        highlightOldCurrItems: true,
      },
      validations: [selectEmptyThemesRule],
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
    },
    {
      component: 'databaseTypes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: 'In welke databanktype(s) moet dit item getoond worden?',
      customRequiredMessage:
        'Je moet een <strong>databanktype</strong> invullen. Anders verschijnt dit item niet in een databank.',
      options: {
        referenceFrameHref: `/content/${constants.contentTypesKey}` as ContentHref,
        types: [ContentType.THEME],
      },
      validations: [selectEmptyThemesRule],
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
    proLinks,
    {
      component: 'webFacetsPlaceHolder',
      reactComponent: 'AsideWebFacetPlaceHolder',
      label: 'Facetten',
      width: '12',
    },
  ],
  audienceTab: proThemeAudienceTab,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  allowSuggestions: true,
  isImportable: true,
  globalAttachmentsGroupContainer: true,
};
