import { EditAsideTeaserImportance } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { set } from 'lodash';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import SimpleRadioButton from '../../radioButton/SimpleRadioButton';
import AsideTeaserImportanceLabel from './asideTeaserImportanceLabel/AsideTeaserImportanceLabel';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

export type TeaserImportance = 'LOW' | 'MEDIUM' | 'HIGH';

const AsideTeaserImportance: React.FC<{
  config: EditAsideTeaserImportance;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const editHref = useEditHref();

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  const change = (value) => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, value)));
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
        <label className="control-label" htmlFor="teaser-importance">
          {config.label}
        </label>
        <div className="importance" id="teaser-importance" style={{ display: 'flex' }}>
          {(['LOW', 'MEDIUM', 'HIGH'] as TeaserImportance[]).map((importance) => (
            <SimpleRadioButton
              key={importance}
              name={config.property}
              label={<AsideTeaserImportanceLabel importance={importance} />}
              value={importance}
              checked={initialValue === importance}
              onChange={change}
              readOnly={readOnly}
            />
          ))}
        </div>
      </div>
    </AsideValidation>
  );
};

export default AsideTeaserImportance;

AsideTeaserImportance.displayName = 'AsideTeaserImportance';

AsideTeaserImportance.propTypes = {
  config: any,
};
