import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { llinkidDefaultBuildingBlocks } from './llinkidGenericConfigProps';

const LLINKID_BASIC_MATERIAL_REQUIREMENTS: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  information: {
    single: 'Basisuitrusting',
    plural: 'Basisuitrusting',
  },

  buildingBlocks: llinkidDefaultBuildingBlocks,
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,

  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_BASIC_MATERIAL_REQUIREMENTS;
