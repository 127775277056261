import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectAttachment } from '@newStore/documentUI/transformContent/attachmentSelectors';
import { selectNonFirstAttachmentInDownloadWarning } from '@newStore/validation/validationRules/nonFirstAttachmentDownloadWarning';
import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import {
  selectAttachmentReplacementNameRule,
  selectChangeMessageForFile,
} from '@UI/aside/asideFileSelector/asideFileSelectors';

const ATTACHMENT: BuildingBlockNodeConfig = {
  nodeSelector: selectAttachment,
  information: {
    single: 'Bijlage',
    plural: 'Bijlagen',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  validationRules: [selectNonFirstAttachmentInDownloadWarning],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      required: RequiredType.ERROR,
      label: 'Titel',
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
    },
    authorsComponent,
    {
      component: 'file',
      reactComponent: 'AsideFile',
      label: 'Bestand',
      definiteArticle: true,
      property: 'attachments',
      asideChangeMessageSelector: selectChangeMessageForFile,
      validations: [selectAttachmentReplacementNameRule],
      required: RequiredType.ERROR,
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
        type: 'ATTACHMENT',
      },
    },
  ],
  onNewNodeDropped: {
    openAside: true,
  },
  isCollapsible: false,
};

export default ATTACHMENT;
