import { AudienceTabComponent } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForNamedSets } from '@UI/aside/asideNamedSet/asideNamedSetSelectors';
import { proThemeAudienceTab } from './proThemeAudienceTab';

export const proNewsletterAudienceTab: Array<AudienceTabComponent> = [
  ...proThemeAudienceTab,
  {
    component: 'namedSet',
    reactComponent: 'AsideNamedSet',
    property: 'positions',
    label: 'Functies (doelgroep)',
    subLabel: 'Voor wie is deze inhoud bestemd?',
    labelSingular: 'doelgroep',
    tag: 'doelgroepen',
    isInheritedProperty: true,
    asideChangeMessageSelector: selectChangeMessageForNamedSets,
  },
];
