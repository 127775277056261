import {
  AttachmentType,
  ContentHref,
  ContentKey,
  ContentType,
  LlinkidGoalType,
  ProposalStatus,
  RedactieHref,
} from '@generalTypes/apiTypes';
import { EditComponentProperty, NodeType } from '@nodeTypeConfig/configTypes';

type IllustrationForNode = {
  url: string;
} | null;

type BasicNode = {
  href: ContentHref;
  key: ContentKey;
  type: NodeType;
};

export type BasicNodeWithImage = BasicNode & {
  image: IllustrationForNode;
};

export type ParagraphNode = BasicNodeWithImage & {
  text: string;
  type: typeof NodeType.PARAGRAPH;
  isStaticHtml: boolean;
};

export type TermNode = BasicNodeWithImage & {
  title: string;
  type: typeof NodeType.TERM;
  description: string;
};

export type TitleNode = BasicNode & {
  title: string;
};
export type TitleWithDescriptionNode = BasicNode & {
  title: string;
  description: string;
};

export type LinkedContent = {
  callToAction: string;
  title: string;
  documentKey?: string | undefined;
  url?: string;
};

export type TeaserNode = BasicNode & {
  title: string;
  description: string;
  type: typeof NodeType.TEASER;
  image: IllustrationForNode;
  customRender: true;
  linkedContent: LinkedContent | null;
  linkedContentLoading: boolean;
};

export type ProNewsItemNode = BasicNode & {
  title: string;
  type: typeof NodeType.PRONEWSITEM;
  linkedTeasers: Array<{
    title: string;
    key: string;
  }>;
  customRender: true;
  arePendingActions: boolean;
  resourcesLoading: boolean;
};

export type TempFallbackNode = BasicNode & {
  title: string | undefined;
  isFallback: true;
};

export type ZillCurriculumNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL;
  title: string;
  description: string;
};

export type ZillClusterNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL_CLUSTER;
  identifier: string;
  title: string;
  description: string;
};

export type ZillDevelopmentFieldNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD;
  title: string;
  identifier: string;
  color: string | undefined;
  description: string;
  text: string;
  icon: {
    url: string;
  } | null;
};

export type ZillDevelopmentThemeNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL_DEVELOPMENT_THEME;
  title: string;
  identifier: string;
  color: string | undefined;
  description: string;
  text: string;
};

export type ZillGenericGoalNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL_GENERIC_GOAL;
  title: string;
  identifier: string;
  color: string | undefined;
};

export type ZillDevelopmentContentNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT;
  title: string;
};

export type ZillLeerlijnStepNode = BasicNode & {
  type: typeof NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER;
  description: string;
  ageRangeLabel: string;
  startAge: number;
  endAge: number;
  customRender: true;
};

export type ZillGenericGoalReference = {
  href: string;
  identifier: string;
  color: string | undefined;
  title: string;
};
export type ZillLeerlijnReferenceStepNode = BasicNode & {
  type:
    | typeof NodeType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE
    | typeof NodeType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE;
  genericGoal: ZillGenericGoalReference | null;
  customRender: true;
};

export type OdetNode = BasicNode & {
  title: string;
  identifier: string;
  type:
    | typeof NodeType.CURRICULUM_ODET_LEERGEBIED
    | typeof NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL
    | typeof NodeType.CURRICULUM_ODET_ENDTERM;
};

export type OdetSubSectionNode = BasicNode & {
  title: string;
  identifier: string | undefined;
  type: typeof NodeType.CURRICULUM_ODET_SUBSECTION;
};

export type OdetTitleNode = BasicNode & {
  title: string;
  type: typeof NodeType.CURRICULUM_ODET | typeof NodeType.CURRICULUM_ODET_LEVEL;
};

/**
 * this type is used for the /sam/commons nodes that we use in the referenceframe
 */
export type ExternalCommonsNode = BasicNode & {
  title: string;
};

export type ThemeItemNode = BasicNode & {
  title: string;
  type: typeof NodeType.THEME;
  color: string | undefined;
  icon: {
    url: string;
  } | null;
};

export type ProMenuLevel2Node = BasicNode & {
  title: string;
  type: typeof NodeType.PRO_MENU_LEVEL_2;
  color: string | undefined;
  icon: {
    url: string;
  } | null;
};

export type VideoNode = BasicNode & {
  videoSrc: string | undefined;
  type: typeof NodeType.VIDEO;
  customRender: true;
};

export type LlinkidCurriculumNode = BasicNode & {
  type: typeof NodeType.LLINKID_CURRICULUM;
  identifier: string | undefined;
  title: string;
  version: string;
  educationalGroups: Array<{
    key: ContentKey;
    label: string;
    themes: Array<{
      key: ContentKey;
      title: string;
      imgSrc: string;
      imgSize: string;
    }>;
  }>;
  customRender: true;
};

export type LlinkidGoalSubItemNode = BasicNode & {
  description: string;
  type:
    | typeof NodeType.LLINKID_PEDAGOGICAL_TIP
    | typeof NodeType.LLINKID_EXTRA_GOAL_INFORMATION
    | typeof NodeType.LLINKID_GOAL_EXPLANATION
    | typeof NodeType.LLINKID_INITIAL_SITUATION
    | typeof NodeType.LLINKID_CONCRETIZATION;
  name: string;
  customRender: true;
  isNew?: boolean;
};

export type AttachmentNode = BasicNode & {
  attachmentKey: string;
  title: string | undefined;
  description: string | undefined;
  fileTypeIconSrc: string;
  createDate: string;
  fileType: string;
  fileTag: string;
  fileSize: string;
  type: typeof NodeType.ATTACHMENT;
  customRender: true;
};
export type AttachmentsGroupNode = BasicNode & {
  title: string;
  subTitle: string;
  customRender: true;
  type: typeof NodeType.ATTACHMENTS_GROUP;
};

export type ImageNode = BasicNode & {
  src: string | undefined;
  type: typeof NodeType.IMAGE;
  customRender: true;
};

export type GlobalDocumentNode = BasicNode & {
  title: string | undefined;
  attachmentName: string | undefined;
  downloadUrl: string | undefined;
  embedUrl: string | undefined;
  isPdf: boolean;
  previewPages: Array<{
    key: string;
    src: string | undefined;
  }>;
  type: typeof NodeType.GLOBAL_DOCUMENT;
  customRender: true;
  /** the items below are for the attachment render */
  description: string | undefined;
  fileTypeIconSrc: string;
  createDate: string;
  fileType: string;
  fileTag: string;
  fileSize: string;
};

export type ReferenceGroupReference = {
  href: string;
  title: string;
  identifier: string | undefined;
  color: string | undefined;
  isDeletedByProposal: boolean;
};

export type ReferenceGroupNode = BasicNode & {
  description: string | undefined;
  type: typeof NodeType.REFERENCE_GROUP;
  customRender: true;
  references: Array<ReferenceGroupReference>;
};

export type MusicNode = BasicNode & {
  title: string;
  mp3src: string | undefined;
  type: typeof NodeType.MUSIC;
  customRender: true;
};

export type TableOfContentNode = {
  title: string;
  color?: string | undefined;
  identifier?: string;
  placeholder: boolean;
};

export type PlaceholderNode = BasicNode & {
  placeholder: string;
};

export type LegalNode = BasicNode & {
  text: string;
  title: string;
  type: typeof NodeType.LEGAL;
  customRender: true;
};

export type LlinkidGoalNode = BasicNode & {
  description: string;
  type: typeof NodeType.LLINKID_GOAL;
  attitudinal: boolean;
  llinkidGoalType: LlinkidGoalType | null;
  identifier: string;
  mandatory: boolean;
  customRender: true;
};

export type QuoteNode = BasicNode & {
  text: string;
  type: typeof NodeType.QUOTE;
  customRender: true;
};

export type DescriptionNode = BasicNode & {
  description: string;
};

export type OdetKeyCompetenceNode = DescriptionNode & {
  identifier: string;
  type: NodeType.CURRICULUM_ODET_KEY_COMPETENCE;
};

export type BuildingBlockTile = {
  type: NodeType;
  icon: string;
  name: string;
};

export type GlossaryNode = {
  href: ContentHref;
  key: ContentKey;
  title: string;
  description: string;
  redactieLink: RedactieHref;
};

export type ContentNode =
  | TitleNode
  | TitleWithDescriptionNode
  | VideoNode
  | ProNewsItemNode
  | TeaserNode
  | ZillCurriculumNode
  | ZillClusterNode
  | ZillDevelopmentFieldNode
  | ZillDevelopmentThemeNode
  | ZillGenericGoalNode
  | ZillDevelopmentContentNode
  | ZillLeerlijnStepNode
  | ZillLeerlijnReferenceStepNode
  | OdetKeyCompetenceNode
  | OdetNode
  | OdetTitleNode
  | OdetSubSectionNode
  | LlinkidCurriculumNode
  | LlinkidGoalSubItemNode
  | ProMenuLevel2Node
  | ExternalCommonsNode
  | TempFallbackNode
  | QuoteNode
  | AttachmentsGroupNode
  | AttachmentNode
  | ImageNode
  | GlobalDocumentNode
  | LegalNode
  | ReferenceGroupNode
  | MusicNode
  | PlaceholderNode
  | DescriptionNode
  | LlinkidGoalNode
  | ParagraphNode
  | TermNode;

// export type ContentNodeWithRelation = ContentNode & {
//   /**
//    * the relation href of the relation to the parent
//    */
//   relationHref: ContentRelationHref;
//   /**
//    * the relation key of the relation to the parent
//    */
//   relationKey: ContentRelationKey;
// };

export const isFallbackNode = (c: ContentNode): c is TempFallbackNode => 'isFallback' in c;

export const isLlinkidGoalSubItemNode = (c: ContentNode): c is LlinkidGoalSubItemNode =>
  c.type === NodeType.LLINKID_PEDAGOGICAL_TIP ||
  c.type === NodeType.LLINKID_EXTRA_GOAL_INFORMATION ||
  c.type === NodeType.LLINKID_GOAL_EXPLANATION ||
  c.type === NodeType.LLINKID_INITIAL_SITUATION;

export type DiffMessage = {
  property?: EditComponentProperty;
  message: string;
  attachmentType?: AttachmentType;
  hiddenChildKey?: ContentType;
  subItems?: Array<string>;
};

export type ProposalType = 'CREATE' | 'UPDATE' | 'DELETE' | 'RELOCATE' | 'CHILDREN_HAVE_PROPOSALS';

export type ProposalMetaInfo = {
  href: string | null;
  type: ProposalType;
  status: ProposalStatus;
  childProposalsMessage: DiffMessage | null;
  authors: Array<{
    href: string;
    firstName: string;
    lastName: string;
  }>;
  authorsDescription: string;
  userIsAuthor: boolean;
  submittedOn: string | null;
};
