import { AttachmentNode, GlobalDocumentNode } from '@newStore/documentUI/documentUITypes';

const AttachmentRow: React.FC<{ contentNode: AttachmentNode | GlobalDocumentNode }> = ({
  contentNode,
}) => (
  <div key={contentNode.key}>
    <div className={`nopaddings row`} style={{ margin: '5px 5px', padding: 0 }}>
      <div className="col-sm-5">
        <div className="text-ellipsis">
          <img src={contentNode.fileTypeIconSrc} width="20" />
          <span>{contentNode.title}</span>
        </div>
        {contentNode.description && (
          <span
            style={{ fontSize: '10px', marginLeft: '25px' }}
            dangerouslySetInnerHTML={{ __html: contentNode.description }}
          />
        )}
      </div>

      <div className="col-sm-2 text-ellipsis">
        <span>{contentNode.createDate}</span>
      </div>

      <div className="col-sm-2 text-ellipsis">
        <span>{contentNode.fileType}</span>
      </div>

      <div className="col-sm-1 text-ellipsis">
        <span>{contentNode.fileSize}</span>
      </div>
    </div>
  </div>
);

export default AttachmentRow;
