import { RootState } from '@generalTypes/rootStateTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { DropDownOptionsType, EditAsideAttachmentGroupType } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { selectCurrentAttachmentGroupType } from './asideAttachmentGroupSelectors';

import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideAttachmentGroupType: React.FC<{
  config: EditAsideAttachmentGroupType;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const readOnly = useReadOnly();

  const selectedOption: DropDownOptionsType = useSelector((state: RootState) =>
    selectCurrentAttachmentGroupType(state, config)
  );

  const onChange = (value: DropDownOptionsType) => {
    const importance = value.key;

    dispatch(patchNodeAction(getResourceKey(editHref), { importance }));
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <div style={{ maxWidth: '400px' }}>
        <label className="control-label">{config.label}</label>
        {config.subLabel && <label className="subtitle-label">{config.subLabel}</label>}

        <KovResourcePicker
          value={selectedOption}
          localData={config.options}
          disabled={readOnly}
          clearable={false}
          onChange={onChange}
          menuPlacement="bottom"
          disableSearch={true}
          optionValue={(o: DropDownOptionsType) => o.key}
          optionTemplate={(o: DropDownOptionsType) => o.label}
        />
      </div>
    </AsideValidation>
  );
};

export default AsideAttachmentGroupType;

AsideAttachmentGroupType.displayName = 'AsideAttachmentGroupType';

AsideAttachmentGroupType.propTypes = {
  config: any,
};
