import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectDocumentRootType,
  selectTypeNameSingle,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { Content, ContentHref } from '@generalTypes/apiTypes';
import { createError } from '../createError';
import { ValidationRule } from '../validationTypes';

// If a element is included in an content node via a IS_INCLUDED_IN relation, the intersection
// of that element targetaudience and the content node targetaudience should not be empty.
// This can happen with global documents in attachment groups, both in the main view or as hidden
// elements in the aside, and also in teasers included in newsletter. See monday 112131159

const properties = ['mainstructuresOuTypeCombinations', 'coverage'] as const;

const errorMessages = {
  mainstructuresOuTypeCombinations: 'Onderwijsniveaus en instellingstypes',
  coverage: 'regio',
};

const intersectionEmpty = (a, b) => !a || !b || b.filter((x) => a.includes(x)).length === 0;

export const selectValidateTeaserAudienceParentIntersectionNotEmpty: ValidationRule =
  createTypedSelector(
    [
      (state) => selectDocumentRootType(state),
      (state, href: ContentHref) => selectContentItem(state, href),
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href, parentHref) => parentHref,
      (state, href, parentHref) => parentHref && selectTypeNameSingle(state, parentHref),
    ],
    (
      rootType,
      content: Content | undefined,
      relationsMap,
      contentMap,
      parentHref,
      parentTypeNameSingle
    ) => {
      if (rootType === NodeType.TEASER) {
        return true;
      }

      const pathToRoot = getPathToRoot(relationsMap, contentMap, parentHref);

      for (const propertyChecked of properties) {
        const ancestorWithAudience = pathToRoot.find((ancestor) => ancestor[propertyChecked]);
        if (
          ancestorWithAudience &&
          content &&
          intersectionEmpty(ancestorWithAudience[propertyChecked], content[propertyChecked])
        ) {
          return createError(
            `audienceIntersectionWithParentEmpty.${propertyChecked}`,
            'getAudienceIntersectionWithParentEmpty',
            `Een teaser moet minstens 1 <strong>${errorMessages[propertyChecked]}</strong> gemeenschappelijk hebben met de ${parentTypeNameSingle} waarin het is opgenomen`,
            { property: propertyChecked, component: 'namedSet' },
            RequiredType.WARNING
          );
        }
      }
      return true;
    }
  );

export const selectValidateGlobalDocAudienceParentIntersectionNotEmpty: ValidationRule =
  createTypedSelector(
    [
      (state) => selectDocumentRootType(state),
      (state, href: ContentHref) => selectContentItem(state, href),
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href, parentHref) => parentHref,
      (state, href, parentHref) => parentHref && selectTypeNameSingle(state, parentHref),
    ],
    (
      rootType,
      content: Content | undefined,
      relationsMap,
      contentMap,
      parentHref,
      parentTypeNameSingle
    ) => {
      if (rootType === NodeType.GLOBAL_DOCUMENT) {
        return true;
      }

      const pathToRoot = getPathToRoot(relationsMap, contentMap, parentHref);

      for (const propertyChecked of properties) {
        const ancestorWithAudience = pathToRoot.find((ancestor) => ancestor[propertyChecked]);
        if (
          ancestorWithAudience &&
          content &&
          intersectionEmpty(ancestorWithAudience[propertyChecked], content[propertyChecked])
        ) {
          return createError(
            `audienceIntersectionWithParentEmpty.${propertyChecked}`,
            'getAudienceIntersectionWithParentEmpty',
            `Een gedeeld document moet minstens 1 <strong>${errorMessages[propertyChecked]}</strong> gemeenschappelijk hebben met de ${parentTypeNameSingle} waarin het is opgenomen`,
            { property: propertyChecked, component: 'namedSet' },
            RequiredType.ERROR
          );
        }
      }
      return true;
    }
  );
