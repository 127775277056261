import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { EditAsideSources } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import ModalErrorBoundary from '@UI/modals/ModalErrorBoundary';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { AsideSourceItem } from './AsideSourceItem';
import { selectRelationsToSources } from './asideSourcesSelectors';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import SourceModal from './SourceModal';

const AsideSources: React.FC<{
  config: EditAsideSources;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const currentEditingHref = useEditHref();
  const readOnly = useReadOnly();

  const relations = useSelector(selectRelationsToSources);

  const confirmationService = getAngularService('ConfirmationService');
  const sourceModalRef = useRef<Modal>();

  const onDelete = useCallback(
    async (item) => {
      const confirmed = await confirmationService.confirmDeletion();
      if (confirmed) {
        dispatch(DOCUMENT_ACTIONS.removeNodeAction(getResourceKey(item.from.href)));
      }
    },
    [confirmationService, dispatch]
  );

  const onCreate = () => {
    sourceModalRef.current?.open({
      onSubmit: (source) => {
        if (source) {
          dispatch(
            DOCUMENT_ACTIONS.addNodeToParentNodeAction(
              getResourceKey(currentEditingHref),
              1,
              ContentType.SOURCE,
              {
                title: source.title,
              }
            )
          );
        }
      },
    });
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <>
        <AsideRelationsPanel
          title={config.label}
          items={relations}
          readOnly={readOnly}
          onCreate={onCreate}
          onDelete={onDelete}
        >
          {(item) => <AsideSourceItem href={item.from.href as ContentHref} />}
        </AsideRelationsPanel>
        <Modal
          ref={sourceModalRef}
          size="sm"
          component={<SourceModal />}
          errorBoundaryComponent={ModalErrorBoundary}
        />
      </>
    </AsideValidation>
  );
};

AsideSources.displayName = 'AsideSources';

AsideSources.propTypes = {
  config: any,
};

export default AsideSources;
