import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { selectCurrentDocumentHref } from '@newStore/documentUI/documentUISelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { selectExternalContent } from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { getPracticalExampleRelations } from '@newStore/zill/zillHelpers';
import { selectPracticalExampleRelationsForZillIllustration } from '@newStore/zill/zillSelectors';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

export const selectPracticalExampleSelectedItemForZillIllustration = createTypedSelector(
  [
    (state) => selectPracticalExampleRelationsForZillIllustration(state),
    (state) => selectExternalContent(state),
  ],
  (practicalExampleHrefs, externalContent) => {
    const relation = practicalExampleHrefs.find((rel) => {
      const content = externalContent[rel.to.href];
      return content && content.tags.includes('PRACTICAL_EXAMPLE');
    });

    if (!relation) {
      return null;
    }
    return {
      relationHref: relation.$$meta.permalink,
      $$expanded: externalContent[relation.to.href],
    };
  }
);

export const selectChangeMessageForPracticalExampleReferences: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectCurrentDocumentHref(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectExternalContent(state),
    ],
    (documentRootHref, rawRelationsMap, relationsMap, externalContent): string | null => {
      const newRelation = getPracticalExampleRelations(documentRootHref, relationsMap);
      const oldRelation = getPracticalExampleRelations(documentRootHref, rawRelationsMap);

      const newValue =
        (newRelation[0]?.to.href && externalContent[newRelation[0].to.href]?.title) || null;
      const oldValue =
        (oldRelation[0]?.to.href && externalContent[oldRelation[0].to.href]?.title) || null;
      return stringDiffArrowFunction(newValue, oldValue);
    }
  );
