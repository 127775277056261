import React, { useState } from 'react';

import { Modal } from '@kathondvla/react-shared-components/src/components';

import { useDispatch } from 'react-redux';
import { closeAddGlobalDocumentModal } from '@newStore/documentUI/documentUIState';
import { addGlobalDocumentRelationAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { Content } from '@generalTypes/apiTypes';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { GlobalDocumentDropdown } from './GlobalDocumentDropdown';
import './modals.scss';

const GlobalDocumentInsertModal: React.FC<{
  modalTitle?: string;
}> = ({ modalTitle = '' }) => {
  const [selectedGlobalDocument, setSelectedGlobalDocument] = useState<Content | null>(null);

  const dispatch = useDispatch();

  const modalData = useTypedSelector((state) => state.documentUI.addGlobalDocumentModalData.data);

  const handleSubmit = () => {
    if (selectedGlobalDocument && modalData) {
      dispatch(
        addGlobalDocumentRelationAction(
          getResourceKey(modalData.parentHref),
          modalData.positionIndex,
          selectedGlobalDocument
        )
      );
    }

    dispatch(closeAddGlobalDocumentModal());
  };

  const onClose = () => {
    dispatch(closeAddGlobalDocumentModal());
  };

  return (
    <div className="overflown-modal">
      <Modal.Header title={modalTitle || 'Voeg een bijlage toe'} onClose={onClose} />
      <Modal.Body>
        <div className="form-group">
          <GlobalDocumentDropdown
            onChange={setSelectedGlobalDocument}
            selectedGlobalDocument={selectedGlobalDocument}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-link pull-left" onClick={onClose}>
          Annuleren
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Ok
        </button>
      </Modal.Footer>
    </div>
  );
};

GlobalDocumentInsertModal.displayName = 'GlobalDocumentInsertModal';

export default GlobalDocumentInsertModal;
