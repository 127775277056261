import React from 'react';

import {
  AsyncKovResourcePicker,
  useLoadSriOptions,
} from '@kathondvla/react-shared-components/src/components';

import { contentApi } from '@store/api/apiConfig';
import {
  getAttachmentFileSize,
  getFileExtension,
  getFileTag,
} from '@newStore/documentUI/transformContent/attachmentHelpers';
import { stripHtml } from '@newStore/genericHelpers';
import { Content } from '@generalTypes/apiTypes';

const DisplayName = ({ item }) => {
  if (!item) {
    return 'Selecteer een item';
  }
  const file = item.attachments.find((a) => a.type === 'CONTENT');
  const description = stripHtml(item.description);
  return `${stripHtml(item.title)} (${getFileExtension(file)}), ${getFileTag(
    file
  )}, ${getAttachmentFileSize(file)}${description.length ? ` - ${description}` : ''}`;
};

export const GlobalDocumentDropdown: React.FC<{
  selectedGlobalDocument: Content | null;
  onChange: (value: Content) => void;
}> = ({ selectedGlobalDocument, onChange }) => {
  const sriLoadOptionsFn = useLoadSriOptions({
    sriClient: contentApi,
    href: '/content',
    parameters: {
      type: 'UNSTRUCTURED_DOCUMENT',
    },
  });

  return (
    <div className="form-group" style={{ marginBottom: '20px' }}>
      <AsyncKovResourcePicker
        value={selectedGlobalDocument}
        loadOptions={sriLoadOptionsFn}
        clearable={false}
        disabled={false}
        onChange={(value) => onChange(value.$$expanded)}
        placeholder="Zoek een gedeeld document"
        multiple={false}
        menuPlacement="bottom"
        optionValue={(o) => o.key}
        optionTemplate={(o) => <DisplayName item={o?.$$expanded || o} />}
      />
      {!selectedGlobalDocument && (
        <div className={`error-message type-warning`}>
          <div className="error-message-content">
            <span>Selecteer een gedeeld document</span>
          </div>
        </div>
      )}
    </div>
  );
};
