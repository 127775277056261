import {
  AttachmentAttachmentMetaInfo,
  Content,
  isAttachmentAttachmentMetaInfo,
} from '@generalTypes/apiTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { GlobalDocumentNode } from '../documentUITypes';
import { settings } from '../../../config/settings';
import {
  getAttachmentCreateDate,
  getAttachmentFileSize,
  getFileTag,
  getFileType,
  getIconSrc,
} from './attachmentHelpers';

export const selectGlobalDocumentNode = createTypedSelector(
  [(state, href) => selectContentItem(state, href)],
  (content: Content): GlobalDocumentNode => {
    const contentAttachment = content.attachments.find(
      isAttachmentAttachmentMetaInfo
    ) as AttachmentAttachmentMetaInfo;

    const previewPages = content.attachments
      .filter((attachment) => attachment.type === 'CONTENT_PREVIEW')
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map((attachment) => ({
        key: attachment.key,
        src: settings.apisAndUrls.contentApi + attachment.href,
      }));

    return {
      href: content.$$meta.permalink,
      key: content.key,
      title: content.title,
      attachmentName: contentAttachment?.name,
      downloadUrl:
        contentAttachment?.href &&
        `${settings.apisAndUrls.proWebsite}/download${contentAttachment.href}`,
      embedUrl: contentAttachment?.href && settings.apisAndUrls.contentApi + contentAttachment.href,
      isPdf: contentAttachment?.contentType === 'application/pdf',
      previewPages,
      type: NodeType.GLOBAL_DOCUMENT,
      customRender: true,
      description: content.description,
      fileTypeIconSrc: getIconSrc(contentAttachment),
      createDate: getAttachmentCreateDate(contentAttachment),
      fileType: getFileType(contentAttachment),
      fileTag: getFileTag(content),
      fileSize: getAttachmentFileSize(contentAttachment),
    };
  }
);
