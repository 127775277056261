import { Content, ContentHref, RequiresRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { selectAllExternalData } from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { selectReferenceFrameContent } from '@newStore/referenceFrames/referenceFramesSelectors';
import {
  AsideChangeMessageSelector,
  EditAsideReferenceFrameReferences,
  EditComponent,
} from '@nodeTypeConfig/configTypes';
import { relationStrengthTranslations } from '../../../../reduxLoop/constants/relationStrengthTranslations';

const getRelations = (
  relationsMap,
  config,
  href,
  referenceFrameContent,
  externalData
): [{ content: Content; relation: RequiresRelation }] => {
  const [relationOrigin, relationEnd] = !config.options.revertedRelationDirection
    ? ['from', 'to']
    : ['to', 'from'];
  const allRelations = relationsMap[relationOrigin][href];
  const relations = allRelations
    .filter(
      (relation) =>
        config.options.relationTypes.some((z) => z === relation.relationtype) &&
        referenceFrameContent[relation[relationEnd].href]
    )
    .map((item) => ({ content: externalData[item[relationEnd].href], relation: item }));
  return relations;
};

const selectReferenceFramesOldValues = createTypedSelector(
  [
    (state) => selectRawApiRelationsToAndFromMap(state),
    (state, href: ContentHref, config) =>
      selectReferenceFrameContent(state, config.options.referenceFrame),
    (state, href: ContentHref, config: EditAsideReferenceFrameReferences) => [href, config],
    (state) => selectAllExternalData(state),
  ],
  (
    rawRelationsMap,
    referenceFrameContent,
    params,
    externalData
  ): [{ content: Content; relation: RequiresRelation }] => {
    const [href, config] = params;
    return getRelations(rawRelationsMap, config, href, referenceFrameContent, externalData);
  }
);

const selectReferenceFramesNewValues = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state, href: ContentHref, config) =>
      selectReferenceFrameContent(state, config.options.referenceFrame),
    (state, href: ContentHref, config: EditAsideReferenceFrameReferences) => [href, config],
    (state) => selectAllExternalData(state),
  ],
  (
    relationsMap,
    referenceFrameContent,
    params,
    externalData
  ): [{ content: Content; relation: RequiresRelation }] => {
    const [href, config] = params;
    return getRelations(relationsMap, config, href, referenceFrameContent, externalData);
  }
);

export const selectChangeMessageForEducationalPointers: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref, config) => selectReferenceFramesOldValues(state, href, config),
      (state, href: ContentHref, config) => selectReferenceFramesNewValues(state, href, config),
    ],
    (referenceFramesOldValues, referenceFramesNewValues): string | null => {
      const oldValues = referenceFramesOldValues.map(({ content }) => content.title ?? '');
      const newValues = referenceFramesNewValues.map(({ content }) => content.title ?? '');
      return getDiffForArraysOfStrings(oldValues, newValues);
    }
  );

export const selectChangeMessageForEducationalComponents: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref, config) => selectReferenceFramesOldValues(state, href, config),
      (state, href: ContentHref, config) => selectReferenceFramesNewValues(state, href, config),
    ],
    (referenceFramesOldValues, referenceFramesNewValues): string | null => {
      const oldValues = referenceFramesOldValues.map(
        ({ content, relation }) =>
          `${content.title} (${relationStrengthTranslations[relation.strength].toLowerCase()})`
      );
      const newValues = referenceFramesNewValues.map(
        ({ content, relation }) =>
          `${content.title} (${relationStrengthTranslations[relation.strength].toLowerCase()})`
      );
      return getDiffForArraysOfStrings(oldValues, newValues);
    }
  );
