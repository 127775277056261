import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { referencedInProHomeCannotUnpublish } from '@newStore/validation/validationRules/referencedInProHomeCannotUnpublish';
import { validateWebTemplateConfig } from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { ValidationRule, AsyncValidationRule } from '@newStore/validation/validationTypes';
import { NodeType } from '@nodeTypeConfig/configTypes';

export const proThemeDefaultValidationRules: Array<ValidationRule | AsyncValidationRule> = [
  validateWebTemplateConfig,
  referencedInProHomeCannotUnpublish,
  selectCurriculumThemesValidation,
];
export const proTileDefaultValidionRules = [validateWebTemplateConfig];

export const allProRootNodeTypes = [
  NodeType.WEBPAGE2,
  NodeType.PRO_THEME_HOME_FULL,
  NodeType.PRO_THEME_HOME_PICTURE,
  NodeType.PRO_THEME_HOME_TEXT,
  NodeType.PRO_TEXT_PAGE_STANDALONE,
  NodeType.PRO_BLOG_STANDALONE,
  NodeType.PRO_DATABASE_STANDALONE,
  NodeType.PRO_GLOBAL_DATABASE,
  NodeType.PRO_DOWNLOAD_PAGE_STANDALONE,
  NodeType.PRO_STATIC_PAGE,
  NodeType.PRO_TEMPORARY_PAGE_STANDALONE,
];
