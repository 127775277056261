import { extractContentAndRelations } from '@newStore/documentApi/documentApiHelpers';
import { addExtraInternalData } from '@newStore/documentApi/documentApiState';
import {
  addResourceAncestorsToLoad,
  addResourcesToLoad,
} from '@newStore/externalData/externalDataState';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getAllHrefs } from '@newStore/documentApi/documentApiDataAccess';
import { selectExternalContent } from '@newStore/externalData/externalDataSelectors';
import {
  addRelationAction,
  addZillIllustrationAction,
  expandZillGoalSelectionsAction,
  removeRelationAction,
  removeZillIllustrationRelationAction,
} from '@store/actions/documentActions';
import { ContentHref, ContentKey, ContentWith$$Relations } from '@generalTypes/apiTypes';
import { waitFor } from '@store/helpers/sagaUtils';
import { selectApiWithPendingChanges } from '@newStore/documentApi/documentApiSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { getResourceKey } from '@store/helpers/documentHelpers';
import {
  selectPracticalExampleHrefsForZillIllustration,
  selectAllZillIllustrationGoalHrefsToLoad,
  selectZillIllustrationsHrefsForZillPracticalExample,
  selectZillIllustrationRelationsForPracticalExample,
  selectZillGoalByHref,
} from './zillSelectors';
import {
  isLoadedZillGoal,
  isZillGoalSubDetail,
  SelectedZillGoal,
  ZillCurriculumSelection,
  ZillGoalSubDetail,
} from './zillTypes';
import { groupSubDetailsByGoal } from './zillHelpers';

function* loadZillGoalsForZillIllustrationSaga() {
  const goalHrefsToLoad: ReturnType<typeof selectAllZillIllustrationGoalHrefsToLoad> = yield select(
    selectAllZillIllustrationGoalHrefsToLoad
  );
  if (goalHrefsToLoad) {
    yield put(addResourceAncestorsToLoad({ resources: goalHrefsToLoad }));
  }
}

function* loadIllustrationsWithGoalsReferencingPracticalExampleSaga() {
  yield call(
    waitFor,
    (state: RootState) => Object.keys(selectApiWithPendingChanges(state).content).length > 0
  );
  const illustrationHrefsToLoad: ReturnType<
    typeof selectZillIllustrationRelationsForPracticalExample
  > = yield select(selectZillIllustrationsHrefsForZillPracticalExample);
  if (illustrationHrefsToLoad.length > 0) {
    const apiIllustrations: ContentWith$$Relations[] = yield call(
      getAllHrefs,
      illustrationHrefsToLoad
    );
    const { content: illustrations, relations } = extractContentAndRelations(apiIllustrations);
    const goalRelations = relations.filter(
      (rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW'
    );

    yield put(addExtraInternalData({ content: illustrations, relations: goalRelations }));

    yield loadZillGoalsForZillIllustrationSaga();
  }
}

function* loadPracticalExampleForZillIllustrationSaga() {
  const practicalExampleHrefs: string[] = yield select(
    selectPracticalExampleHrefsForZillIllustration
  );
  const externalContent: ReturnType<typeof selectExternalContent> = yield select(
    selectExternalContent
  );
  const practicalExampleHrefsToLoad = practicalExampleHrefs.filter(
    (href) => !externalContent[href]
  );

  if (practicalExampleHrefsToLoad.length > 0) {
    yield put(addResourcesToLoad({ resources: practicalExampleHrefsToLoad }));
  }
}

function* addSubDetailsToGoalOfExistingIllustrationSaga({
  payload,
}: PayloadAction<{ subDetail: ZillGoalSubDetail; existingGoal: SelectedZillGoal }[]>) {
  for (const { subDetail, existingGoal } of payload) {
    yield put(
      addRelationAction({
        relationtype: 'REFERENCES',
        from: {
          href: existingGoal.illustration.href,
        },
        to: {
          href: subDetail.href,
        },
        strength: 'LOW',
      })
    );
  }
}

function* processNewSelectedIllustrationsSaga({
  payload,
}: PayloadAction<{
  selectedGoals: ContentHref[];
  currentSelections: ZillCurriculumSelection[];
  genericGoals: SelectedZillGoal[];
  editKey: ContentKey;
}>) {
  const { selectedGoals, currentSelections, genericGoals, editKey } = payload;
  const deletedSelections = currentSelections.filter((cs) => !selectedGoals.includes(cs.href));

  // If a selection is deleted we need to make the distinction between: is an entire goal deleted and with it the illustration, or is just a subDetail removed
  const deletedSubDetails = deletedSelections.filter((sel) => sel.type === 'ZILL_GOAL_SUB_DETAIL');
  const subDetailsForWhichEntireGoalIsDeleted = deletedSubDetails.filter((subDetail) =>
    subDetail.zillGoal.subDetails.every((subDetailOfGoal) =>
      deletedSubDetails.some((deletedSubDetail) => subDetailOfGoal.href === deletedSubDetail.href)
    )
  );
  const removedSubDetails = deletedSubDetails.filter(
    (subDetail) =>
      !subDetailsForWhichEntireGoalIsDeleted.some(
        (subDetailForWhichEntireGoalIsDeleted) =>
          subDetailForWhichEntireGoalIsDeleted.href === subDetail.href
      )
  );
  if (removedSubDetails.length) {
    console.log('removedSubDetails', removedSubDetails);
  }
  // removedSubDetails.forEach((subDetail) => {
  for (const subDetail of removedSubDetails) {
    if (subDetail.relationFromIllustrationHref) {
      yield put(removeRelationAction(getResourceKey(subDetail.relationFromIllustrationHref)));
    }
  }

  const deletedGoals: ZillCurriculumSelection[] = [
    ...deletedSelections.filter((sel) => sel.type === 'ZILL_GOAL'),
    ...(Object.values(
      subDetailsForWhichEntireGoalIsDeleted.reduce(
        (goalMap, subDetail) => ({ ...goalMap, [subDetail.zillGoal.href]: subDetail }),
        {}
      )
    ) as ZillCurriculumSelection[]),
  ];
  console.log('deleted goals', deletedGoals);

  for (const deletedSelection of deletedGoals) {
    yield put(
      removeZillIllustrationRelationAction(
        getResourceKey(deletedSelection.zillGoal.illustration.href),
        getResourceKey(deletedSelection.zillGoal.illustration.relationToPracticalExampleHref)
      )
    );
  }

  const newSelectionHrefs = selectedGoals.filter(
    (href) => !currentSelections.some((sel) => sel.href === href)
  );
  if (newSelectionHrefs.length) {
    yield put(expandZillGoalSelectionsAction(newSelectionHrefs));
    yield put(addResourceAncestorsToLoad({ resources: newSelectionHrefs }));

    yield call(waitFor, (state) => {
      const newSelections = newSelectionHrefs.map((href) => selectZillGoalByHref(state, href));
      return newSelections.every((item) => isLoadedZillGoal(item) || isZillGoalSubDetail(item));
    });
    const newSelections = yield select((state) =>
      newSelectionHrefs.map((href) => selectZillGoalByHref(state, href))
    );

    const subDetails = newSelections.filter((item) => isZillGoalSubDetail(item));
    const existingGoals = subDetails.flatMap((subDetail) => {
      const existingGoal = genericGoals.find(
        (linkedGoal) => linkedGoal.href === subDetail.zillGoal.href
      );
      if (!existingGoal) {
        return [];
      }
      return {
        subDetail,
        existingGoal,
      };
    });
    if (existingGoals.length) {
      yield put(addSubDetailToGoalOfExistingIllustration(existingGoals));
    }

    const newGoals = [
      ...newSelections
        .filter((goal) => isLoadedZillGoal(goal))
        .map((goal) => ({ goal, subDetail: [] })),
      ...groupSubDetailsByGoal(
        subDetails.filter(
          (dc) => !genericGoals.find((linkedGoal) => linkedGoal.href === dc.zillGoal.href)
        )
      ),
    ];

    for (const { goal, subDetail } of newGoals) {
      // open modal, ask zill illustration title,
      // create node with linked goal selections to it
      const title = yield getAngularService('ModalWindowService').open({
        component: 'zillIllustrationModal',
        dataForModal: { goal, subDetail, goalHref: goal.href },
      });

      const zillIllustration = {
        title,
      };
      yield put(
        addZillIllustrationAction(
          editKey,
          zillIllustration,
          subDetail.length ? subDetail.map((dc) => dc.href) : [goal.href]
        )
      );
    }
  }
}

export const loadIllustrationsReferencingPracticalExamples = createAction(
  'zill/loadIllustrationsReferencingPracticalExamples'
);
export const loadZillGoalsForZillIllustrations = createAction(
  'zill/loadZillGoalsForZillIllustration'
);
export const loadPracticalExampleForZillIllustrations = createAction(
  'zill/loadPracticalExampleForZillIllustrations'
);

export const addSubDetailToGoalOfExistingIllustration = createAction<
  {
    subDetail: ZillGoalSubDetail;
    existingGoal: SelectedZillGoal;
  }[]
>('zill/addSubDetailToGoalOfExistingIllustration');

export const processNewSelectedIllustrations = createAction<{
  selectedGoals: ContentHref[];
  currentSelections: ZillCurriculumSelection[];
  genericGoals: SelectedZillGoal[];
  editKey: ContentKey;
}>('zill/processNewSelectedIllustrations');

export function* zillSaga() {
  yield takeEvery(
    loadIllustrationsReferencingPracticalExamples.match,
    loadIllustrationsWithGoalsReferencingPracticalExampleSaga
  );
  yield takeEvery(loadZillGoalsForZillIllustrations.match, loadZillGoalsForZillIllustrationSaga);
  yield takeEvery(
    loadPracticalExampleForZillIllustrations.match,
    loadPracticalExampleForZillIllustrationSaga
  );
  yield takeEvery(
    addSubDetailToGoalOfExistingIllustration.match,
    addSubDetailsToGoalOfExistingIllustrationSaga
  );
  yield takeEvery(processNewSelectedIllustrations.match, processNewSelectedIllustrationsSaga);
}
