import { selectIsNodeReferenceValid } from '@newStore/validation/validationRules/externalLinkValidationRules';
import { EditAsideMenuItemReference } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForMenuItemReference } from '@UI/aside/asideMenuItemReference/asideMenuItemReferenceSelectors';

export const menuItemReference: EditAsideMenuItemReference = {
  component: 'menuItemReference',
  reactComponent: 'AsideMenuItemReference',
  asideChangeMessageSelector: selectChangeMessageForMenuItemReference,
  label: 'Navigeert naar',
  property: 'referenceRelation.to',
  validations: [selectIsNodeReferenceValid],
};
