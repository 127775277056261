import { EditAsideAccessRights } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForAccessRights } from '@UI/aside/asideAccessRights/asideAccessRightsSelectors';
import { getAccessRightsToStringFunction } from '../editComponentHelpers';

export const proAccessRightsComponent: EditAsideAccessRights = {
  component: 'accessRights',
  reactComponent: 'AsideAccessRights',
  property: 'descendantsAccessRights',
  label: 'Afscherming',
  labelSingular: 'Afscherming',
  isInheritedProperty: true,
  valueToString: getAccessRightsToStringFunction('descendantsAccessRights'),
  asideChangeMessageSelector: selectChangeMessageForAccessRights,
} as const;
