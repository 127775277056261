import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';

const LLINKID_GUIDING_PINCIPLE_GROUP: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  information: {
    single: 'Krachtlijnen',
    plural: 'Krachtlijnen',
  },

  buildingBlocks: [{ type: NodeType.LLINKID_GUIDING_PRINCIPLE }],
  edit: [],
  showPlaceholder: true,
  readOnlyNode: true,

  isNotDraggable: true,
  isNotDeletable: true,
  isCollapsible: true, // maybe here you don't want to collapse?
  onNewNodeDropped: {
    openAside: false,
  },
};

export default LLINKID_GUIDING_PINCIPLE_GROUP;
