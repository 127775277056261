import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
import { selectProReferenceFrame } from '@newStore/referenceFrames/referenceFramesSelectors';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

export const selectAsidePrimaryReferenceFrameOptions = createTypedSelector(
  [selectProReferenceFrame],
  (proReferenceFrame) => {
    if (!proReferenceFrame) {
      return null;
    }
    return Object.values(proReferenceFrame.relations).flatMap((relation) => {
      if (
        proReferenceFrame.content[relation.from.href].type === 'CURRICULUM_THEME' &&
        proReferenceFrame.content[relation.to.href].type !== 'CURRICULUM_THEME' &&
        parentChildRelationFilter(relation)
      ) {
        return proReferenceFrame.content[relation.from.href];
      }
      return [];
    });
  }
);

export const selectChangeMessageForPrimaryReferenceFrameOptions: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state) => selectAsidePrimaryReferenceFrameOptions(state),
    ],
    (content, originalContent, primaryReferenceFrameOptions): string | null => {
      if (!primaryReferenceFrameOptions || content.themes?.[0] === originalContent?.themes?.[0]) {
        return null;
      }

      const currentOption = primaryReferenceFrameOptions.find(
        (o) => o.$$meta.permalink === content.themes?.[0]
      );

      const originalOption = primaryReferenceFrameOptions.find(
        (o) => o.$$meta.permalink === originalContent?.themes?.[0]
      );

      return stringDiffArrowFunction(currentOption?.title || null, originalOption?.title || null);
    }
  );
