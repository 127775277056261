import { Content, ContentHref } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';
import { formatDate } from 'date-fns';

import { get } from 'lodash';
import { stringDiffArrowFunction } from '../transformProposal/asideDiffText';

const getFormattedDate = (content: Content | undefined, property: string): string => {
  return get(content, property) ? formatDate(get(content, property) as string, 'dd/MM/yyyy') : '';
};

export const selectChangeMessageForDate: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newValue = getFormattedDate(content, config.property);
      const oldValue = getFormattedDate(originalContent, config.property);

      const diffHtml = stringDiffArrowFunction(newValue, oldValue);

      return diffHtml;
    }
  );

export const selectChangeMessageForDateToSend: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, _href: ContentHref) =>
        selectApiWithPendingChanges(state).newsletterSettings?.dateToSend,
      (state, _href: ContentHref) => state.documentApi.newsletterSettings?.dateToSend,
    ],
    (newDate, oldDate): string | null => {
      const newValue = newDate ? formatDate(newDate, 'dd/MM/yyyy') : '';
      const oldValue = oldDate ? formatDate(oldDate, 'dd/MM/yyyy') : '';

      const diffHtml = stringDiffArrowFunction(newValue, oldValue);

      return diffHtml;
    }
  );
