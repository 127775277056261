import { EditAsidePracticalExampleReferences } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React from 'react';
import {
  AsyncKovResourcePicker,
  useLoadSriOptions,
} from '@kathondvla/react-shared-components/src/components';
import { contentApi } from '@store/api/apiConfig';
import { useDispatch } from 'react-redux';
import {
  addRelationAction,
  patchRelationAction,
  removeRelationAction,
} from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { formatDate } from '@newStore/genericHelpers';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useEditHref } from '../../../hooks/UseEditHref';
import { selectPracticalExampleSelectedItemForZillIllustration } from './asidePracticalExampleReferencesSelectors';

const DisplayOption = ({ item }) => (
  <div className="picker-result">
    <img src="/images/zill.svg" alt="image" className="thumb" />
    <strong className="title">{item.$$expanded.title}</strong> <br />
    <span className="subtitle">
      {item.$$expanded.issued ? formatDate(item.$$expanded.issued) : 'Niet gepubliceerd'}
    </span>
  </div>
);

const AsidePracticalExampleReferences: React.FC<{
  config: EditAsidePracticalExampleReferences;
}> = ({ config }) => {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const editKey = getResourceKey(editHref);

  const selectedExample = useTypedSelector((state) =>
    selectPracticalExampleSelectedItemForZillIllustration(state)
  );

  const sriLoadOptionsFn = useLoadSriOptions({
    sriClient: contentApi,
    href: '/content',
    parameters: {
      tagsIn: 'PRACTICAL_EXAMPLE',
      orderBy: 'issued',
      descending: true,
    },
  });

  const change = (newValue) => {
    if (newValue) {
      if (selectedExample) {
        dispatch(
          patchRelationAction(getResourceKey(selectedExample.relationHref), {
            to: newValue,
          })
        );
      } else {
        const newRelation = {
          relationtype: 'REFERENCES',
          strength: 'MEDIUM',
          from: {
            href: `/content/${editKey}`,
          },
          to: newValue,
        };
        dispatch(addRelationAction(newRelation));
      }
    } else if (selectedExample) {
      dispatch(removeRelationAction(getResourceKey(selectedExample.relationHref)));
    }
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        <label className="control-label control-label-inline">{config.label}</label>
        <AsyncKovResourcePicker
          value={selectedExample}
          onChange={change}
          loadOptions={sriLoadOptionsFn}
          disabled={readOnly}
          optionValue={(option) => option.href}
          placeholder={`Zoek ${config.label.toLowerCase()}`}
          clearable={true}
          multiple={false}
          optionTemplate={(option) => <DisplayOption item={option} />}
        />
      </AsideValidation>
    </div>
  );
};

AsidePracticalExampleReferences.displayName = 'AsidePracticalExampleReferences';

export default AsidePracticalExampleReferences;

AsidePracticalExampleReferences.propTypes = {
  config: any,
};
