import { ContentHref, ContentRelation, ContentType } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { ContentRecord, RelationsToAndFromMap } from '@newStore/documentApi/documentApiTypes';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

const getRelations = (
  relationsToAndFromMap: RelationsToAndFromMap,
  content: ContentRecord,
  href: ContentHref
): ContentRelation[] => {
  return relationsToAndFromMap.to[href as ContentHref].filter(
    (z) => z.relationtype === 'IS_PART_OF' && content[z.from.href].type === ContentType.SOURCE
  );
};

const getSources = (relations: ContentRelation[], content: ContentRecord): string[] => {
  return relations.map((relation) => content[relation.from.href]?.title ?? '');
};

export const selectRelationsToSources = createTypedSelector(
  [(state) => selectApiWithPendingChanges(state), (state) => state.documentUI.currentEditingNode],
  (apiWithPendingChanges, currentEditingNode): ContentRelation[] => {
    return getRelations(
      apiWithPendingChanges.relationsToAndFromMap,
      apiWithPendingChanges.content,
      currentEditingNode as ContentHref
    );
  }
);

export const selectChangeMessageForSources: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href: ContentHref) => href,
      (state) => state.documentApi.content,
    ],
    (relationsMap, rawRelationsMap, content, href, rawContent): string | null => {
      const newSources = getSources(getRelations(relationsMap, content, href), content);
      const oldSources = getSources(getRelations(rawRelationsMap, rawContent, href), rawContent);
      return getDiffForArraysOfStrings(oldSources, newSources);
    }
  );
