import { ContentHref, ContentRelation } from '@generalTypes/apiTypes';
import { selectApiWithPendingChangesWithoutDeletes } from '@newStore/documentApi/documentApiSelectors';
import {
  selectAllNodeTypesMap,
  selectAppliedNodeConfig,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector, getSortedChildHrefsFromRelations } from '@newStore/genericHelpers';
import { NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { createError } from '../createError';
import { ValidationRule } from '../validationTypes';

export const selectNonFirstAttachmentInDownloadWarning: ValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href).information,
    (state, href, parentHref: ContentHref | undefined) =>
      parentHref && selectAppliedNodeConfig(state, parentHref).information,
    (state, href, parentHref: ContentHref | undefined) =>
      parentHref && selectAllNodeTypesMap(state)[parentHref],
    (state, href) => href,
    (state, href, parentHref) =>
      selectApiWithPendingChangesWithoutDeletes(state).relationsToAndFromMap.to[parentHref],
  ],
  (typeInfo, parentTypeInfo, parentNodeType, nodeHref, parentRelations: ContentRelation[]) => {
    if (!parentNodeType || !parentTypeInfo) {
      return true;
    }

    if (
      parentNodeType !== NodeType.DOWNLOAD &&
      parentNodeType !== NodeType.SHARED_ATTACHMENTS_GROUP
    ) {
      return true;
    }

    if (parentRelations.length > 1) {
      const sortedParentChildRelations = getSortedChildHrefsFromRelations(parentRelations);
      const firstChildHref = sortedParentChildRelations[0];
      if (firstChildHref !== nodeHref) {
        return createError(
          'nonFirstAttachmentInDownloadWarning',
          'selectNonFirstAttachmentInDownloadWarning',
          `${
            typeInfo.definiteArticle ? 'Dit' : 'Deze'
          } ${typeInfo.single.toLowerCase()} is een downloadbijlage. De hoofddownload is het eerste item in ${
            parentTypeInfo.definiteArticle ? 'dit' : 'deze'
          } ${parentTypeInfo.single.split('(')[0].trim().toLowerCase()}.`,
          undefined,
          RequiredType.WARNING
        );
      }
    }

    return true;
  }
);
