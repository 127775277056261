import { any } from 'prop-types';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { useDispatch } from 'react-redux';
import { EditAsideAgeRange } from '@nodeTypeConfig/configTypes';
import { set } from 'lodash';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import './AsideAgeRange.scss';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { getAgeMonth, getAgeYear } from './asideAgeRangeSelectors';

const AsideAgeRange: React.FC<{
  config: EditAsideAgeRange;
}> = ({ config }) => {
  const readOnly = useReadOnly();
  const href = useEditHref();
  const key = getResourceKey(href);
  const dispatch = useDispatch();

  const startAge = useInitialValue('startage') ?? null;
  const endAge = useInitialValue('endage') ?? null;

  const values = {
    startage: {
      year: getAgeYear(startAge),
      month: getAgeMonth(startAge),
    },
    endage: {
      year: getAgeYear(endAge),
      month: getAgeMonth(endAge),
    },
  };

  const change = (property, monthOrYear, value) => {
    values[property][monthOrYear] = Math.round(value);

    dispatch(
      patchNodeAction(
        key,
        set(
          {},
          property,
          values[property].year + Math.floor((1000 * values[property].month) / 12) / 1000
        )
      )
    );
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <label className="control-label">{config.label}</label>
      <div className="form-flex">
        <div className="col-sm-12 nopadding age-range">
          <div className="start-age">
            <input
              className="form-control small-form-control"
              defaultValue={values.startage.year}
              type="number"
              onChange={(e) => change('startage', 'year', e.target.value)}
              disabled={readOnly}
              min="0"
              max="99"
              step="1"
            />
            &nbsp; j &nbsp;
          </div>
          {config.options?.editMonth && (
            <div className="start-age">
              <input
                className="form-control small-form-control"
                defaultValue={values.startage.month}
                type="number"
                onChange={(e) => change('startage', 'month', e.target.value)}
                disabled={readOnly}
                min="0"
                max="11"
                step="1"
              />
              &nbsp; m &nbsp;
            </div>
          )}
          &nbsp;-&nbsp;
          <div className="end-age">
            <input
              className="form-control small-form-control"
              defaultValue={values.endage.year}
              type="number"
              onChange={(e) => change('endage', 'year', e.target.value)}
              disabled={readOnly}
              min="0"
              max="99"
              step="1"
            />
            &nbsp; j &nbsp;
          </div>
          {config.options?.editMonth && (
            <div className="start-age">
              <input
                className="form-control small-form-control"
                defaultValue={values.endage.month}
                type="number"
                onChange={(e) => change('endage', 'month', e.target.value)}
                disabled={readOnly}
                min="0"
                max="11"
                step="1"
              />
              &nbsp; m &nbsp;
            </div>
          )}
        </div>
      </div>
    </AsideValidation>
  );
};

AsideAgeRange.displayName = 'AsideAgeRange';

export default AsideAgeRange;

AsideAgeRange.propTypes = {
  config: any,
};
