import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectDocumentRoot,
} from '@newStore/documentApi/documentApiSelectors';
import { selectCurrentDocumentHref } from '@newStore/documentUI/documentUISelectors';
// import { deepFreeze } from '@newStore/genericHelpers';
import { createTypedSelector } from '@newStore/genericHelpers';
import { RootState } from '@generalTypes/rootStateTypes';
import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '../externalData/externalDataSelectors';
import {
  getZillGoalOrSubDetailByHref,
  getGoalByIllustrationToGoalRel,
  getPracticalExampleRelations,
} from './zillHelpers';
import {
  ZillGoalIsLoading,
  SelectedZillGoal,
  ZillIllustration,
  isLoadedZillGoal,
  isZillGoalSubDetail,
  ZillGoalHasFailedToLoad,
  isSelectedZillGoal,
  ZillGoalSubDetail,
  ZillGoal,
} from './zillTypes';

/* ZILL Illustrations and practical examples
┌────────┐
│        │
│  ZILL  │
│  Goal  │
│        │
└────────┘
     ▲
     │ REFERENCES Prio LOW (1n) [1n in theory but normaly it is just 1-1]
     │ BUT it can also link to something below a goal (development content or leerlijn step) in that case it is (1n) because then in content api there is a relation to each "ZillGoalSubDetail"
     | funtionally they see it as: a practical example points to a Goal (not to a subDetail) and illustration is "a property" of the relation between practical example and goal
┌────┴───────────┐
│                │
│      ZILL      │
│  Illustration  │
| = contextualisation of why a goal is selected for this practical example
│                │
└────┬───────────┘
     │ REFERENCES Prio MEDIUM (1)
     │
     ▼
┌─────────────────────┐
│                     │
│        ZILL         │
│  Practical Example  │
│                     │
│                     │
└─────────────────────┘

A practical example can be referenced by multiple ZILL illustrations.
*/

export const selectZillGoalByHref = createTypedSelector(
  [
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
    (state) => state.externalData.failedResourceAncestors,
    (state) => state.externalData.resourceAncestorsToLoad,
    (state, goalHref: ContentHref) => goalHref,
  ],
  (
    externalContent,
    externalContentRelationsMap,
    failedResourceAncestors,
    resourceAncestorsToLoad,
    goalHref
  ) => {
    return getZillGoalOrSubDetailByHref(
      goalHref,
      externalContent,
      externalContentRelationsMap,
      failedResourceAncestors,
      resourceAncestorsToLoad
    );
  }
);

export const selectZillIllustrationRelationsForPracticalExample = createTypedSelector(
  [
    (state) => selectCurrentDocumentHref(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
  ],
  (currentDocumentHref, apiWithPendingRelationsMap) => {
    if (!currentDocumentHref) {
      return [];
    }

    const illustrationRelations = apiWithPendingRelationsMap.to[currentDocumentHref].filter(
      (rel) => rel.relationtype === 'REFERENCES'
    );
    return illustrationRelations;
  }
);

export const selectZillIllustrationsHrefsForZillPracticalExample = (state: RootState) => {
  const illustrationRelsToLoad = selectZillIllustrationRelationsForPracticalExample(state);

  return illustrationRelsToLoad.map((rel) => rel.from.href);
};

const removeZillGoalFromSubDetail = (subDetail: ZillGoalSubDetail) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { zillGoal, ...rest } = subDetail;
  return rest;
};

/**
 * Merges all ZillGoalSubDetails into a generic Zill Goal so that all details of the same generic goal are combined
 * @returns an Array of SelectedZillGoals (or Goals that are still loading)
 */
const mergeSubDetailsIntoGenericGoals = (
  goalOrSubDetailAndIllustrationTupples: [ZillGoal | ZillGoalSubDetail, ZillIllustration][]
): SelectedZillGoal[] => {
  const goalMap: Record<string, SelectedZillGoal> = {};
  goalOrSubDetailAndIllustrationTupples.forEach(([goalOrSubDetail, zillIllustration]) => {
    if (isZillGoalSubDetail(goalOrSubDetail)) {
      const existingGoal = goalMap[goalOrSubDetail.zillGoal.href];
      if (existingGoal && isSelectedZillGoal(existingGoal)) {
        existingGoal.subDetails.push(removeZillGoalFromSubDetail(goalOrSubDetail));
      } else {
        goalMap[goalOrSubDetail.zillGoal.href] = {
          ...goalOrSubDetail.zillGoal,
          illustration: zillIllustration,
          subDetails: [removeZillGoalFromSubDetail(goalOrSubDetail)],
        };
      }
    } else {
      goalMap[goalOrSubDetail.href] = {
        ...goalOrSubDetail,
        illustration: zillIllustration,
        subDetails: [],
      };
    }
  });
  return Object.values(goalMap);
};

/**
 * In the data a practical example is linked to an illustration which is linked to a zill goal.
 * But how they see it functionally is: the practical example is linked (directly) to the goal, which is wrapped in an illustration.
 * The illustration is the contextualisation of how this goal fits in this practical example.
 * @return so we return an array of goals as though they are directly linked to the practical example with illustration as just a property of the goal
 */
export const selectLinkedZillGoalsForPracticalExample = createTypedSelector(
  [
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
    (state) => selectApiWithPendingChanges(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => state.externalData.failedResourceAncestors,
    (state) => state.externalData.resourceAncestorsToLoad,
    (state) => selectCurrentDocumentHref(state),
  ],
  (
    externalContent,
    externalContentRelationsMap,
    apiWithPendingChanges,
    apiWithPendingRelationsMap,
    failedResourceAncestors,
    resourceAncestorsToLoad,
    currentDocumentHref
  ): {
    illustrationsAreLoaded: boolean;
    genericGoals: SelectedZillGoal[];
    loadingZillGoals: (ZillGoalIsLoading | ZillGoalHasFailedToLoad)[];
  } => {
    const goalOrSubDetailAndIllustrationTupples: [
      ZillGoal | ZillGoalSubDetail,
      ZillIllustration
    ][] = [];
    const loadingZillGoals: (ZillGoalIsLoading | ZillGoalHasFailedToLoad)[] = [];
    let illustrationsAreLoaded = true;

    const illustrationRelations = apiWithPendingRelationsMap.to[
      currentDocumentHref as ContentHref
    ].filter((rel) => rel.relationtype === 'REFERENCES');

    illustrationRelations.forEach((illustrationRelation) => {
      const illustration = apiWithPendingChanges.content[illustrationRelation.from.href];
      if (!illustration) {
        illustrationsAreLoaded = false;
        return;
      }

      const transformedIllustration: ZillIllustration = {
        href: `/content/${illustration.key}`,
        relationToPracticalExampleHref: `/content/${illustrationRelation.key}`,
        title: illustration.title || '',
      };

      const illustrationToGoalRels = apiWithPendingRelationsMap.from[
        `/content/${illustration.key}`
      ].filter((rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW');

      const goalsOrSubDetails = illustrationToGoalRels.map((illustrationToGoalRel) =>
        getGoalByIllustrationToGoalRel(
          illustrationToGoalRel,
          externalContent,
          externalContentRelationsMap,
          failedResourceAncestors,
          resourceAncestorsToLoad
        )
      );

      goalsOrSubDetails.forEach((elem) => {
        if (isLoadedZillGoal(elem) || isZillGoalSubDetail(elem)) {
          goalOrSubDetailAndIllustrationTupples.push([elem, transformedIllustration]);
        } else {
          loadingZillGoals.push(elem);
        }
      });
    });

    const result = {
      illustrationsAreLoaded,
      genericGoals: mergeSubDetailsIntoGenericGoals(goalOrSubDetailAndIllustrationTupples),
      loadingZillGoals,
    };

    console.log('[zill selectors] Zill Goals linked to practical example:', result);

    return result;
  }
);

export const selectZillGoalsForZillIllustration = createTypedSelector(
  [
    (state) => selectDocumentRoot(state),
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => state.externalData.failedResourceAncestors,
    (state) => state.externalData.resourceAncestorsToLoad,
  ],
  (
    zillIllustrationContentResource,
    externalContent,
    externalContentRelationsMap,
    apiWithPendingRelationsMap,
    failedResourceAncestors,
    resourceAncestorsToLoad
  ): {
    illustrationsAreLoaded: boolean;
    genericGoals: SelectedZillGoal[];
    loadingZillGoals: (ZillGoalIsLoading | ZillGoalHasFailedToLoad)[];
  } => {
    if (!zillIllustrationContentResource) {
      return { illustrationsAreLoaded: false, genericGoals: [], loadingZillGoals: [] };
    }
    const goalRelations = apiWithPendingRelationsMap.from[
      zillIllustrationContentResource.$$meta.permalink
    ].filter((rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW');

    const transformedIllustration: ZillIllustration = {
      href: zillIllustrationContentResource.$$meta.permalink,
      title: zillIllustrationContentResource.title || '',
      relationToPracticalExampleHref: '', // to please TypeScript...
    };
    const goalOrSubDetailAndIllustrationTupples: [
      ZillGoal | ZillGoalSubDetail,
      ZillIllustration
    ][] = [];
    const loadingZillGoals: (ZillGoalIsLoading | ZillGoalHasFailedToLoad)[] = [];
    goalRelations.forEach((rel) => {
      const goalOrSubDetail = getGoalByIllustrationToGoalRel(
        rel,
        externalContent,
        externalContentRelationsMap,
        failedResourceAncestors,
        resourceAncestorsToLoad
      );
      if (isLoadedZillGoal(goalOrSubDetail) || isZillGoalSubDetail(goalOrSubDetail)) {
        goalOrSubDetailAndIllustrationTupples.push([goalOrSubDetail, transformedIllustration]);
      } else {
        loadingZillGoals.push(goalOrSubDetail);
      }
    });

    const result = {
      illustrationsAreLoaded: true,
      genericGoals: mergeSubDetailsIntoGenericGoals(goalOrSubDetailAndIllustrationTupples),
      loadingZillGoals,
    };

    console.log('[zill selectors] Zill Goals:', Object.values(result));

    return result;
  }
);

export const selectAllZillIllustrationGoalHrefsToLoad = (state: RootState) => {
  const apiWithPendingChanges = selectApiWithPendingChanges(state);
  const apiWithPendingRelationsMap = selectApiWithPendingChangesRelationsToAndFromMap(state);
  const illustrationHrefs = Object.values(apiWithPendingChanges.content)
    .filter((item) => item.type === ContentType.ZILL_ILLUSTRATION)
    .map((item) => item.$$meta.permalink);
  const goalHrefs = illustrationHrefs.flatMap((illustrationHref) => {
    const illustrationRelations = apiWithPendingRelationsMap.from[illustrationHref];
    return illustrationRelations
      .filter((rel) => rel.relationtype === 'REFERENCES' && rel.strength === 'LOW')
      .map((rel) => rel.to.href);
  });

  const hrefsToLoad = goalHrefs.filter(
    (href) =>
      !state.externalData.loadedAncestors.includes(href) &&
      !state.externalData.resourceAncestorsToLoad.includes(href) &&
      !state.externalData.failedResourceAncestors.includes(href)
  );
  return hrefsToLoad;
};

export const selectPracticalExampleRelationsForZillIllustration = (state: RootState) => {
  const documentRootHref = selectCurrentDocumentHref(state);
  const relationsMap = selectApiWithPendingChangesRelationsToAndFromMap(state);
  return getPracticalExampleRelations(documentRootHref, relationsMap);
};

export const selectPracticalExampleHrefsForZillIllustration = (state: RootState) =>
  selectPracticalExampleRelationsForZillIllustration(state).map((rel) => rel.to.href);
