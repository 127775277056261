import { EditAsideAttachmentGroupType, NodeType } from '@nodeTypeConfig/configTypes';
import { allProRootNodeTypes } from '@nodeTypeConfig/PRO/proGenericConfigProps';
import { selectChangeMessageForAttachmentGroupType } from '@UI/aside/asideAttachmentGroupType/asideAttachmentGroupSelectors';

export const attachmentGroupTypeOptions = [
  { key: 'MEDIUM', label: 'In de tekst én onderaan (standaard)' },
  { key: 'HIGH', label: 'Enkel onderaan' },
  { key: 'LOW', label: 'Enkel in de tekst' },
];

export const attachmentGroupTypeComponent: EditAsideAttachmentGroupType = {
  component: 'attachmentGroupType',
  label: 'Bijlagegroep tonen',
  subLabel: 'Waar moet de bijlagegroep getoond worden?',
  reactComponent: 'AsideAttachmentGroupType',
  options: attachmentGroupTypeOptions,
  showInAncestorTypes: [...allProRootNodeTypes, NodeType.SHARED_MINI_DATABASE_ITEM],
  asideChangeMessageSelector: selectChangeMessageForAttachmentGroupType,
};
