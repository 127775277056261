import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { validateWebTemplateConfig } from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForLinks } from '@UI/aside/asideLinks/asideLinksSelectors';
import { importanceComponent } from '@nodeTypeConfig/helpers/editComponents/importanceComponent';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { proContactsComponent } from '@nodeTypeConfig/helpers/editComponents/proContactsComponent';
import { menuItemReference } from '@nodeTypeConfig/helpers/editComponents/menuItemReference';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';
// Extends SECTION => A SECTION without webConfig inside a Pro Theme document or a SHARED_MINI_DATABASE_ITEM item document becomes a PRO_THEME_SECTION
// A Pro Theme is one of the 3 PRO_THEME_HOME_... document types, one of the 5 PRO_..._STANDALONE document types or just WEBPAGE2
const PRO_THEME_SECTION: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [validateWebTemplateConfig],
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [
    ...proDefaultBuildingBlocks.filter((bb) => bb.type !== NodeType.WORD_IMPORT),
    {
      type: NodeType.WORD_IMPORT,
      options: {
        openImportModal: true,
      },
    },
  ],
  edit: [
    {
      ...identifierComponent, // can go if we work on Aside (confirmed by Isabelle)
      width: '2',
      view: {
        hideIfEmpty: true,
      },
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      ...titleComponent,
      width: '10',
    },
    {
      ...importanceComponent,
      width: '6',
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      ...authorsComponent,
      width: '6',
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      ...proContactsComponent,
      width: '12',
      hideInAncestorTypes: [NodeType.PRO_DATABASE_ITEM],
    },
    {
      component: 'linkGroup',
      reactComponent: 'AsideLinks',
      asideChangeMessageSelector: selectChangeMessageForLinks,
      label: 'Links',
      width: '12',
      options: {
        type: 'group',
      },
    },
    menuItemReference,
  ],
};

export default PRO_THEME_SECTION;
